import React, { useState, useEffect } from "react";
import bsBreakpoints from "bs-breakpoints";
import classNames from "classnames";
import { useHistory } from "react-router-dom";

const HeroCaption = ({
  className,
  title,
  position,
  breadcrumb,
  breadcrumbPrefix,
}) => {
  const history = useHistory();

  const [currentBreakPoint, setCurrentBreakPoint] = useState("");

  useEffect(() => {
    bsBreakpoints.init();
    setCurrentBreakPoint(bsBreakpoints.getCurrentBreakpoint());

    const handleBreakPoint = (event) => {
      setCurrentBreakPoint(event.detail);
    };

    window.addEventListener("new.bs.breakpoint", handleBreakPoint);

    return () => {
      window.removeEventListener("new.bs.breakpoint", handleBreakPoint);
    };
  }, []);

  const heroContainerClasses = classNames({
    [className]: className,
    "hero-content--bottom-left": position === "bottom-left",
    large: !(currentBreakPoint === "xSmall" || currentBreakPoint === "small"),
  });

  const headingClasses = classNames(
    "text-white font-weight-bold mb-0",
    {
      "display-6": !(
        currentBreakPoint === "xSmall" || currentBreakPoint === "small"
      ),
    },
    {
      "display-7":
        currentBreakPoint === "xSmall" || currentBreakPoint === "small",
    }
  );

  const breadcrumbClasses = classNames(
    "font-heading-font mb-0 font-weight-light text-capitalize",
    {
      "display-9": !(
        currentBreakPoint === "xSmall" || currentBreakPoint === "small"
      ),
    }
  );

  return (
    <div className={heroContainerClasses}>
      <h1 className={headingClasses}>{title}</h1>
      {breadcrumb ? (
        <p className={breadcrumbClasses}>
          {[
            breadcrumbPrefix ? breadcrumbPrefix : "",
            ...history.location.pathname.replaceAll("-", " ").split("/"),
          ]
            .filter((link) => link.length)
            .map((link, index, arr) => (
              <span
                className={
                  index === arr.length - 1
                    ? "text-highlight-color"
                    : "text-white"
                }
              >
                {link + (index !== arr.length - 1 ? " / " : "")}
              </span>
            ))}
        </p>
      ) : null}
    </div>
  );
};

export default HeroCaption;
