import React from "react";
import PageHeader from "../../../components/NIISPageHeader/PageHeader";
import PageSection from "../../../components/NIISPageSection/PageSection";
import PageWrapper from "../../../components/NIISPageWrapper/PageWrapper";

const BachelorOfCommercePage = () => {
  return (
    <PageWrapper>
      <PageHeader title="Bachelor of Commerce" imageUrl={require('../../../assets/information-science-academic/page/BComm.jpg')} />
      <PageSection>
          <p>This degree is basically designed to give
students a range of different managerial and all-round business skills like
financial management, project management, human resources, accounting and
economics.</p>
      </PageSection>
    </PageWrapper>
  );
};

export default BachelorOfCommercePage;
