import React from "react";
import { Col, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import CourseCard from "../../../components/NIISCourseCard/CourseCard";
import PageHeader from "../../../components/NIISPageHeader/PageHeader";
import PageSection from "../../../components/NIISPageSection/PageSection";
import PageWrapper from "../../../components/NIISPageWrapper/PageWrapper";
import { postgraduateCourseData } from "../../../static-data/nisscourses.data";

const PostgraduatePage = () => {
	const { pathname } = useLocation();

	return (
		<PageWrapper>
			<PageHeader title="Post Graduate Courses" />
			<PageSection>
				<Row className="justify-content-center">
					{postgraduateCourseData &&
						postgraduateCourseData.map(({ imageUrl, title, link }) => (
							<Col
								lg="3"
								md="2"
								key={title}
								className="d-flex align-items-stretch mb-4"
							>
								<CourseCard
									title={title}
									imageUrl={imageUrl}
									link={`${pathname}${link}`}
								/>
							</Col>
						))}
				</Row>
			</PageSection>
		</PageWrapper>
	);
};

export default PostgraduatePage;
