import React from "react";
import { Image } from "react-bootstrap";
import "./collegeNameMiniCard.style.scss";

const CollegeNameMiniCard = ({ title, imageUrl, onClick, type }) => {
	return (
		<div className="college-name-mini-card" onClick={onClick}>
			<Image
				src={imageUrl.default ? imageUrl.default : imageUrl}
				alt={title}
				className="college-name-mini-card--image"
			/>
			<div className="college-name-mini-card--title">
				<h6 style={{ fontSize: "15px" }}>{title}</h6>
			</div>
		</div>
	);
};

export default CollegeNameMiniCard;
