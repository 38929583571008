import React from "react";
import Blockquote from "../../../components/NIISBlockquote/Blockquote";
import PageHeader from "../../../components/NIISPageHeader/PageHeader";
import PageSection from "../../../components/NIISPageSection/PageSection";
import PageSectionHeader from "../../../components/NIISPageSectionHeader/PageSectionHeader";
import PageWrapper from "../../../components/NIISPageWrapper/PageWrapper";
// import { Table } from "react-bootstrap";

const SciencePage = () => {
  return (
    <PageWrapper>
      <PageHeader title="+2 Science" />
      <PageSection>
        <p>
          Selecting a stream is comparatively easier than selecting a career,
          but making a careful choice is still important as your stream can
          influence your career. Science is based on the theory and application
          of scientific concepts, which opens up your career opportunities in
          various dimensions and builds a wide scope of concepts to be grasped,
          applied and learnt.
        </p>
        <Blockquote>
          <p>Subjects: Physics, Chemistry, Mathematics, English,</p>
        </Blockquote>
        <p className="mt-4 font-weight-bold text-center">
          The following combinations in Science Stream are allowed. Any
          deviation of this will not be entertained.
        </p>

        <PageSectionHeader title="Compulsory Subject" rightStyle="bottom" />
        <p>
          (Compulsory subject in English and MIL carries 200 marks. 100 marks in
          1st year and 100 marks in 2nd year)
        </p>
        <div className="ml-2">
          <ul>
            <li>
              <p>English</p>
            </li>
            <li>
              <p>MIL (Oriya / Alternative English)</p>
            </li>
          </ul>
        </div>
      </PageSection>
      <PageSection bgClass="light">
        <PageSectionHeader
          title="Environment Education (Compulsory)"
          leftStyle="bottom"
        />

        <div className="ml-2">
          <ul>
            <li>
              <p>Theory 70 marks.</p>
            </li>
            <li>
              <p>Project Work 30 Marks (Total 100 Marks in 1st year).</p>
            </li>
          </ul>
        </div>
      </PageSection>
      <PageSection>
        <PageSectionHeader title="Elective Subjects" rightStyle="bottom" />
        <p>
          (Each elective subject carries 200 marks.100 marks in 1st year and 100
          marks in 2nd year).
        </p>
        <div className="ml-2">
          <ul>
            <li>
              <p>Physics</p>
            </li>
            <li>
              <p>Chemistry</p>
            </li>
          </ul>
        </div>
        <p>
          A student shall have to offer two elective subjects out of the
          following group as 3rd and 4th elective. Either Group "A" or Group "B"
          for 3rd and 4th elective subject.
        </p>
      </PageSection>
      <PageSection bgClass="light">
        <PageSectionHeader
          title='Group "A" or "B" for 3rd and 4th elective subject.'
          leftStyle="bottom"
        />

        <PageSectionHeader title="Group A" type="h4" />
        <div className="ml-2 mb-4">
          <ul>
            <li>
              <p>Mathematics</p>
            </li>
            <li>
              <p>Biology/Electronic/Information Technology/Computer Science</p>
            </li>
          </ul>
        </div>

        <PageSectionHeader title="Group B" type="h4" />
        <div className="ml-2">
          <ul>
            <li>
              <p>Biology</p>
            </li>
            <li>
              <p>Mathematics/Information Technology/Computer Science</p>
            </li>
          </ul>
        </div>
        {/* <Table responsive="sm" bordered>
          <thead className="bg-diag-1 text-brand-1">
            <tr>
              <th>Group-A</th>
              <th>Group-B</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Mathematics</td>
              <td>Biology</td>
            </tr>
            <tr>
              <td>
                Biology/Electronic/Information Technology/Computer Science
              </td>
              <td>Mathematics/Information Technology/Computer Science</td>
            </tr>
          </tbody>
        </Table> */}
      </PageSection>
    </PageWrapper>
  );
};

export default SciencePage;
