import React,{useState} from "react";
import NiisHero from "../../components/NIISNiisHero/NiisHero";
import PageWrapper from "../../components/NIISPageWrapper/PageWrapper";
import CollegeNameResponsive from "../../components/NIISCollegeNameResponsive/CollegeNameResponsive";
import CoursesResponsive from "../../components/NIISCoursesResponsive/CoursesResponsive";
import InfrastructureAndFacilitesResponsive from "../../components/NIISInfrastructureAndFacilitesResponsive/InfrastructureAndFacilitesResponsive";
import InfographyResponsive from "../../components/NIISInfographyResponsive/InfographyResponsive";
import QuickLinksResponsive from "../../components/NIISQuickLinksResponsive/QuickLinksResponsive";
import ResponsiveContactForm from "../../components/NIISContactResponsive/ContactResponsive";
import NewsResponsive from "../../components/NIISNewsResponsive/NewsResponsive";
import SocialBlogResponsive from "../../components/NIISSocialBlogResponsive/SocialBlogResponsive";
import UpcommingEventsResponsive from "../../components/NIISUpcommingEventsResponsive/UpcommingEventsResponsive";
import FromStudentsResponsive from "../../components/NIISFromStudentsResponsive/FromStudentsResponsive";
import TrusteeResponsive from "../../components/NIISTrusteeResponsive/TrusteeResponsive";
import AboutUsResponsive from "../../components/NIISAboutUsResponsive/AboutUsResponsive";
import AboutUsIconsResponsive from "../../components/NIISAboutUsIcons/AboutUsIconsResponsive";
import {niiscourses} from "../../static-data/collegecourses.data";



const HomePage = () => {

	const [niisCourse, setNiisCourse] = useState(niiscourses);


	return (
		<PageWrapper className="mt-n6">

			{/* <LogoBanner/> */}
			{/* <CustomNavbar linkList={navbarData} newLinks={newLinks} /> */}
			<NiisHero />
			<CollegeNameResponsive />
			<AboutUsResponsive/>
			<AboutUsIconsResponsive/>
			<CoursesResponsive />  
			<TrusteeResponsive />
			<FromStudentsResponsive />
			<InfrastructureAndFacilitesResponsive />
			<InfographyResponsive />
			<QuickLinksResponsive />
			<UpcommingEventsResponsive />
			<SocialBlogResponsive />
			<ResponsiveContactForm course={niisCourse}/>
			<NewsResponsive />

		</PageWrapper>
	);
};

export default HomePage;
