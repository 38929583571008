import React from "react";
import PageHeader from "../../../components/NIISPageHeader/PageHeader";
import PageSection from "../../../components/NIISPageSection/PageSection";
import PageSectionHeader from "../../../components/NIISPageSectionHeader/PageSectionHeader";
import PageWrapper from "../../../components/NIISPageWrapper/PageWrapper";
import JuniorScienceEnquiryForm from "../../../components/NIISJuniorScienceEnquiryForm/JuniorScienceEnquiryForm";

const AdmissionEnquiryPage = () => {
  return (
    <PageWrapper>
      <PageHeader title="Admission Enquiry" />
      <PageSection>
        <PageSectionHeader
          type="h5"
          title="Thank you for your interest in NIIS Group of Institutions."
        />
        <p>
          If you are interested in NIIS junior college, we strongly recommend that you get in
          touch and come to our institute which we are so proud of and see how
          we work. Please fill in the enquiry form to log your interest in the
          NIIS and we will be in touch to arrange for a tour of our campus.
        </p>
      </PageSection>
      <PageSection bgClass="light">
        <PageSectionHeader title="Write to us" leftStyle="bottom" />
        <JuniorScienceEnquiryForm />
      </PageSection>
    </PageWrapper>
  );
};

export default AdmissionEnquiryPage;
