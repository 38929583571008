export const niiscourses = [
    {
        id:1,
        name:"B.Tech(CS)"
    },
    {
        id:2,
        name:"B.Tech(EEE)"
    },
    {
        id:3,
        name:"B.Tech(ETC)"
    },
    {
        id:4,
        name:"B.Tech(EE)"
    },
    {
        id:5,
        name:"B.Tech(Civil)"
    },
    {
        id:6,
        name:"B.Tech(Mech)"
    },
    {
        id:7,
        name:"BBA",
    },
    {
        id:8,
        name:"BCA"
    },
    {
        id:9,
        name: "B.Sc(Hons)"
    },
    {
        id:10,
        name:"B.Com"
    },
    {
        id:11,
        name:"B.A"
    },
    {
        id:12,
        name:"B.Sc ITM"
    },
    {
        id:13,
        name:"B.Sc Bio-tech"
    },
    {
        id:14,
        name:"MBA"
    },
    {
        id:15,
        name: "MCA"
    },
    {
        id:16,
        name:"M.Com"
    },
    {
        id:17,
        name:"M.Com(FC)"
    },
    {
        id:18,
        name:"M.Sc(CS)"
    },
    {
        id:19,
        name: "MSW"
    },	  
];

export const informationScienceCourses=[
    {
        id:1,
        name:"BBA"
    },
    {
        id:2,
        name:"BCA"
    },
    {
        id:3,
        name:"B.Sc(CS)"
    },
    {
        id:4,
        name:"B.Sc(Hons)"
    },
    {
        id:5,
        name:"B.Com"
    },
    {
        id:6,
        name:"BA"
    },
    {
        id:7,
        name:"B.Sc ITM"
    },
    {
        id:8,
        name:"B.Sc Bio-tech"
    },
    {
        id:9,
        name:"M.Com"
    },
    {
        id:10,
        name:"M.Com(FC)"
    },
    {
        id:11,
        name:"MPMIR"
    },
    {
        id:12,
        name:"M.Sc(CS)"
    },
    {
        id:13,
        name:"M.Sc(Phy)"
    },
    {
        id:14,
        name:"M.Sc(Chem)"
    },
    {
        id:15,
        name:"MSW"
    }
];

