import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Card, Row, Col, Image } from "react-bootstrap";
import bsBreakpoints from "bs-breakpoints";
import classNames from "classnames";

import "./DevisionCard.styles.scss";

const DivisionCard = ({ title, link, imageSrc }) => {
  const [currentBreakPoint, setCurrentBreakPoint] = useState("");

  useEffect(() => {
    bsBreakpoints.init();
    setCurrentBreakPoint(bsBreakpoints.getCurrentBreakpoint());

    const handleBreakPoint = (event) => {
      setCurrentBreakPoint(event.detail);
    };

    window.addEventListener("new.bs.breakpoint", handleBreakPoint);

    return () => {
      window.removeEventListener("new.bs.breakpoint", handleBreakPoint);
    };
  }, []);

  const imageClassName = classNames(
    {
      "text-center":
        currentBreakPoint === "xSmall" || currentBreakPoint === "small",
    },
    {
      "mb-4": currentBreakPoint === "xSmall" || currentBreakPoint === "small",
    }
  );

  const titelClassName = classNames({
    "text-center":
      currentBreakPoint === "xSmall" || currentBreakPoint === "small",
  });

  return (
    <Card className="division-card w-100 shadow border">
      <Link to={link} style={{ color: "unset", textDecoration: "none" }}>
        <Card.Body>
          <Row className="justify-content-center align-items-stretch">
            <Col sm="5" className={imageClassName}>
              <Image src={imageSrc} alt={title} className="img-fluid" />
            </Col>
            <Col sm="7" className={titelClassName}>
              <Card.Title as="h5">{title}</Card.Title>
            </Col>
          </Row>
        </Card.Body>
      </Link>
    </Card>
  );
};

export default DivisionCard;
