import React from "react";
import Blockquote from "../../../components/NIISBlockquote/Blockquote";
import PageHeader from "../../../components/NIISPageHeader/PageHeader";
import PageSection from "../../../components/NIISPageSection/PageSection";
import PageSectionHeader from "../../../components/NIISPageSectionHeader/PageSectionHeader";
import PageWrapper from "../../../components/NIISPageWrapper/PageWrapper";

const CommercePage = () => {
  return (
    <PageWrapper>
      <PageHeader title="+2 Commerce" />
      <PageSection>
        <p>
          Business studies taught at our institution are targeted to give
          students practical and useful knowledge to prepare them for real life
          situations with skills needed to manage businesses in current
          vulnerable markets.
        </p>
        <Blockquote>
          <p>Subjects: Accounts, Business Studies</p>
        </Blockquote>
        <p className="mt-4 font-weight-bold text-center">
          The following combinations in Science Stream are allowed. Any
          deviation of this will not be entertained.
        </p>

        <PageSectionHeader title="Compulsory Subject" rightStyle="bottom" />
        <p>
          (Compulsory subject in English and MIL carries 200 marks. 100 marks in
          1st year and 100 marks in 2nd year)
        </p>
        <div className="ml-2">
          <ul>
            <li>
              <p>English</p>
            </li>
            <li>
              <p>MIL (Oriya / Alternative English)</p>
            </li>
          </ul>
        </div>
      </PageSection>
      <PageSection bgClass="light">
        <PageSectionHeader
          title="Environment Education (Compulsory)"
          leftStyle="bottom"
        />

        <div className="ml-2">
          <ul>
            <li>
              <p>Theory 70 marks.</p>
            </li>
            <li>
              <p>Project Work 30 Marks (Total 100 Marks in 1st year).</p>
            </li>
          </ul>
        </div>
      </PageSection>
      <PageSection>
        <PageSectionHeader title="Elective Subjects" rightStyle="bottom" />
        <p>
          (Each elective subject carries 200 marks.100 marks in 1st year and 100
          marks in 2nd year).
        </p>
        <div className="ml-2">
          <ul>
            <li>
              <p>Accounting</p>
            </li>
            <li>
              <p>Business Studies & Management</p>
            </li>
            <li>
              <p>Business Mathematics & Statistics.</p>
            </li>
          </ul>
        </div>
        <p>A student shall have to offer any one of the following groups</p>
      </PageSection>
      <PageSection bgClass="light">
        <div className="mb-2">
          <PageSectionHeader
            title='Group "A" or "B" for 3rd and 4th elective subject.'
            type="h4"
          />
        </div>
        <PageSectionHeader title="Group A" type="h4" />
        <div className="ml-2 mb-5">
          <ul>
            <li>
              <p>Commercial Banking </p>
            </li>
            <li>
              <p>Salesmanship</p>
            </li>
            <li>
              <p>Fundamentals of Company</p>
            </li>
            <li>
              <p>Salesmanship</p>
            </li>
          </ul>
        </div>

        <PageSectionHeader title="Group B" type="h4" />
        <div className="ml-2">
          <ul>
            <li>
              <p>Insurance </p>
            </li>
            <li>
              <p>Business Mathematics & Statistics</p>
            </li>
            <li>
              <p>Fundamentals of Management A/c</p>
            </li>
            <li>
              <p>Information Technology-2</p>
            </li>
          </ul>
        </div>
        {/* <Table responsive="sm" bordered>
          <thead className="bg-diag-1 text-brand-1">
            <tr>
              <th></th>
              <th>Group-A</th>
              <th>Group-B</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Group-1</td>
              <td>Commercial Banking</td>
              <td>Insurance</td>
            </tr>
            <tr>
              <td>Group-2</td>
              <td>Salesmanship</td>
              <td>Business Mathematics &amp; Statistics</td>
            </tr>
            <tr>
              <td>Group-3</td>
              <td>Fundamentals of Company</td>
              <td>Fundamentals of Management A/c</td>
            </tr>
            <tr>
              <td>Group-4</td>
              <td>Information Technology-1</td>
              <td>Information Technology-2</td>
            </tr>
          </tbody>
        </Table> */}
      </PageSection>
    </PageWrapper>
  );
};

export default CommercePage;
