import React, { useState, useEffect } from "react";
import bsBreakpoints from "bs-breakpoints";
import classNames from "classnames";
import ResponsivePageHeader from "../ResponsivePageHeader/ResponsivePageHeader";
import PageSectionHeader from "../NIISPageSectionHeader/PageSectionHeader";
import PageSection from "../PageSection/PageSection";

const AlumniRegisterResponsive = () => {
	const [currentBreakPoint, setCurrentBreakPoint] = useState("");

	useEffect(() => {
		bsBreakpoints.init();
		setCurrentBreakPoint(bsBreakpoints.getCurrentBreakpoint());

		const handleBreakPoint = (event) => {
			setCurrentBreakPoint(event.detail);
		};

		window.addEventListener("new.bs.breakpoint", handleBreakPoint);

		return () => {
			window.removeEventListener("new.bs.breakpoint", handleBreakPoint);
		};
	}, []);

	const descriptionClasses = classNames("mb-4 text-center text-text-color", {
		"font-sm": currentBreakPoint === "xSmall" || currentBreakPoint === "small",
	});

	return (
		<PageSection bgClass="background-color">
			{/* <PageSectionHeader title="NIIS Alumni Across the world! " rightStyle="bottom" /> */}
			<ResponsivePageHeader centered={true} title="NIIS Alumni Across the world!" />
			<p className={descriptionClasses}>
				" Join the NIBA alumni network and reconnect with your peers! "
			</p>
			<a
				href=""
				className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
				target="_blank"
				rel="noopener noreferrer"
			>
				<span className="ml-2">Alumni Registration Form</span>
			</a>
		</PageSection>
	);
};

export default AlumniRegisterResponsive;
