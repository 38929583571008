import React from "react";
import PageHeader from "../../../components/NIISPageHeader/PageHeader";
import PageSection from "../../../components/NIISPageSection/PageSection";
import PageWrapper from "../../../components/NIISPageWrapper/PageWrapper";
import CustomIcon from "../../../components/NIISCustomIcon/CustomIcon";
import { AiFillFilePdf } from "react-icons/ai";
import PageSectionHeader from "../../../components/NIISPageSectionHeader/PageSectionHeader";
import Blockquote from "../../../components/NIISBlockquote/Blockquote";

const MasterInChemistryPage = () => {
	return (
		<PageWrapper>
			<PageHeader
				title="Master of Science in Chemistry"
				imageUrl={require("../../../assets/information-science-academic/2.jpg")}
			/>
			<PageSection>
				<p className="mb-4">
					Master of Science in Chemistry can give you a specialized focus on an
					area of technology, helping you develop your skills and career
					prospects.
				</p>

				{/* <p className="text-center">
					<a href="/#" className="download-link">
						<CustomIcon currentIcon={AiFillFilePdf} />
						<span className="ml-2">DOWNLOAD COMPLETE SYLLABUS</span>
					</a>
				</p> */}
			</PageSection>
			<PageSection bgClass="light">
				<PageSectionHeader title="Course Structure" leftStyle="bottom" />
				<div className="ml-2">
					<Blockquote>
						<p>
							<span className="font-weight-bold">Name of the Course : </span>
							Master of Science in Chemistry
						</p>
						<p>
							<span className="font-weight-bold">Duration : </span>2 yrs
						</p>
						{/* <p>
							<span className="font-weight-bold">Intake Capacity : </span>30
						</p> */}
						<p>
							<span className="font-weight-bold">Tuition Fees per Annum :</span>
							As per Govt. Directives
						</p>
					</Blockquote>
				</div>
			</PageSection>
			<PageSection>
				<PageSectionHeader title="Eligibility" rightStyle="bottom" />
				<p>
					B.Sc Degree with chemistry as a core subject with at-least 50% marks
					in aggregate(45% for SC/ST) in the qualifying examination.
				</p>
			</PageSection>
			<PageSection bgClass="light">
				<PageSectionHeader title="Selection Procedure" leftStyle="bottom" />
				<p>
					Through NIIS Aptitude Test (NAT) along with merit of previous academic
					record followed by personal interview. Admission of Special category
					seats shall be made basing on a merit list among the applicants to be
					followed by Personal Aptitude Test (PAT).
				</p>
			</PageSection>
			{/* <PageSection>
				<PageSectionHeader title="Career Opportunity" rightStyle="bottom" />
				<p>
					M.Sc. Computer Science graduates interested in pursuing a job can
					apply for various job profiles such as a system administrator,
					software programmer, software tester, software analyst and Data Base
					Manager. Further, the candidate can also go for hardware and
					Networking profiles.
				</p>

				<p className="mb-4">
					An M.Sc. degree in Computer Science mostly helps in acquiring a job
					position in the IT sector. The IT and ITeS sector has been one of the
					top employment sectors in India in the past and continues to do so.
					Current trends show that the sector will increase to grow at the rate
					of 12-15% annually. With an expansion in the sector, the employment
					opportunities are also expected to increase. This forecasts a good
					career prospect for M.Sc. Computer Science. The graph below shows the
					current salary trend for M.Sc. Computer Science graduates employed in
					IT industry.
				</p>
				<div className="text-center">
					<Image
						src="http://www.niisinst.com/niis-information-science/images/flow.jpg"
						className="img-fluid shadow"
					/>
				</div>
			</PageSection> */}
		</PageWrapper>
	);
};
export default MasterInChemistryPage;
