import React from "react";
import PageHeader from "../../../components/NIISPageHeader/PageHeader";
import PageSection from "../../../components/NIISPageSection/PageSection";
import PageWrapper from "../../../components/NIISPageWrapper/PageWrapper";
import CustomIcon from "../../../components/NIISCustomIcon/CustomIcon";
import { AiFillFilePdf } from "react-icons/ai";
import PageSectionHeader from "../../../components/NIISPageSectionHeader/PageSectionHeader";


const MasterOfCommercePage = () => {
  return (
    <PageWrapper>
      <PageHeader title="Master of Commerce (MCom)" imageUrl={require('../../../assets/information-science-academic/page/BComm.jpg')}/>
      <PageSection>
        <p className="mb-4">
        A course which is ideal for students who wish to pursue a
career in banking, finance, accounting and different commerce sectors. We at
NIIS provide an ambient and productive training for the whole course with
internships and project packed semesters as well.        </p>

        <p className="text-center">
          <a href="/#" className="download-link">
            <CustomIcon currentIcon={AiFillFilePdf} />
            <span className="ml-2">DOWNLOAD COMPLETE SYLLABUS</span>
          </a>
        </p>
      </PageSection>
      <PageSection bgClass="light">
        <PageSectionHeader title="Course Structure" leftStyle="bottom" />
        <p>
          MCom is a course consisting of 4 semesters, spanning a period of two
          years. The first semester comprises of the subjects which acquaints
          the students of different discipline with the world of commerce and
          finance. The other three semesters divided into core subjects that
          offers the student sufficient skill to fulfill the objective of the
          course. In the beginning of the 3rd semester is an industrial tour in
          which the students visit different industries and obtain practical
          knowledge. After the fourth semester they submit a project report on
          different burning topics
        </p>
      </PageSection>
      <PageSection>
        <PageSectionHeader title="Eligibility" rightStyle="bottom" />
        <p>
          A Bachelor's Degree (10 +2 +3) passed in BCom with 45% of marks for
          general (40% of marks for SC/ST) candidates.
        </p>
      </PageSection>
      <PageSection bgClass="light">
        <PageSectionHeader title="Selection Procedure" leftStyle="bottom" />
        <p>
          Through NIIS Aptitude Test (NAT) along with merit of previous academic
          record followed by personal interview. Admission of Special category
          seats shall be made basing on a merit list among the applicants to be
          followed by Personal Aptitude Test (PAT).
        </p>
      </PageSection>
      <PageSection>
        <PageSectionHeader title="Career Opportunity" rightStyle="bottom" />
        <p>
          After successful completion of this two years full time course, a
          candidate may get employment opportunity in Govt. or Private
          Companies, Corporations, Banks, Educational Establishment as
          Accountant, Financial Experts, Tax Planning, Corporate Structuring,
          Retail Management, Merchant Banking etc. with a descent pay package.
        </p>
      </PageSection>
    </PageWrapper>
  );
};

export default MasterOfCommercePage;
