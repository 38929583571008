import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import PageHeader from "../../../components/NIISPageHeader/PageHeader";
import PageSection from "../../../components/NIISPageSection/PageSection";
import PageWrapper from "../../../components/NIISPageWrapper/PageWrapper";

const ActivitiesPage = () => {
  return (
    <PageWrapper>
      <PageHeader title="Activities" />
      <PageSection>
        <p className="mb-4">
        From an enthusiastic annual Sports Week, to innumerable science weeks, seminars,
cultural activities- even magazine publishing events take place round the year. We
believe these activities open up the minds of the Children into the creative field as well,
and provides them with a much needed break from the hectic academic curriculum.
        </p>
        {/* <PageSectionHeader title="Sports" rightStyle="bottom" />
        <p>
          The institute organizes annual sports event every year to encourage
          participation and create interest among the students in various
          sports. Prizes and mementos are distributed to the successful
          participants.
        </p> */}
      </PageSection>
      <PageSection bgClass="light">
      <Row>
          <Col md="6">
            <Image
              src={
                require("../../../assets/activities/act 2.JPG")
                  .default
              }
              className="img-fluid rounded mb-4"
            />
          </Col>
          <Col md="6">
            <Image
              src={
                require("../../../assets/activities/act 11.JPG")
                  .default
              }
            className="img-fluid rounded mb-4"
            />
          </Col>
          <Col md="6">
            <Image
              src={
                require("../../../assets/activities/sc1.JPG")
                  .default
              }
              className="img-fluid rounded mb-4"
            />
          </Col>
          <Col md="6">
            <Image
              src={
                require("../../../assets/activities/sc2.JPG")
                  .default
              }
            className="img-fluid rounded mb-4"
            />
          </Col>
        </Row>
      </PageSection>
    </PageWrapper>
  );
};

export default ActivitiesPage;
