import React from "react";
import { Card } from "react-bootstrap";
import classNames from "classnames";

import "./TestimonialCard.styles.scss";

const TestimonialCard = ({
  name,
  subTitle,
  content,
  imageUrl,
  bgClass,
  type,
  shadowImage,
  border
}) => {
  const cardClasses = classNames(
    "w-100 h-100",
    {
      "mini-testimonial": type === "mini",
    },
    { [bgClass]: bgClass },
    {"border": border}
  );

  const imageClasses = classNames({ shadow: shadowImage });

  return (
    <Card className={cardClasses}>
      <Card.Img
        top="true"
        src={imageUrl.default ? imageUrl.default : imageUrl}
        alt={name}
        className={imageClasses}
      />
      <Card.Body>
        {name ? (
          <Card.Title as="h5" className="text-center text-brand-1">
            {name}
          </Card.Title>
        ) : null}
        {subTitle ? (
          <Card.Subtitle className="text-center font-weight-bold text-brand-2 mb-4">
            {subTitle}
          </Card.Subtitle>
        ) : null}

        {content ? (
          <Card.Text className="text-center">{content}</Card.Text>
        ) : null}
      </Card.Body>
    </Card>
  );
};

export default TestimonialCard;
