import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { facilitiesData } from "../../static-data/facility.data";
import MiniCard from "../NIISMiniCard/MiniCard";
import "./FacilitiesCards.styles.scss";
const FacilitiesCards = () => {
  return (
    <Container>
      <div style={{ maxWidth: "900px" , margin: "0 auto"}}>
        <Row className="justify-content-center">
          {facilitiesData
            ? facilitiesData.map(({ title, imageUrl }) => (
                <Col
                  sm="6"
                  md="3"
                  key={title}
                  className="d-flex align-items-stretch mb-4"
                >
                  <MiniCard
                    title={title}
                    imageUrl={imageUrl.default}
                    bgClass="bg-brand-2"
                  />
                </Col>
              ))
            : null}
        </Row>
      </div>
    </Container>
  );
};

export default FacilitiesCards;
