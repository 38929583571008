import React from "react";
import classNames from "classnames";

const Blockquote = ({ borderClass, children, paddingClass, className }) => {
  const blockClassNames = classNames(
    { "border-brand-2-left-md": !borderClass },
    { [`border-${borderClass}`]: borderClass },
    { [paddingClass]: paddingClass },
    {'pl-4': !paddingClass},
    {[className]: className}
  );
  return <div className={blockClassNames}>{children}</div>;
};

export default Blockquote;
