import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import {
  GoChevronUp,
  GoChevronDown,
  GoChevronRight,
  GoChevronLeft,
} from "react-icons/go";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation } from "swiper";
import bsBreakpoints from "bs-breakpoints";
import classNames from "classnames";
import NewsBitesCard from "../NIISNewsBitesCard/NewsBitesCard";
import CustomIcon from "../NIISCustomIcon/CustomIcon";

import "./NewsBites.styles.scss";

let src = require("../../assets/news-temp.jpg").default;

SwiperCore.use([Autoplay, Navigation]);

const NewsBites = () => {
  const [currentBreakPoint, setCurrentBreakPoint] = useState("");

  useEffect(() => {
    bsBreakpoints.init();
    setCurrentBreakPoint(bsBreakpoints.getCurrentBreakpoint());

    const handleBreakPoint = (event) => {
      setCurrentBreakPoint(event.detail);
    };

    window.addEventListener("new.bs.breakpoint", handleBreakPoint);

    return () => {
      window.removeEventListener("new.bs.breakpoint", handleBreakPoint);
    };
  }, []);

  const verticalClassNames = classNames("news-controls-vertical", {
    "d-none": !(
      currentBreakPoint === "small" || currentBreakPoint === "xSmall"
    ),
  });

  const horizontalClassNames = classNames("news-controls-horizontal ", {
    "d-none": currentBreakPoint === "small" || currentBreakPoint === "xSmall",
  });

  const newsContainerClassNames = classNames("mb-4", "news-container", {
    "news-container-mobile":
      currentBreakPoint === "small" || currentBreakPoint === "xSmall",
  });

  return (
    <div className={newsContainerClassNames}>
      <Swiper
        grabCursor={true}
        direction={
          currentBreakPoint === "small" || currentBreakPoint === "xSmall"
            ? "vertical"
            : "horizontal"
        }
        scrollbar={{ el: "", draggable: true, hide: true }}
        slidesPerView={2}
        spaceBetween={20}
        loop={true}
        autoplay={{ delay: 3000 }}
        navigation={{ prevEl: ".news-prev", nextEl: ".news-next" }}
        height={500}
      >
        <SwiperSlide>
          <NewsBitesCard
            imageSrc={src}
            title="Test 1"
            link=""
            date="19 May, 2019"
          />
        </SwiperSlide>
        <SwiperSlide>
          <NewsBitesCard
            imageSrc={src}
            title="Test 2"
            link=""
            date="19 May, 2019"
          />
        </SwiperSlide>
        <SwiperSlide>
          <NewsBitesCard
            imageSrc={src}
            title="Test 3"
            link=""
            date="19 May, 2019"
          />
        </SwiperSlide>
        <SwiperSlide>
          <NewsBitesCard
            imageSrc={src}
            title="Test 4"
            link=""
            date="19 May, 2019"
          />
        </SwiperSlide>
        <SwiperSlide>
          <NewsBitesCard
            imageSrc={src}
            title="Test 5"
            link=""
            date="19 May, 2019"
          />
        </SwiperSlide>
        <SwiperSlide>
          <NewsBitesCard
            imageSrc={src}
            title="Test 6"
            link=""
            date="19 May, 2019"
          />
        </SwiperSlide>
      </Swiper>

      <div className={verticalClassNames}>
        <div className="news-prev mb-4">
          <CustomIcon
            currentIcon={GoChevronUp}
            colorClass="text-brand-2"
            size="lg"
            shadowClass="shadow"
            roundedClass="rounded-circle"
            bgClass="bg-light"
          />
        </div>
        <div className="news-next">
          <CustomIcon
            currentIcon={GoChevronDown}
            colorClass="text-brand-2"
            size="lg"
            shadowClass="shadow"
            roundedClass="rounded-circle"
            bgClass="bg-light"
          />
        </div>
      </div>
      <div className={horizontalClassNames}>
        <Row className="justify-content-between">
          <Col>
            <span className="news-prev control">
              <CustomIcon
                currentIcon={GoChevronLeft}
                colorClass="text-brand-2"
                size="lg"
                shadowClass="shadow"
                roundedClass="rounded-circle"
                bgClass="bg-light"
              />
            </span>
          </Col>
          <Col className="text-right">
            <span className="news-next control">
              <CustomIcon
                currentIcon={GoChevronRight}
                colorClass="text-brand-2"
                size="lg"
                shadowClass="shadow"
                roundedClass="rounded-circle"
                bgClass="bg-light"
              />
            </span>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default NewsBites;
