import React from "react";
import PageHeader from "../../../components/NIISPageHeader/PageHeader";
import PageSection from "../../../components/NIISPageSection/PageSection";
import PageWrapper from "../../../components/NIISPageWrapper/PageWrapper";
import CustomIcon from "../../../components/NIISCustomIcon/CustomIcon";
import { AiFillFilePdf } from "react-icons/ai";
import PageSectionHeader from "../../../components/NIISPageSectionHeader/PageSectionHeader";
import Blockquote from "../../../components/NIISBlockquote/Blockquote";

const BachelorOfSciencePage = () => {
  return (
    <PageWrapper>
      <PageHeader title="Bachelor of Science (Computer Science)" imageUrl={require('../../../assets/information-science-academic/page/BS.jpg')}/>
      <PageSection>
        <p className="mb-4">
        In this course, you will be studying
Computer Science-an engineering science which studies the principles and use
of computers.
        </p>

        <p className="text-center">
          <a href="/#" className="download-link">
            <CustomIcon currentIcon={AiFillFilePdf} />
            <span className="ml-2">DOWNLOAD COMPLETE SYLLABUS</span>
          </a>
        </p>
      </PageSection>
      <PageSection bgClass="light">
        <PageSectionHeader title="Course Structure" leftStyle="bottom" />
        <div className="ml-2">
          <Blockquote>
            <p>
              <span className="font-weight-bold">Semester : </span>
              BSc. COMP SC.
            </p>
            <p>
              <span className="font-weight-bold">Duration : </span>3 yrs (6 Semesters)	
            </p>
            <p>
              <span className="font-weight-bold">Intake Capacity : </span>60
            </p>
            <p>
              <span className="font-weight-bold">Tuition Fees per Annum :</span>
              As per Govt. Directives
            </p>
          </Blockquote>
        </div>
      </PageSection>
      <PageSection>
        <PageSectionHeader title="Eligibility" rightStyle="bottom" />
        <p>Higher Secondary / 10 +2 passed in Science.</p>
      </PageSection>
      <PageSection bgClass="light">
        <PageSectionHeader title="Selection Procedure" leftStyle="bottom" />
        <p>
          Through AIMA-UGAT / NAT / Merit based on previous academic record
          followed by personal interview.
        </p>
      </PageSection>
      <PageSection>
        <PageSectionHeader title="Career Opportunity" rightStyle="bottom" />
        <p>
          A successful BSc.(CS) student may get suitable employment opportunity
          as Software Developer Programmer, System Analyst, E-Com Specialist,
          Software Engineer, Quality Analyst, Web Designer, Web Developer,
          Technical Consultant etc. in Govt. Deptt. PSUs, Banks, Pvt. Industry,
          Teaching Profession etc. One can go teaching profession like
          programmer or pursue higher study like MSc.(Computer Science) or 3
          year MCA or 2 year MCA(Lateral Entry) after completion of this course.
        </p>
      </PageSection>
    </PageWrapper>
  );
};

export default BachelorOfSciencePage;
