import React from 'react'
import { Card, Image } from 'react-bootstrap';

import './MiniCard.styles.scss';

const MiniCard = ({title, imageUrl, bgClass}) => {
    return (
        <Card className={`mini-card text-center border`}>
            <Card.Body>
                <Image src={imageUrl} alt={title} className="img-fluid mb-4"/>
                <Card.Title as="h4" className="text-brand-2 mb-0">{title}</Card.Title>
            </Card.Body>
        </Card>
    )
}

export default MiniCard;
