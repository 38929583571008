import React from "react";
import { Route, Switch } from "react-router-dom";
import SwiperCore, { Autoplay, Navigation, Pagination } from "swiper";

import CustomFooter from "./components/NIISCustomFooter/CustomFooter";
import withDefaultNavbarAndFooter from "./components/NIISPageWithNavbarAndFooter/NIISPageWithNavbarAndFooter";
import FourOFour from "./pages/FourOFour/FourOFourPage";
import HomePage from "./pages/NIISHomePage/HomePage";
import AboutUsPage from "./pages/AboutUsPage/AboutUsPage";
import InformationScienceRouterPageNIIS from "./pages/InformationScienceRouterNIIS/InformationScienceRouterPage";
import JuniorCollegeRouterPageNIIS from "./pages/JuniorCollegeRouterNIIS/JuniorCollegeRouterPage";
import CoursesPage from "./pages/CoursesPage/CoursesPage";
import StudentCellPage from "./pages/StudentCellPage/StudentCellPage";


import "./App.scss";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";


// import { footerData } from "./static-data/footer.data";
// import LogoBanner from "./components/NIISLogoBanner/LogoBanner";
// import CustomNavbar from "./components/NIISCustomNavbar/CustomNavbar";
// import { navbarData, newLinks } from "./static-data/navbar.data";
SwiperCore.use([Autoplay, Navigation, Pagination]);

const App = () => {

	const HomePageWithNav = withDefaultNavbarAndFooter(HomePage);
	return (
		<div>
			
			{/* <LogoBanner/>
			<CustomNavbar linkList={navbarData} newLinks={newLinks} /> */}

			<Switch>
				<Route exact path="/" component={HomePageWithNav} />
				<Route exact path="/home" component={HomePageWithNav} />

				<Route
					path="/niis-information-science"
					component={InformationScienceRouterPageNIIS}
				/>
				<Route
					path="/niis-junior-college"
					component={JuniorCollegeRouterPageNIIS}
				/>
				<Route
					path="/niis-business-administration"
					component={() => {
						window.location.href = "https://niisinstitute.edu.in/";
						return null;
					}}
				/>
				<Route
					path="/niis-engineering-technology"
					component={() => {
						window.location.href = "https://nietbbsr.edu.in/";
						return null;
					}}
				/>


				<Route exact path="/about-us/about-niis" component={AboutUsPage} />
				<Route exact path="/academics/courses" component={CoursesPage} />
				<Route
					path="/student-corner/clubs-committees"
					component={StudentCellPage}
				/>

				<Route path="*" component={FourOFour} />
			</Switch>
			
		</div>
	);
};

export default App;
