export const upcommingEventsData = [
	{
		date: "01.07.2023",
		title: "Plantation Drive",
		venue: "",
		time: "",
	},
	{
		date: "29.07.2023",
		title:
			"International Conference on Innovation and Global Issues in		IT, Management, Social Sciences and Agriculture",
		venue: "",
		time: "",
	},
	{
		date: "07.08.2023",
		title: "Celebration of National Handloom Day",
		venue: "",
		time: "",
	},
	{
		date: "15.08.2023",
		title: "Observation of Independence Day",
		venue: "",
		time: "",
	},
	{
		date: "29.08.2023",
		title: "Intra College Tournament on National Sports Day",
		venue: "",
		time: "",
	},
	{
		date: "30.08.2023",
		title: "Celebration of Raksha Bandhan",
		venue: "",
		time: "",
	},
	{
		date: "05.09.2023",
		title: "Celebration of Teacher’s Day",
		venue: "",
		time: "",
	},
	{
		date: "08.09.2023",
		title:
			"Seminar on Women Literacy on the occasion of International		Literacy Day",
		venue: "",
		time: "",
	},
	{
		date: "09.09.2023",
		title:
			"Workshop on Health Care on the occasion of International		First Aid Day",
		venue: "",
		time: "",
	},
	{
		date: "28.09.2023",
		title: "World Environment Health Day with Switch on Foundation",
		venue: "",
		time: "",
	},
	{
		date: "02.10.2023",
		title: "Celebration of Gandhi Jayanti",
		venue: "",
		time: "",
	},
	{
		date: "11.10.2023",
		title: "Awareness Programme on the Day of the Girl Child in the Madanpur Govt. High School",
		venue: "",
		time: "",
	},

];
