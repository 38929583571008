import React, { useState } from "react";
import { IconContext } from "react-icons";
import classNames from "classnames";

import "./customIcon.style.scss";

const CustomIcon = ({
  test,
  className,
  currentIcon,
  hoverIcon,
  colorClass,
  size,
  shadowClass,
  roundedClass,
  bgClass,
  onClick,
}) => {
  const [Current, setCurrent] = useState(currentIcon);

  const handleMouseEnter = () => {
    hoverIcon && setCurrent(hoverIcon);
  };

  const handleMouseLeave = () => {
    setCurrent(currentIcon);
  };

  const iconClassNames = classNames({
    [colorClass]: colorClass,
    [size]: size,
    [shadowClass]: shadowClass,
    [roundedClass]: roundedClass,
    [bgClass]: bgClass,
  });

  return (
    <span
      className={`custom-icon ${className ? className : ""}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={onClick}
    >
      <IconContext.Provider
        value={{
          className: iconClassNames,
        }}
      >
        {Current}
      </IconContext.Provider>
    </span>
  );
};

export default CustomIcon;
