export const studentsFeedbackData = [
	{
		imageUrl: require("../assets/students-feedback/alumini (1).jpg"),
		name: "Rajesh Kumar Gouda",
		company: "Odisha police",
		feedback: "“ My career underwent a huge transformation at NIIS! ”",
	},
	{
		imageUrl: require("../assets/students-feedback/alumini (5).JPG"),
		name: "Sneha Gupta",
		company: "JP Morgan Chase",
		feedback:
			"“ My opinion is that your network increases your net worth. I made some of my most valuable life connections at NIIS.”",
	},
	{
		imageUrl: require("../assets/students-feedback/alumini (6).JPG"),
		name: "Amit Kumar Singh",
		company: "Goldman Sachs",
		feedback:
			"“ One of the best institutes to get your PG management degree. ”",
	},
	{
		imageUrl: require("../assets/students-feedback/alumini (4).JPG"),
		name: "Saswat Kumar Ray",
		company: "Flipkart",
		feedback: "“ Studying at NIIS was one of the best decisions in my life! ”",
	},
	{
		imageUrl: require("../assets/students-feedback/alumini (2).jpg"),
		name: "Pradosh Kumar Rath",
		company: "Asian Heart Institute",
		feedback:
			"“ I was placed in my ideal company thanks to NIIS! I consider myself fortunate to have been a member of the institution, and it was one of the most difficult and stimulating stretches of my formal schooling. ”",
	},
	{
		imageUrl: require("../assets/students-feedback/alumini (3).jpg"),
		name: "Prashanti Kolla",
		company: "Bank of New York",
		feedback:
			"“ Made memories and friends for a lifetime here. And gave my investment banking career a head start!  ”",
	},

	{
		imageUrl: require("../assets/students-feedback/alumini (7).jpg"),
		name: "Shiwam Singh",
		company: "Merck India",
		feedback:
			"“ The institute lives up to its reputation, that it bridges the gap between Industry and academia.  ”",
	},
	{
		imageUrl: require("../assets/students-feedback/alumini (8).jpg"),
		name: "Rakesh Kumar Sahoo",
		company: "Credit Suisse",
		feedback:
			"“ I believe that NIIS will soon become one of the best institute in the country with their quality education and strong alumni.   ”",
	},
];
