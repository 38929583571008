import React from "react";
import { Container, Row, Col } from "react-bootstrap";
// import BusinessManagement from '../../assets/division/business.png'
import InformationScience from "../../assets/division/information-science.png";
import JuniorScience from "../../assets/division/junior-science.png";
import BusinessAdministraion from "../../assets/division/business.png";
import DivisionCard from "../NIISDivisionCard/DivisionCard";
const NiisDivisionCards = () => {
  return (
    <Container>
      <Row className="justify-content-center">
        <Col md="4" className="d-flex align-items-stretch mb-4">
          <DivisionCard
            title="NIIS Institute of Information Science & Management"
            link="/niis-information-science"
            imageSrc={InformationScience}
          />
        </Col>
        <Col md="4" className="d-flex align-items-stretch mb-4">
          <DivisionCard
            title="NIIS Higher Secondary School"
            link="/niis-junior-college"
            imageSrc={JuniorScience}
          />
        </Col>
        <Col md="4" className="d-flex align-items-stretch mb-4">
          <DivisionCard
            title="NIIS Institute of Business Administration"
            link="/niis-business-administration"
            imageSrc={BusinessAdministraion}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default NiisDivisionCards;
