import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import PageHeader from "../../../components/NIISPageHeader/PageHeader";
import PageSection from "../../../components/NIISPageSection/PageSection";
import PageWrapper from "../../../components/NIISPageWrapper/PageWrapper";
import CustomIcon from "../../../components/NIISCustomIcon/CustomIcon";
import { AiFillFilePdf } from "react-icons/ai";
import PageSectionHeader from "../../../components/NIISPageSectionHeader/PageSectionHeader";
import Blockquote from "../../../components/NIISBlockquote/Blockquote";

const IndustrialVisitAndStudyTourPage = () => {
  return (
    <PageWrapper>
      <PageHeader title="Industrial Visit and Study Tours" />
      <PageSection>
        <PageSectionHeader
          title="Industry Oriented Education"
          rightStyle="bottom"
        />
        <p>
          India entering to the sphere of globalization has brought the globe
          close to look at and understand the need of the globe. Skilling has
          been the core area of education today in addition to academic
          knowledge. Keeping in view the global perspective for students NIIS is
          continuous trying to maintain healthy realizing with different
          industrial & corporate houses. When any students passed out and join
          any industry initially it becomes very difficult to
          survive/accommodate with the corporate culture. Though the process of
          well planned industrial visit & study tours NIIS tries to bridge the
          gap between academic & industry, so as to prepare the student
          "Corporate Fit" on the day of joining in any corporate houses directly
          from the institute's campus.
        </p>
        <p>
          Industry oriented education is an approach to learning from industry
          perspective. Traditional technical teaching methodologies no more
          exist in any part of the world. To be a part of student skilling
          process now we have adapted new mantra of 100% practical exposure for
          our student along with providing them strong fundamental knowledge of
          theory.
        </p>
      </PageSection>
      <PageSection bgClass="light">
        <PageSectionHeader
          title="Industry Oriented Methodology"
          leftStyle="bottom"
        />

        <p>
          Our Industrial orientation methodology is an approach to learning from
          an industry perspective. Now our students are getting basic knowledge
          and advanced knowledge through such exposures. Our emphasis during the
          industrial training is to develop and enhance the technical skills &
          knowledge of the students to grow in this fast paced management and IT
          with the help of live projects. During the program, the students work
          on live projects under the guidance of our expert team. This hands-on
          experience makes them well versed with the different cycles of the
          system development.
        </p>
        <p>
          Keeping in the view the requirement of increase in knowledge,
          improvement in skills and change in professional attitude, study tour
          are organized and conducted from time to time. The confidence level of
          the candidate is strengthened through well planned & well designed
          study tours from time to time.
        </p>
      </PageSection>
      <PageSection>
        <PageSectionHeader title="Gallery" rightStyle="bottom" />
        <Row>
          <Col md="6">
            <Image
              src={require("../../../assets/industrial visit/industrial visit 1.jpg").default}
              className="img-fluid rounded mb-4"
            />
          </Col>
          <Col md="6">
            <Image
              src={require("../../../assets/industrial visit/industrial visit 3.jpg").default}
              className="img-fluid rounded mb-4"
            />
          </Col>
          <Col md="6">
            <Image
              src={require("../../../assets/industrial visit/industrial visit 2.jpg").default}
              className="img-fluid rounded mb-4"
            />
          </Col>
          <Col md="6">
            <Image
              src={require("../../../assets/industrial visit/industrial visit 4.jpg").default}
              className="img-fluid rounded mb-4"
            />
          </Col>
        </Row>
      </PageSection>
    </PageWrapper>
  );
};

export default IndustrialVisitAndStudyTourPage;
