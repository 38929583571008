import React from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import { Link } from 'react-router-dom';
import PageWrapper from "../../components/NIISPageWrapper/PageWrapper";

const FourOFour = () => {
  return (
    <PageWrapper className="bg-brand-2">
      <Container>
        <Row className="justify-content-center p-5">
          <Col md="5" className="bg-brand-white py-5 border-radius-lg">
            <div className="text-center">
              <h1 className="display-1 heading-text heading-text--styled-left-bottom heading-text--styled-right-top">
                404
              </h1>
              <p className="mb-4">Page not found</p>
              <Button as={Link} to="/" className="btn-rounded font-paragraph-font">Go to home</Button>
            </div>
          </Col>
        </Row>
      </Container>
    </PageWrapper>
  );
};

export default FourOFour;
