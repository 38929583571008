import React from "react";
import PageHeader from "../../../components/NIISPageHeader/PageHeader";
import PageSection from "../../../components/NIISPageSection/PageSection";
import PageSectionHeader from "../../../components/NIISPageSectionHeader/PageSectionHeader";
import PageWrapper from "../../../components/NIISPageWrapper/PageWrapper";
import { Col, Row } from "react-bootstrap";

const PrincipalMessagePage = () => {
  return (
    <PageWrapper>
      <PageHeader title="Principal's Message" />
      <PageSection>
        <PageSectionHeader title="Principal's Desk" rightStyle="bottom" />
        <Row>
          <Col md="8">
            <p>
              "The human person must view the world as a partner in the
              preservation of life in order to preserve this equilibrium, the
              human person must understand the importance of all things in his
              surroundings, rather than only view them as a means of survival."
            </p>
          </Col>
          <Col md={{ order: "last" }} xs={{ order: "first" }}>
            {/* Image to be added */}
          </Col>
        </Row>
      </PageSection>
      <PageSection bgClass="light">
        <p>
          We decided to step into the qualitative educational field in the year
          2000 under the shed of Nilachal Management Trust (NEMT) and there has
          been no looking back since then.
        </p>
        <p>
          The Higher Secondary School is a proud constituent of the NIIS group
          which first came into existence in the year 2007 and successfully
          registered under the Government of Odisha CHSE, Odisha.
        </p>
        <p>
          All of us at NIIS Group of Institutions are eager and excited to have
          an amazing year! As your Principal, it is important to me that
          everyone who steps through our doors- students, parents, and teachers
          - are excited to be here! This attitude enables us to meet the
          challenges of academic excellence in a positive, fun, and nurturing
          way. Please feel free to call or email me to discuss any questions or
          concerns you might have throughout the school year.
        </p>

        <p>
          I look forward to working with you as a partner in education to ensure
          your child's success!
        </p>

        <p>Regards,</p>
      </PageSection>
    </PageWrapper>
  );
};

export default PrincipalMessagePage;
