export const facilitiesData = [
    {
      imageUrl: require("../assets/facility/skill-cell.png"),
      title: "NIBA Skill Cell",
    },
    {
      imageUrl: require("../assets/facility/courses.png"),
      title: "Professional Courses",
    },
    {
      imageUrl: require("../assets/facility/classroom.png"),
      title: "Digital Class Room",
    },
    {
      imageUrl: require("../assets/facility/placement-cell.png"),
      title: "Placement Cell",
    },
    {
      imageUrl: require("../assets/facility/library.png"),
      title: "Modern Library",
    },
  ];
  