import React from "react";
import { Col, Image, Row } from "react-bootstrap";
import PageHeader from "../../../components/NIISPageHeader/PageHeader";
import PageSection from "../../../components/NIISPageSection/PageSection";
import PageWrapper from "../../../components/NIISPageWrapper/PageWrapper";
import { newRecruiterImages } from "../../../static-data/new-recruiter.data";

const ProjectsAndInternshipsPage = () => {
  return (
    <PageWrapper>
      <PageHeader title="Projects and Interships" />
      <PageSection>
        <p>
          We will open you to the worldwide business climate and guarantee you
          an expert organization that arrives at all sides of the globe.
          Subsequently, in case you're considering building a worldwide
          vocation, the wide no. of internship offerings will assist you with
          accomplishing your objectives. Students with internships can get all
          the exposure required for building a true office environment spirit
          and to be a team player. Companies like BSNL, NALCO, TISCO, BAJAJ
          Auto, NIC, BSNL, ICICI, HDFC Bank, LG Electronics, Reliance
          Industries, Airtel, MRF, P&G, OPGC, PPT, IMFA, AMUL, HCL, TATA
          CAPITAL, Pantaloons, Asian Paints, LLOYDS are known to take up our
          students for rigorous training and internships.
        </p>
      </PageSection>
      <PageSection bgClass="light">
        <Row>
          {newRecruiterImages.map((img, index) => (
            <Col sm="2" key={`new-recruiter-${index}`}>
              <Image
                src={img.default ? img.default : img}
                className="m-3 img-fluid w-100"

              />
            </Col>
          ))}
        </Row>
      </PageSection>
    </PageWrapper>
  );
};

export default ProjectsAndInternshipsPage;
