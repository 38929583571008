export const ugAdmissionPageData = [
	{
		title: "Diverse Programs",
		imageUrl: require("../assets/admission/diverse.png"),
		description: "Explore a range of undergraduate courses spanning Engineering, Management, Arts, and Sciences. Our curriculum is designed to align with industry needs, ensuring you graduate with skills that matter.",
	},
	{
		title: "Expert Faculty",
		imageUrl: require("../assets/admission/faculty.png"),
		description: "Learn from seasoned educators and industry professionals dedicated to your success. Our faculty members bring real-world experience to the classroom, making your learning experience rich and relevant.",
		
	},
	{
		title: "State-of-the-Art Facilities",
		imageUrl: require("../assets/admission/facilities.png"),
		description: "Immerse yourself in a dynamic learning environment equipped with modern infrastructure, cutting-edge labs, and comprehensive resources that complement your academic journey.",
	},
	{
		title: "Industry Exposure",
		imageUrl: require("../assets/admission/industry.png"),
		description: "Benefit from our strong industry ties, internships, and practical training that bridge the gap between theory and practice. We prepare you not just for exams but for real-world challenges.",
	},
	{
		title: "Inclusive Campus Culture",
		imageUrl: require("../assets/admission/campus.png"),
		description: "Join a vibrant community of students from diverse backgrounds, where you'll find a supportive network to share ideas, collaborate, and build lifelong friendships.        ",
	},
	

];