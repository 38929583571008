export const academicAdvisorData = [
  {
    name: "Prof(Dr.) B. B. Mishra, Advisor",
    designation: "Deptt. of Business Administration",
    location: "Utkal University, Bhubaneswar",
    content:
      "Memories fade with time and lessons are forgotten, but experiences shape a human being and leave a lasting mark. Teachers have the privilege of guiding early experiences and discoveries.",
  },
  {
    name: "Prof(Dr.) Sadasib Pradhan, Advisor",
    designation: "Deptt. of Ancient History",
    location: "Utkal University, Bhubaneswar",
    content:
      "The carving and chipping away to create a masterpiece takes years of dedication. Teachers help students discover their interests, passions, and ultimately themselves.",
  },
  {
    name: "Prof(Dr.) P. K. Behera, Advisor",
    designation: "Deptt. of Computer Science & Application",
    location: "Utkal University, Bhubaneswar",
    content:
      "Free the child's potential, and you will transform him into the world.",
  },
  {
    name: "Shri Shyamal Ray, MTech (IIT, Kgp.), Advisor",
    designation: "Ex-CGM, Coal India Ltd",
    content:
      "The task of the modern educator is not to cut down jungles, but to irrigate deserts.",
  },
  {
    name: "Prof(Dr.) D.V. Ramana, Advisor",
    designation: "Professor,Accounting XIMB",
    content:
      "Nine-tenths of education is encouragement, which we have always aimed to provide as a students’s backbone",
  },
  {
    name: "Prof(Dr.) B. K. Mohanty, Advisor",
    location: "OUAT, Bhubaneswar",
    content:
      "Every child deserves a champion—an adult who will never give up on them, who understands the power of connection and insists that they become the best that they can possibly be.",
  },
  {
    name: "Shri B. N. Mishra, FCA, Advisor",
    designation: "Charted Accounts & Consultant",
    content:
      "Students may be influenced by their families and upbringing, but it is up to teachers to bring out the best in each one. Treating each student as unique and worthy of being taught is an everyday challenge that requires teachers to set aside personal biases and preconceptions.",
  },
  {
    name: "Shri P.K. Prusty, Advisor",
    designation: " ",
    content:
      "Being the smartest person in the room doesn’t guarantee respect, and kindness is not dependent on words or knowledge. Caring is shown through actions, motives and personal sacrifice.",
  },
  {
    name: "Prof(Dr.) Damodar Rout, Advisor",
    designation: "Ex-Chairman, CHSE, Odisha",
    content:
      "Historically, education was reserved for the wealthy and privileged to ensure power stayed with the elite. However, times have changed and change has been created by people from all walks of life. The thread weaving the change-makers together? Education.",
  },
];
