import React from "react";
import PageHeader from "../../../components/NIISPageHeader/PageHeader";
import PageSection from "../../../components/NIISPageSection/PageSection";
import PageWrapper from "../../../components/NIISPageWrapper/PageWrapper";
import CustomIcon from "../../../components/NIISCustomIcon/CustomIcon";
import { AiFillFilePdf } from "react-icons/ai";
import PageSectionHeader from "../../../components/NIISPageSectionHeader/PageSectionHeader";
import Blockquote from "../../../components/NIISBlockquote/Blockquote";

const BachelorOfArtsPage = () => {
  return (
    <PageWrapper>
      <PageHeader title="Bachelor of Arts (Honours)" imageUrl={require('../../../assets/information-science-academic/page/BA.jpg')}/>
      <PageSection>
        <p>
        In BA (Hons), you study a particular specialisation in
your three year course which grooms you for an intellectual and prolific study of
a lifetime and builds your career in the right direction.
        </p>

        <p className="mb-4">
          The duration of Bachelor of Arts Degree spans over a period of 3
          years.
        </p>

        <p className="text-center">
          <a href="/#" className="download-link">
            <CustomIcon currentIcon={AiFillFilePdf} />
            <span className="ml-2">DOWNLOAD COMPLETE SYLLABUS</span>
          </a>
        </p>
      </PageSection>
      <PageSection bgClass="light">
        <PageSectionHeader title="Features" leftStyle="bottom" />
        <div className="ml-2">
          <ul>
            <li>
              <p>
                The course covers all the related topics for the field and gives
                rigorous training to the students.
              </p>
            </li>
            <li>
              <p>
                The students go through a well-defined study program for their
                all-round development.
              </p>
            </li>
            <li>
              <p>
                It is generally considered the easiest stream according to the
                larger populace.
              </p>
            </li>
            <li>
              <p>
                Bachelor of Arts Degree offers more avenues of employment
                opportunities. This is primarily because students seeking
                Engineering and Science are limited to their respective fields
                whereas students pursuing Arts are not limited to their specific
                fields; their scope of specialization is vast.
              </p>
            </li>
            <li>
              <p>
                With respect to the previous academic background and the
                personal choice and ability of the student towards a specific
                program, there exists an appropriate program for almost each and
                every candidate opting for this degree.
              </p>
            </li>
          </ul>
        </div>
      </PageSection>
      <PageSection>
        <PageSectionHeader title="Course Structure" rightStyle="bottom" />
        <div className="ml-2">
          <Blockquote>
            <p>
              <span className="font-weight-bold">Semester : </span>
              BA(Economics,Pol.Sc,Sociolog,English,Psychology)
            </p>
            <p>
              <span className="font-weight-bold">Duration : </span>3 yrs(6
              Semesters)
            </p>
            <p>
              <span className="font-weight-bold">Intake Capacity : </span>128
            </p>
            <p>
              <span className="font-weight-bold">
                Tuition Fees per Annum :
              </span>
              As per Govt. Directives
            </p>
          </Blockquote>
        </div>
      </PageSection>
      <PageSection bgClass="light">
        <PageSectionHeader title="Eligibility" leftStyle="bottom" />
        <p>+2 Passed Student in any Stream.</p>
      </PageSection>
      <PageSection>
        <PageSectionHeader title="Selection Procedure" rightStyle="bottom" />
        <p>Online admission conducted by Govt. of Odisha.</p>
      </PageSection>
      <PageSection bgClass="light">
        <PageSectionHeader title="Career Opportunity" leftStyle="bottom" />
        <p>
          After the completion of the BA degree there are various options
          available for the students, it offers a wide range of postgraduate
          options and career opportunities. Individuals can pursue a career in
          different fields after successful completion of their Bachelor of Arts
          degree. They can become an Economist, Historian, Archaeologist,
          Educationalist, Philosopher, Political scientist, Personnel manager,
          Social activist, Public Relation Executive, Psychologist, Sociologist,
          Philosopher, and Journalist etc.
        </p>
      </PageSection>
    </PageWrapper>
  );
};

export default BachelorOfArtsPage;
