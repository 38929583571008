import firebase from "firebase/app";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDXXtoZ4g1GseqPBTugjaGGCZ-nnGW2YGk",
  authDomain: "niiswebsitedata.firebaseapp.com",
  projectId: "niiswebsitedata",
  storageBucket: "niiswebsitedata.appspot.com",
  messagingSenderId: "455573610515",
  appId: "1:455573610515:web:86a94346398a5eba139fe6",
  measurementId: "G-SVC4999QY6",
};

// Initialize Firebase

firebase.initializeApp(firebaseConfig);

export default firebase;
export const db = firebase.firestore();
export const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp;
