export const newRecruiterImages = [
    require('../assets/recruiter-new/logo1.jpg'),
    require('../assets/recruiter-new/logo2.png'),
    require('../assets/recruiter-new/logo3.png'),
    require('../assets/recruiter-new/logo4.jpeg'),
    require('../assets/recruiter-new/logo5.JPG'),
    require('../assets/recruiter-new/logo6.jpg'),
    require('../assets/recruiter-new/logo7.jpg'),
    require('../assets/recruiter-new/logo8.png'),
    require('../assets/recruiter-new/logo9.jpg'),
    require('../assets/recruiter-new/logo10.png'),
    require('../assets/recruiter-new/logo11.jpg'),
    require('../assets/recruiter-new/logo12.jpg'),
    require('../assets/recruiter-new/logo13.jpg'),
    require('../assets/recruiter-new/logo14.png'),
    require('../assets/recruiter-new/logo15.png'),
    require('../assets/recruiter-new/logo16.jpg'),
    require('../assets/recruiter-new/logo17.jpg'),
]