import React from "react";
import { Link } from "react-router-dom";
import { Card } from "react-bootstrap";

import "./CourseCard.styles.scss";

const CourseCard = ({ imageUrl, title, link }) => {
	return (
		<Card className="w-100 course-card shadow-sm">
			<Link to={link} style={{ color: "unset", textDecoration: "none" }}>
				<Card.Img
					top="true"
					src={imageUrl.default ? imageUrl.default : imageUrl}
					alt={title}
					width={20}
					style={{
						display: "block",
						marginLeft: "auto",
						marginRight: "auto",
						width: "50%",
						marginTop: "20px",
					}}
				/>
				<hr className="mb-0" />
				<Card.Body>
					<Card.Title as="h5" className="text-brand-1 text-center">
						{title}
					</Card.Title>
				</Card.Body>
			</Link>
		</Card>
	);
};

export default CourseCard;
