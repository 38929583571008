import React from "react";
import "./NiisJuniorCollegeHero.styles.scss";

const NiisJuniorCollegeHero = () => {
  return (
    <div className="hero niis-junior-college-hero">
      <div className="hero-content text-center">
        <div>
          <h1 className="heading-text display-5 heading-text--styled-left-bottom heading-text--styled-right-top">
            NIIS HIGHER
          </h1>
          <br />
          <h1 className="heading-text display-5">SECONDARY</h1>
          <br />
          <h1 className="heading-text display-5 heading-text--styled-right-bottom heading-text--styled-left-top">
            SCHOOL
          </h1>

          <br />

          <h3 className="font-paragraph-font text-brand-1 mt-2">
            Grooming to excel
          </h3>
        </div>
      </div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="hero-svg-element"
        width="100%"
        height="280"
      >
        <path
          fill="#fff"
          fillOpacity="1"
          preserveAspectRatio="none"
          viewBox="0 0 2400 320"
        >
          <animate
            repeatCount="indefinite"
            dur="10s"
            fill="freeze"
            attributeName="d"
            values="M0,288L120,288C240,288,480,288,720,266.7C960,245,2800,203,2900,181.3L3000,160L3000,320L2900,320C2800,320,960,320,720,320C480,320,240,320,120,320L0,320Z;
            M0,160L120,165.3C240,171,480,181,720,197.3C960,213,2800,235,2900,245.3L3000,256L3000,320L2900,320C2800,320,960,320,720,320C480,320,240,320,120,320L0,320Z;
            M0,224L120,240C240,256,480,288,720,288C960,288,2800,256,2900,240L3000,224L3000,320L2900,320C2800,320,960,320,720,320C480,320,240,320,120,320L0,320Z;
            M0,192L120,186.7C240,181,480,171,720,186.7C960,203,2800,245,2900,266.7L3000,288L3000,320L2900,320C2800,320,960,320,720,320C480,320,240,320,120,320L0,320Z;
            M0,288L120,288C240,288,480,288,720,266.7C960,245,2800,203,2900,181.3L3000,160L3000,320L2900,320C2800,320,960,320,720,320C480,320,240,320,120,320L0,320Z;"
          />
          {/* <animate
            dur="10s"
            attributeName="fill"
            repeatCount="indefinite"
            values="#1d3557; #457b9d"
          /> */}
        </path>
      </svg>
    </div>
  );
};

export default NiisJuniorCollegeHero;
