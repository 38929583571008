import React from "react";
import PageHeader from "../../../components/NIISPageHeader/PageHeader";
import PageSection from "../../../components/NIISPageSection/PageSection";
import PageWrapper from "../../../components/NIISPageWrapper/PageWrapper";

const FacilitiesPage = () => {
  return (
    <PageWrapper>
      <PageHeader title="Infrastructure" />
      <PageSection>
        <p>
          The sophistically designed infrastructure and amenities around the
          campus are made with care and lots of planning so as to provide a safe
          surroundings to students where they can thrive and prosper with good
          education, eating habits and with regard to good health.
        </p>
        <p>
          The sprawling 10 acres campus with over 2.5Lakh built up area has
          various sections of academic wings, hostel facilities, lab and
          workshops, playing field and leisure grounds help children to sustain
          in a free and spacious environment without any compromises on
          accommodation and study.
        </p>
      </PageSection>
    </PageWrapper>
  );
};

export default FacilitiesPage;
