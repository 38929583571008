import React, { useState, useEffect } from "react";
import bsBreakpoints from "bs-breakpoints";
import classNames from "classnames";
import { Col, Row } from "react-bootstrap";
import PageSection from "../PageSection/PageSection";
import PageSectionHeader from "../NIISPageSectionHeader/PageSectionHeader";

const AlumniStoriesResponsive = () => {
	const [currentBreakPoint, setCurrentBreakPoint] = useState("");

	useEffect(() => {
		bsBreakpoints.init();
		setCurrentBreakPoint(bsBreakpoints.getCurrentBreakpoint());

		const handleBreakPoint = (event) => {
			setCurrentBreakPoint(event.detail);
		};

		window.addEventListener("new.bs.breakpoint", handleBreakPoint);

		return () => {
			window.removeEventListener("new.bs.breakpoint", handleBreakPoint);
		};
	}, []);

	const descriptionClasses = classNames("mb-4 text-center text-text-color", {
		"font-sm": currentBreakPoint === "xSmall" || currentBreakPoint === "small",
	});

	return (
		<PageSection bgClass="background-white">
			{/* <ResponsivePageHeader
				centered={true}
				title="NIBA Alumni Across the World! 
 "
			/> */}
			<PageSectionHeader title="Benefits of joining our alumni network:" rightStyle="bottom" />

			<Row className="justify-content-between">
				<Col md="6" className="mb-5 mb-md-0 ">
					<div
						className="border mb-4 rounded px-5 py-4 bg-light"
						style={{ height: "85%" }}
					>
						<p className="display-9 font-weight-normal font-heading-font">
							Networking Opportunities
						</p>
						<p style={{ textAlign: "justify" }}>
							Connect with fellow alumni, industry experts, and professionals in your field. 
							Expand your professional network, exchange ideas, and explore potential collaborations or career opportunities.
						</p>
					</div>
				</Col>
				<Col md="6" className="mb-5 mb-md-0 ">
					<div
						className="border mb-4 rounded px-5 py-4 bg-light"
						style={{ height: "85%" }}
					>
						<p className="display-9 font-weight-normal font-heading-font">
							Lifelong Learning
						</p>
						<p style={{ textAlign: "justify" }}>
							Stay informed about upcoming workshops, seminars, and webinars organized by NIIS. 
							Enhance your knowledge and skills through continued learning and professional development opportunities.

						</p>
					</div>
				</Col>
				<Col md="6" className="mb-5 mb-md-0 ">
					<div
						className="border mb-4 rounded px-5 py-4 bg-light"
						style={{ height: "85%" }}
					>
						<p className="display-9 font-weight-normal font-heading-font">
							Career Support
						</p>
						<p style={{ textAlign: "justify" }}>
							Avail yourself of career guidance services and job placement assistance. 
							Our dedicated career counseling team can provide valuable advice, help you explore 
							new career paths, and connect you with relevant job opportunities.

						</p>
					</div>
				</Col>
				<Col md="6" className="mb-5 mb-md-0 ">
					<div
						className="border mb-4 rounded px-5 py-4 bg-light"
						style={{ height: "85%" }}
					>
						<p className="display-9 font-weight-normal font-heading-font">
							Alumni Events and Reunions
						</p>
						<p style={{ textAlign: "justify" }}>
							Attend alumni events, reunions, and social gatherings organized by NIIS. 
							Reconnect with old friends, reminisce about your time at the institution, 
							and build lasting memories.

						</p>
					</div>
				</Col>
				<Col md="6" className="mb-5 mb-md-0 ">
					<div
						className="border mb-4 rounded px-5 py-4 bg-light"
						style={{ height: "85%" }}
					>
						<p className="display-9 font-weight-normal font-heading-font">
							Mentorship
						</p>
						<p style={{ textAlign: "justify" }}>
							Contribute to the growth and development of current students by becoming a mentor. 
							Share your experiences, insights, and industry knowledge to guide and inspire the 
							next generation of professionals.


						</p>
					</div>
				</Col>
				<Col md="6" className="mb-5 mb-md-0 ">
					<div
						className="border mb-4 rounded px-5 py-4 bg-light"
						style={{ height: "85%" }}
					>
						<p className="display-9 font-weight-normal font-heading-font">
							Alumni Achievements and Spotlights
						</p>
						<p style={{ textAlign: "justify" }}>
							Share your success stories, accomplishments, and milestones with the NIIS community. 
							We take pride in showcasing the achievements of our alumni and highlighting their 
							contributions to their respective fields.

						</p>
					</div>
				</Col>
			</Row>
		</PageSection>
	);
};

export default AlumniStoriesResponsive;
