import React from "react";
import { Row, Col } from "react-bootstrap";
import { GoChevronLeft, GoChevronRight } from "react-icons/go";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation } from "swiper";
import TestimonialCard from "../NIISTestimonialCard/TestimonialCard";
import CustomIcon from "../NIISCustomIcon/CustomIcon";

import "./TestimonialSlides.styles.scss";

SwiperCore.use([Autoplay, Navigation]);

const TestimonialSlides = ({
  slidesData,
  numberOfSlides,
  controlName,
  type,
  shadowImage
}) => {
  let navigationParams = {
    prevEl: `.${controlName}-prev`,
    nextEl: `.${controlName}-next`,
  };

  console.log(navigationParams);

  return (
    <div className="testimonial-container">
      <div className="testimonial-controls">
        <Row className="justify-content-between">
          <Col>
            <span className={`${controlName}-prev control`}>
              <CustomIcon
                currentIcon={GoChevronLeft}
                colorClass="text-brand-2"
                size="lg"
                shadowClass="shadow"
                roundedClass="rounded-circle"
                bgClass="bg-light"
              />
            </span>
          </Col>
          <Col className="text-right">
            <span className={`${controlName}-next text-right control`}>
              <CustomIcon
                currentIcon={GoChevronRight}
                colorClass="text-brand-2"
                size="lg"
                shadowClass="shadow"
                roundedClass="rounded-circle"
                bgClass="bg-light"
              />
            </span>
          </Col>
        </Row>
      </div>
      <Swiper
        grabCursor={true}
        loop={true}
        scrollbar={{ el: "", draggable: true, hide: true }}
        slidesPerView={numberOfSlides}
        autoplay={{ delay: 3000 }}
        navigation={navigationParams}
        className="shadow bg-white"
      >
        {slidesData
          ? slidesData.map((data) => (
              <SwiperSlide key={data.imageUrl.default ? data.imageUrl.default : data.imageUrl} className="d-flex align-items-stretch">
                <TestimonialCard {...data} type={type} shadowImage={shadowImage}/>
              </SwiperSlide>
            ))
          : null}
      </Swiper>
    </div>
  );
};

export default TestimonialSlides;
