import React, { useState, useEffect } from "react";
import bsBreakpoints from "bs-breakpoints";
import classNames from "classnames";
import ResponsivePageHeader from "../ResponsivePageHeader/ResponsivePageHeader";
import PageSection from "../PageSection/PageSection";
import CustomIcon from "../CustomIcon/CustomIcon";
import { AiFillFilePdf } from "react-icons/ai";


const AboutAlumniResponsive = () => {
	const [currentBreakPoint, setCurrentBreakPoint] = useState("");

	useEffect(() => {
		bsBreakpoints.init();
		setCurrentBreakPoint(bsBreakpoints.getCurrentBreakpoint());

		const handleBreakPoint = (event) => {
			setCurrentBreakPoint(event.detail);
		};

		window.addEventListener("new.bs.breakpoint", handleBreakPoint);

		return () => {
			window.removeEventListener("new.bs.breakpoint", handleBreakPoint);
		};
	}, []);

	const descriptionClasses = classNames("mb-4 text-center text-text-color", {
		"font-sm": currentBreakPoint === "xSmall" || currentBreakPoint === "small",
	});

	return (
		<PageSection bgClass="background-color">
			<ResponsivePageHeader centered={true} title="Alumni " />
			<p className={descriptionClasses}>
			Welcome to the Alumni Page of NIIS Institute of Information Science & Management! We are delighted to connect with our esteemed 
			alumni and celebrate the successes and achievements of our alumni community.<br/><br/>

			At NIIS, we believe that our alumni are our greatest ambassadors, representing the values and excellence that our institution stands for. 
			We take immense pride in the accomplishments of our alumni, who have made a significant impact in various sectors and have carved a niche 
			for themselves in their respective fields.<br/><br/>

			Through this platform, we aim to foster a strong and lifelong bond with our alumni, providing a space for networking, 
			collaboration, and mutual growth. Here, you can reconnect with old friends, make new connections, and stay updated with the 
			latest developments at NIIS.

			</p>
			{/* <a
				href={process.env.PUBLIC_URL + "/CRS.pdf"}
				className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
				target="_blank"
				rel="noopener noreferrer"
			>
				<CustomIcon currentIcon={AiFillFilePdf} size="md" />
				<span className="ml-2">
					DOWNLOAD Certificate of Registration of Societies
				</span>
			</a> */}
		</PageSection>
	);
};

export default AboutAlumniResponsive;
