import React, { useEffect, useState } from "react";
import { Navbar, Nav, Image } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { FaStream, FaSearch } from "react-icons/fa";
import { FiX } from "react-icons/fi";
import bsBreakpoints from "bs-breakpoints";
import classNames from "classnames";

import NissLogo from "../../assets/logo/niis-logo.png";
import CustomNavLinksBuilder from "../NIISCustomNavLinksBuilder/CustomNavLinksBuilder";
import CustomIcon from "../NIISCustomIcon/CustomIcon";

const CustomNavbar = ({ linkList, newLinks, collegeName}) => {
  const history = useHistory();

  const [currentBreakPoint, setCurrentBreakPoint] = useState("");
  const [isExpanded, setIsExpanded] = useState(false);
  const [navbarTransparent, setNavbarTransparent] = useState(true);
  const [pathname, setPathname] = useState(history.location.pathname);

  const [currentLinkList, setCurrentLinkList] = useState(linkList);

  useEffect(() => {
    bsBreakpoints.init();
    setCurrentBreakPoint(bsBreakpoints.getCurrentBreakpoint());

    const handleBreakPoint = (event) => {
      setCurrentBreakPoint(event.detail);
    };

    const handleWindowScroll = () => {
      newLinks.includes(pathname) && window.scrollY < 100
        ? setNavbarTransparent(true)
        : setNavbarTransparent(false);
    };

    handleWindowScroll();

    window.addEventListener("new.bs.breakpoint", handleBreakPoint);

    window.addEventListener("scroll", handleWindowScroll);

    return () => {
      window.removeEventListener("new.bs.breakpoint", handleBreakPoint);
      window.removeEventListener("scroll", handleWindowScroll);
    };
  }, [newLinks, pathname]);

  useEffect(() => {
    if (currentBreakPoint === "xSmall" || currentBreakPoint === "small") {
      const index = linkList.findIndex((item) => item.mobileStart);
      setCurrentLinkList([
        ...linkList.slice(index),
        ...linkList.slice(0, index),
      ]);
    } else {
      setCurrentLinkList(linkList);
    }
  }, [currentBreakPoint, linkList]);

  useEffect(() => {
    history.listen((location) => {
      setPathname(location.pathname);
    });
  }, [history]);

  const navbarClasses = classNames(
    "bg-heading-color d-flex justify-content-between flex-nowrap",
    {
      "align-items-start":
        currentBreakPoint === "xSmall" || currentBreakPoint === "small",
    },
    {
      "align-items-center": !(
        currentBreakPoint === "xSmall" || currentBreakPoint === "small"
      ),
    },
    {
      "navbar--padded": !(
        currentBreakPoint === "xSmall" || currentBreakPoint === "small"
      ),
    },
    {
      "flex-row-reverse": !(
        currentBreakPoint === "xSmall" || currentBreakPoint === "small"
      ),
    },

    {
      
    }
    // {
    //   "navbar-transparent": navbarTransparent,
    // }
  );

  const navbarBrandClasses = classNames({
    hide: !(currentBreakPoint === "xSmall" || currentBreakPoint === "small"),
  });

  const navbarTitleClasses = classNames(
    "mb-0 text-white text-center font-weight-bold",
    {
      hide: !(currentBreakPoint === "xSmall" || currentBreakPoint === "small"),
    }
  );

  const navbarToggleClasses = classNames("navbar-toggler border-none pt-2");

  const navbarCloseClasses = classNames({
    hide: !(currentBreakPoint === "xSmall" || currentBreakPoint === "small"),
  });

  const mobileNavHeaderClasses = classNames("mobile-header", {
    hide: !(currentBreakPoint === "xSmall" || currentBreakPoint === "small"),
  });

  const navClasses = classNames(
    "ml-auto",
    {
      "navbar-nav--padded": !(
        currentBreakPoint === "xSmall" || currentBreakPoint === "small"
      ),
    },
    {
      "mobile-nav":
        currentBreakPoint === "xSmall" || currentBreakPoint === "small",
    },
    {
      "mobile-nav-expand":
        (currentBreakPoint === "xSmall" || currentBreakPoint === "small") &&
        isExpanded,
    }
  );

  return currentLinkList ? (
    
    <Navbar
      collapseOnSelect
      expand={currentLinkList ? "md" : "sm"}
      sticky="top"
      className={navbarClasses}
      style={{paddingTop:"1rem",paddingBottom:"1rem"}}
      expanded={isExpanded}
    >
      <CustomIcon
        className={navbarToggleClasses}
        currentIcon={FaStream}
        colorClass="text-white"
        onClick={() => {
          setIsExpanded(!isExpanded);
        }}
      />

      <h5 className={navbarTitleClasses}>
        {collegeName}
      </h5>

      

      <Nav className={navClasses}>
        <div className={mobileNavHeaderClasses}>
          <Link to="/" className={navbarBrandClasses}>
            <Image src={NissLogo} width={64} height={64} />
          </Link>
          <CustomIcon
            className={navbarCloseClasses}
            colorClass="text-white"
            size="lg"
            currentIcon={FiX}
            onClick={() => {
              setIsExpanded(false);
            }}
          />
        </div>
        <CustomNavLinksBuilder
          keyName="navbar"
          linkList={currentLinkList}
          setNavbarExpanded={setIsExpanded}
        />
      </Nav>
    </Navbar>
  ) : null;
};

export default CustomNavbar;
