import React from "react";
import PageHeader from "../../../components/NIISPageHeader/PageHeader";
import PageSection from "../../../components/NIISPageSection/PageSection";
import PageWrapper from "../../../components/NIISPageWrapper/PageWrapper";
import CustomIcon from "../../../components/NIISCustomIcon/CustomIcon";
import { AiFillFilePdf } from "react-icons/ai";
import PageSectionHeader from "../../../components/NIISPageSectionHeader/PageSectionHeader";
import Blockquote from "../../../components/NIISBlockquote/Blockquote";

const AntiRaggingPage = () => {
    return (
        <PageWrapper>
            <PageHeader title="Anti-Ragging Policy"/>
            <PageSection>
                <p>
                Parents put an immense trust and faith in the hands of the institute while
sending their children to us. We always try to do right by it and carry
concern at all times for the physical and mental stability of the child. We
have drafted the anti-ragging policy for the same reasons-so that no
student should be disrupted any way by another student or staff. We
urge the students to report any unwanted activity to the management
without any fear and they are dealt with utmost strictness and
responsibility.
                </p>
                <p>
                We make sure that a student stays fit and of sound mind all the time.
                </p>

                <p className="text-center mt-4">
          <a href="/#" className="download-link">
            <CustomIcon currentIcon={AiFillFilePdf} />
            <span className="ml-2">DOWNLOAD ANTI-RAGGING POLICY</span>
          </a>
        </p>
            </PageSection>
        </PageWrapper>
    )
}

export default AntiRaggingPage
