export const managingTrustee = [
  {
    name: "Founder Managing Trustee",
    content:
      "It gives pleasure to see this Institution achieving excellence and carve a niche in the education map of Eastern India, particularly in Odisha. The constituent Institutes of NIIS Group of Institutions have been established for over a decade and are well known for their academic excellence and philanthropic endeavor to create global leaders with a considerate attitude and ethical orientation. We are always proactive in our approach to understand and cater to the changing requirements of our students, employees and the community at a large.",
    imageUrl: require("../assets/managing-trustee/founder-cum-ceo-img.png"),
  },
  {
    name: "Joint Managing Trustee",
    content:
      "The management as well as patron members of the institutions are determined to maintain value laden learning, improvement and transformation in technical and professional education. The latest curriculum with state-of-the-art infrastructure ensures that the students gain optimal advantages in terms of advanced knowledge and managerial skills. It is apparent that the facts and figures cannot bring changes in human lives rather it’s the ideas that can result in sustainable transformation of human behavior. That’s why we encourage our students to explore new horizons of innovative thinking by developing a congenial environment of interactive learning and life skills.",
    imageUrl: require("../assets/managing-trustee/deans-desk-niis.png"),
  },
];

export const niisStudentTestimonial = [
  {
    name: "HARESH KHARSEL (MBA)",
    subTitle: "AIS DISTRIBUTION SERVICES",
    content:
      "It was NIIS that set the tone of my career. I realized my potential only due to.",
    imageUrl: require("../assets/alumni/haresh-kharsel.jpg"),
  },
  {
    name: "DEBASIS PANDAB  (MBA)",
    subTitle: "ASIAN HEART INSTITUTE",
    content:
      "The stint with NIIS worked wonders for me & I am really thankful to the Institute.",
    imageUrl: require("../assets/alumni/debasis-pandab.jpg"),
  },
  {
    name: "SHALINI (BSc ITM)",
    subTitle: "TCS",
    content:
      "I owe my heartiest thanks to NIIS for giving me a brilliant platform to walk towards success. Proud to be a NIISIAN.",
    imageUrl: require("../assets/alumni/shalini.jpg"),
  },
];
