import React from "react";
import PageHeader from "../../../components/NIISPageHeader/PageHeader";
import PageSection from "../../../components/NIISPageSection/PageSection";
import PageWrapper from "../../../components/NIISPageWrapper/PageWrapper";
import CustomIcon from "../../../components/NIISCustomIcon/CustomIcon";
import { AiFillFilePdf } from "react-icons/ai";
import PageSectionHeader from "../../../components/NIISPageSectionHeader/PageSectionHeader";
import Blockquote from "../../../components/NIISBlockquote/Blockquote";

const MasterinPersonalManagementAndIndustrialRelationsPage = () => {
  return (
    <PageWrapper>
      <PageHeader title="Master in Personal Management And Industrial Relations (MPMIR)" imageUrl={require('../../../assets/information-science-academic/page/MPMIR.jpg')}/>
      <PageSection>
        <p className="mb-4">
        It is a
two-year full time Postgraduate Program focusing primarily on Personal
Management & setting Industrial relationship among the industries,
implementing policies for individual safety & growth of the organization.
        </p>

        <p className="text-center">
          <a href="/#" className="download-link">
            <CustomIcon currentIcon={AiFillFilePdf} />
            <span className="ml-2">DOWNLOAD COMPLETE SYLLABUS</span>
          </a>
        </p>
      </PageSection>
      <PageSection bgClass="light">
        <PageSectionHeader title="Course Structure" leftStyle="bottom" />
        <div className="ml-2">
          <Blockquote>
            <p>
              <span className="font-weight-bold">Name of the Course : </span>
              MPMIR
            </p>
            <p>
              <span className="font-weight-bold">Duration : </span>2 yrs (4
              Semesters)
            </p>
            <p>
              <span className="font-weight-bold">Intake Capacity : </span>60
            </p>
            <p>
              <span className="font-weight-bold">
                Tuition Fees per Annum :
              </span>
              As per Govt. Directives
            </p>
          </Blockquote>
        </div>
      </PageSection>
      <PageSection>
        <PageSectionHeader title="Eligibility" rightStyle="bottom" />
        <p>
          A Bachelor's Degree (10 +2 +3) passed in Arts / Science / Commerce
          with 50% of marks for general (45% of marks for SC /ST) candidates.
        </p>
      </PageSection>
      <PageSection bgClass="light">
        <PageSectionHeader title="Selection Procedure" leftStyle="bottom" />
        <p>
          Through NIIS Aptitude Test (NAT) along with merit of previous academic
          record followed by personal interview. Admission of Special category
          seats shall be made basing on a merit list among the applicants to be
          followed by Personal Aptitude Test (PAT).
        </p>
      </PageSection>
      <PageSection>
        <PageSectionHeader title="Career Opportunity" rightStyle="bottom" />
        <p>
          After successful completion of this two years full time course, a
          candidate may get employment opportunity in Govt. Dept., PSUs,
          Corporations, Banks, Railways, Educational Establishment & relating
          organizations etc. as Manager, Administrative Officer, Executive,
          Project Co-coordinator, Client Relationship Manager, Consultant,
          Teaching professional with a decent pay package.
        </p>
      </PageSection>
    </PageWrapper>
  );
};

export default MasterinPersonalManagementAndIndustrialRelationsPage;
