import React from "react";
import PageHeader from "../../../components/NIISPageHeader/PageHeader";
import PageSection from "../../../components/NIISPageSection/PageSection";
import PageWrapper from "../../../components/NIISPageWrapper/PageWrapper";
import PageSectionHeader from "../../../components/NIISPageSectionHeader/PageSectionHeader";


const CommunityServicePage = () => {
  return (
    <PageWrapper>
      <PageHeader title="Community Service" />
      <PageSection>
        <p>
        Engaging in community service provides students with the opportunity to
become active members of their community and has a lasting, positive
impact on society at large. Community service or volunteerism enables
students to acquire life skills and knowledge, as well as provide a service
to those who need it most.
        </p>
        <p>
        NIIS provides a student-centric service program, which is the National
Service Scheme (NSS) and is complementary to education. This program
is a noble experiment in academic extension, which inculcates the spirit of
voluntary work among students as well as teachers through community
interaction. This endeavor is an Indian Government sponsored program
under the Department of Youth Affairs and Sports of the Government of
India. The scheme was formally launched on 24th September, 1969.
During its 45 years of fruitful existence, more than 3 Crore students have
benefitted from this large student volunteer program so far.
        </p>
      </PageSection>
      <PageSection>
        <PageSectionHeader title="Women's Day" rightStyle="bottom" />
        <p>Comming Soon</p>
      </PageSection>
      <PageSection bgClass="light"> 
        <PageSectionHeader title="Aids Day" leftStyle="bottom" />
        <p>Comming Soon</p>
      </PageSection>
      <PageSection>
        <PageSectionHeader title="Blood Donation Camp" rightStyle="bottom" />
        <p>Comming Soon</p>
      </PageSection>
      <PageSection bgClass="light"> 
        <PageSectionHeader title="Swacha Bharat" leftStyle="bottom" />
        <p>Comming Soon</p>
      </PageSection>
    </PageWrapper>
  );
};

export default CommunityServicePage;
