import React from "react";
import {Button, Row, Col } from 'react-bootstrap';
import InformationScienceAdmissionEnquiryForm from '../NIISInformationScienceAdmissionEnquiryForm/InformationScienceAdmissionEnquiryForm';

const EnquirySection = () => {
  return (
    <Row className="justify-content-between align-items-stretch">
      <Col md="6">
        <div className="mb-4">
          <InformationScienceAdmissionEnquiryForm />
        </div>
      </Col>
      <Col md="6">
        <div className="p-2 text-center">
          <h2 className="text-brand-1">
            Interested in taking admission?
          </h2>
          <h6 className="text-brand-2">
            Thank you for your interest in NIIS Group of Institutions.
          </h6>
          <p className="mb-4">
            If you are interested in NIIS, we strongly recommend that you get in
            touch and come to our institute which we are so proud of and see how
            we work. Please fill in the enquiry form to log your interest in the
            NIIS and we will be in touch to arrange for a tour of our campus.
          </p>
            <Button>Read More</Button>
        </div>
      </Col>
    </Row>
  );
};

export default EnquirySection;
