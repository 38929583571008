import React from "react";
import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./coursesMiniCard.style.scss";

const CoursesMiniCard = ({ title, link, description, imageUrl, name }) => {
  return (
    <div className="courses-mini-card">
			<div >
				<Image
					src={imageUrl.default ? imageUrl.default : imageUrl}
					alt={title}
					style={{
						display: "block",
						marginLeft: "auto",
						marginRight: "auto",
						width: "50%",
						marginBottom: "15px",
					}}
					className="img-fluid"
				/>
			</div>
      <h3 className="courses-mini-card--name">
        {name}
        </h3>
			<h5 className="courses-mini-card--title">
				{title}
			</h5>
		</div>
  );
};

export default CoursesMiniCard;
