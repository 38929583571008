import React from "react";
import { Card, Image } from "react-bootstrap";

import "./AdvisorCard.styles.scss";

const AdvisorCard = ({ name, designation, location, imageUrl, content }) => {
  return (
    <Card className="w-100 shadow-sm advisor-card">
      <Card.Body className="text-center">
        {false && (
          <Image
            src={
              imageUrl
                ? imageUrl.default
                  ? imageUrl.default
                  : imageUrl
                : require("../../assets/default-user.png").default
            }
            className="img-fluid  advisor-card--image"
          />
        )}
        <Card.Title as="h5" className="text-brand-1">
          {name}
        </Card.Title>
        <div className="mb-4">
          {designation && (
            <Card.Text className="font-weight-bold mb-0">
              {designation}
            </Card.Text>
          )}
          {location && (
            <Card.Text className="font-weight-bold">{location}</Card.Text>
          )}
        </div>

        {content ? (
          <div className="advisor-card--content">
            <p>{content}</p>
          </div>
        ) : null}
      </Card.Body>
    </Card>
  );
};

export default AdvisorCard;
