import React from "react";
import PageHeader from "../../../components/NIISPageHeader/PageHeader";
import PageSection from "../../../components/NIISPageSection/PageSection";
import PageWrapper from "../../../components/NIISPageWrapper/PageWrapper";
import CustomIcon from "../../../components/NIISCustomIcon/CustomIcon";
import { AiFillFilePdf } from "react-icons/ai";
import PageSectionHeader from "../../../components/NIISPageSectionHeader/PageSectionHeader";
import Blockquote from "../../../components/NIISBlockquote/Blockquote";

const MasterinSocialWorkPage = () => {
  return (
    <PageWrapper>
      <PageHeader title="Master in Social Work (MSW)" imageUrl={require('../../../assets/information-science-academic/page/MSW.jpeg')}/>
      <PageSection>
        <p className="mb-4">
        The perks of earning your MSW degree include
being able to offer better support and services to clients, job security, and the
opportunity for personal fulfillment.
        </p>

        <p className="text-center">
          <a href="/#" className="download-link">
            <CustomIcon currentIcon={AiFillFilePdf} />
            <span className="ml-2">DOWNLOAD COMPLETE SYLLABUS</span>
          </a>
        </p>
      </PageSection>
      <PageSection bgClass="light">
        <PageSectionHeader title="Course Structure" leftStyle="bottom" />
        <div className="ml-2">
          <Blockquote>
            <p>
              <span className="font-weight-bold">Name of the Course : </span>
              MSW
            </p>
            <p>
              <span className="font-weight-bold">Duration : </span>2 yrs (4
              Semesters)
            </p>
            <p>
              <span className="font-weight-bold">Intake Capacity : </span>60
            </p>
            <p>
              <span className="font-weight-bold">Tuition Fees per Annum :</span>
              As per Govt. Directives
            </p>
          </Blockquote>
        </div>
      </PageSection>
      <PageSection>
        <PageSectionHeader title="Eligibility" rightStyle="bottom" />
        <p>
          A Bachelor's Degree (10 +2 +3) passed in Arts / Science / Commerce
          from any university candidates.
        </p>
      </PageSection>
      <PageSection bgClass="light">
        <PageSectionHeader title="Selection Procedure" leftStyle="bottom" />
        <p>
          Through NIIS Aptitude Test (NAT) along with merit of previous academic
          record followed by personal interview. Admission of Special category
          seats shall be made basing on a merit list among the applicants to be
          followed by Personal Aptitude Test (PAT).
        </p>
      </PageSection>
      <PageSection>
        <PageSectionHeader title="Career Opportunity" rightStyle="bottom" />
        <p>
          A candidate after completion of this two years full time M.A in Social
          Work course may avail many opportunities for getting employment in
          State/Central Governments Departments, International NGOs like, CARE,
          OXFAM, ACTION AID, WATER AID, UN Organizations etc. IFRC
          (International Federation of Red Cross), National and State Red Cross
          Society, Other National Red Cross societies such as AMCROSS, German,
          Spanish etc. Besides the above, one professional in Social Work can
          start his own venture in establishing an NGO having different
          objectives. Serve in Multinationals, Corporate Sectors, Industrial
          sector, Teaching professions etc. There is huge demand of Social Work
          qualified and trained professionals at present.
        </p>
      </PageSection>
    </PageWrapper>
  );
};

export default MasterinSocialWorkPage;
