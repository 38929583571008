import React from "react";
import PageHeader from "../../../components/NIISPageHeader/PageHeader";
import PageSection from "../../../components/NIISPageSection/PageSection";
import PageWrapper from "../../../components/NIISPageWrapper/PageWrapper";
import CustomIcon from "../../../components/NIISCustomIcon/CustomIcon";
import { AiFillFilePdf } from "react-icons/ai";
import PageSectionHeader from "../../../components/NIISPageSectionHeader/PageSectionHeader";
import Blockquote from "../../../components/NIISBlockquote/Blockquote";

const BachelorofBusinessAdministrationPage = () => {
  return (
    <PageWrapper>
      <PageHeader title="Bachelor of Business Administration (BBA)" imageUrl={require('../../../assets/information-science-academic/page/BBA.jpg')}/>
      <PageSection>
        <p  className="mb-4">
        This course at NIIS is designed to
provide basic understanding about Management education and to train the
students in communication skills effectively which inculcate entrepreneurship
skills.
        </p>

       

        <p className="text-center">
          <a href="/#" className="download-link">
            <CustomIcon currentIcon={AiFillFilePdf} />
            <span className="ml-2">DOWNLOAD COMPLETE SYLLABUS</span>
          </a>
        </p>
      </PageSection>
      <PageSection bgClass="light">
        <PageSectionHeader title="Course Structure" leftStyle="bottom" />
        <div className="ml-2">
          <Blockquote>
            <p>
              <span className="font-weight-bold">Semester : </span>
              BBA
            </p>
            <p>
              <span className="font-weight-bold">Duration : </span>3 yrs(6
              Semesters)
            </p>
            <p>
              <span className="font-weight-bold">Intake Capacity : </span>60
            </p>
            <p>
              <span className="font-weight-bold">Tuition Fees per Annum :</span>
              As per Govt. Directives
            </p>
          </Blockquote>
        </div>
      </PageSection>
      <PageSection>
        <PageSectionHeader title="Eligibility" rightStyle="bottom" />
        <p>
          +2 Passed Student in any Stream (Science, Commerce, Arts with English
          as passing with English as passing subject and minimum 45% marks at
          12th class.
        </p>
      </PageSection>
      <PageSection bgClass="light">
        <PageSectionHeader title="Selection Procedure" leftStyle="bottom" />
        <p>
          Through NIIS Aptitude Test (NAT) along with merit of previous academic
          record followed by personal interview. Admission of Special category
          seats shall be made basing on a merit list among the applicants to be
          followed by Personal Aptitude Test (PAT).
        </p>
      </PageSection>
      <PageSection>
        <PageSectionHeader title="Career Opportunity" rightStyle="bottom" />
        <p>
          A candidate after successful completion of this three years full time
          Bachelor in Business Administration course a student may avail
          employment opportunities in Government, Public, Private
          industries/business houses/corporations, Banks/Railways, Computer
          organizations, Marketing/retailing jobs. Besides one can start own
          business organization as an independent Entrepreneur and can pursue
          MBA for higher studies. It is also a fact that the students completing
          BBA degree from our institute are all well placed in different jobs or
          perusing higher studies in renowned national level institutions.
        </p>
      </PageSection>
    </PageWrapper>
  );
};

export default BachelorofBusinessAdministrationPage;
