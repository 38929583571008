import React from "react";
import PageHeader from "../../../components/NIISPageHeader/PageHeader";
import PageSection from "../../../components/NIISPageSection/PageSection";
import PageWrapper from "../../../components/NIISPageWrapper/PageWrapper";
import CustomIcon from "../../../components/NIISCustomIcon/CustomIcon";
import { AiFillFilePdf } from "react-icons/ai";
import PageSectionHeader from "../../../components/NIISPageSectionHeader/PageSectionHeader";
import Blockquote from "../../../components/NIISBlockquote/Blockquote";

const BachelorofComputerApplicationPage = () => {
  return (
    <PageWrapper>
      <PageHeader title="Bachelor of Computer Application (BCA)" imageUrl={require('../../../assets/information-science-academic/page/BCA.jpg')}/>
      <PageSection>


        <p className="mb-4">
        This course provides a lot of
opportunities to the students who are interested in the computer field and want
to work in the IT sector as programmers or software developers.
        </p>

        <p className="text-center">
          <a href="/#" className="download-link">
            <CustomIcon currentIcon={AiFillFilePdf} />
            <span className="ml-2">DOWNLOAD COMPLETE SYLLABUS</span>
          </a>
        </p>
      </PageSection>
      <PageSection bgClass="light">
        <PageSectionHeader title="Course Structure" leftStyle="bottom" />
        <div className="ml-2">
          <Blockquote>
            <p>
              <span className="font-weight-bold">Semester : </span>
              BCA
            </p>
            <p>
              <span className="font-weight-bold">Duration : </span>3 yrs(6
              Semesters)
            </p>
            <p>
              <span className="font-weight-bold">Intake Capacity : </span>60
            </p>
            <p>
              <span className="font-weight-bold">Tuition Fees per Annum :</span>
              As per Govt. Directives
            </p>
          </Blockquote>
        </div>
      </PageSection>
      <PageSection>
        <PageSectionHeader title="Eligibility" rightStyle="bottom" />
        <p>
          +2 Passed Student in any Stream (Science, Commerce, Arts with
          Mathematics/Business Math/Statistics/Diploma Eng.
        </p>
      </PageSection>
      <PageSection bgClass="light">
        <PageSectionHeader title="Selection Procedure" leftStyle="bottom" />
        <p>
          Through NIIS Aptitude Test (NAT) along with merit of previous academic
          record followed by personal interview. Admission of Special category
          seats shall be made basing on a merit list among the applicants to be
          followed by Personal Aptitude Test (PAT).
        </p>
      </PageSection>
      <PageSection>
        <PageSectionHeader title="Career Opportunity" rightStyle="bottom" />
        <p>
          A successful BCA student may get suitable job opportunities in
          Government Departments, PSUs, Banks, Private industries, software
          development farms, Call Centers, BPOs, Teaching professions and can
          establish himself as independent Enterpreneur. One can also work as
          corporate consultant, web designer in IT farms, and may also persue
          MCA course for a brighter career in IT industry with handsome
          remuneration.
        </p>
      </PageSection>
    </PageWrapper>
  );
};

export default BachelorofComputerApplicationPage;
