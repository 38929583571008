import React from "react";
import { Image } from "react-bootstrap";

const WeekMiniCard = ({ title, imageUrl }) => {
	return (
		<div className="bg-white d-flex flex-column h-100  ">
			<div>
				<Image
					src={imageUrl.default ? imageUrl.default : imageUrl}
					alt={title}
					style={{
						display: "block",
						marginLeft: "auto",
						marginRight: "auto",
						width: "50%",
						marginBottom: "15px",
					}}
					className="img-fluid"
				/>
			</div>
			<h5 className="text-heading-color font-paragraph-font text-center h-100 font-weight-bold mb-5">
				{title}
			</h5>
		</div>
	);
};

export default WeekMiniCard;
