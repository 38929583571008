import React from "react";
import { Container } from "react-bootstrap";
import classNames from "classnames";

const PageSection = ({ bgClass, children, type, className, id }) => {
  const sectionClasses = classNames(
    { "py-5 px-md-5 px-4": type === "lg" },
    { "p-md-5 p-4": !type },
    { "p-md-4 p-3": type === "sm" },
    {
      [`bg-${bgClass}`]: bgClass,
    },
    {
      [className]: className,
    }
  );

  return (
    <div className={sectionClasses} id={id}>
      <Container>{children}</Container>
    </div>
  );
};

export default PageSection;
