export const pgAdmissionPageData = [
	{
		title: "Specialized Programs",
		imageUrl: require("../assets/admission/diverse.png"),
		description: "Explore a spectrum of postgraduate courses tailored to meet the demands of contemporary industries. From specialized management tracks to advanced science disciplines, we offer a diverse range of programs.",
	},
	{
		title: "Renowned Faculty",
		imageUrl: require("../assets/admission/faculty.png"),
		description: "Engage with our accomplished faculty, experts in their respective fields. Benefit from their mentorship as you delve deeper into your subject, conduct research, and build expertise.",
		
	},
	{
		title: "Cutting-edge Facilities",
		imageUrl: require("../assets/admission/facilities.png"),
		description: "Access state-of-the-art laboratories, research centers, and libraries equipped with the latest resources. Our campus provides an ideal environment for advanced learning and scholarly exploration.        ",
	},
	{
		title: "Research Opportunities",
		imageUrl: require("../assets/admission/research.png"),
		description: "Contribute to the advancement of knowledge through our robust research programs. Collaborate with faculty on innovative projects and explore your research interests under their guidance.        ",
	},
	{
		title: "Industry Integration",
		imageUrl: require("../assets/admission/industry.png"),
		description: "Gain practical insights through industry collaborations, internships, and projects. Our strong ties with leading organizations ensure that your education is aligned with real-world challenges.",
	},
	

];