import React ,{useState} from "react";
import { Image } from "react-bootstrap";
import "./ClubCard.style.scss";



const ClubCard = ({ title, imageUrl,description, onClick }) => {

	const [showMore, setShowMore] = useState(false);
	const descriptionData = description;

	return (
		<div className="about-us-mini-card2" onClick={onClick}>
			<Image
				src={imageUrl.default ? imageUrl.default : imageUrl}
				alt={title}
				style={{
					width: " 40%",
					display: "block",
					marginLeft: "auto",
					marginRight: "auto",
				}}
				className="about-us-mini-card2--image"
			/>
			<br />
			<div className="about-us-mini-card2--title">
				<h6 style={{ fontSize: "20px" }}>{title}</h6>
			</div>
			<div className="about-us-mini-card2--description">
				{/* <p style={{ fontSize: "15px",fontWeight:400 }}>{description}</p> */}
				<p style={{ fontSize: "15px",fontWeight:400 }}>
					{showMore ? description.toString() : `${description.substring(0, 250)}`}
					<button className="btn" onClick={() => setShowMore(!showMore)}>{showMore ? "Show less" : "Show more"}</button>
				</p>
			</div>
			
		</div>
	);
};

export default ClubCard;
