import React, { useState, useEffect } from "react";
import bsBreakpoints from "bs-breakpoints";
import classNames from "classnames";
import PageSection from "../NIISPageSection/PageSection";
import ResponsivePageHeader from "../NIISResponsivePageHeader/ResponsivePageHeader";
import { Col, Row } from "react-bootstrap";
import CoursesMiniCard from "../NIISCoursesMiniCard/CoursesMiniCard";
import { postgraduateCourseData,undergraduateCourseData } from "../../static-data/nisscourses.data";

const CoursesResponsive = () => {
  const [currentBreakPoint, setCurrentBreakPoint] = useState("");

  useEffect(() => {
    bsBreakpoints.init();
    setCurrentBreakPoint(bsBreakpoints.getCurrentBreakpoint());

    const handleBreakPoint = (event) => {
      setCurrentBreakPoint(event.detail);
    };

    window.addEventListener("new.bs.breakpoint", handleBreakPoint);

    return () => {
      window.removeEventListener("new.bs.breakpoint", handleBreakPoint);
    };
  }, []);

  const rowContainerClasses = classNames({
    "w-80 mx-auto": !(
      currentBreakPoint === "xSmall" || currentBreakPoint === "small"
    ),
  });

  return (
    <PageSection type="lg" bgClass="page-background-1">
      <ResponsivePageHeader title="Explore Our Courses" />

      <h2 style={{color:"#002855", fontWeight:"600", textAlign: "center"}}>
        Postgraduate Courses
      </h2>
      <br />
      <div className={rowContainerClasses}>
        <Row className="justify-content-center gx-1">
          {postgraduateCourseData.map((data, index) => (
            <Col
              md="3"
              className="d-flex align-items-stretch mb-4 mb-md-0"
              key={"course-responsive" + index}
            >
              <CoursesMiniCard {...data} />
            </Col>
          ))}
        </Row>  
      </div>

      <br />


      <h2 style={{color:"#002855", fontWeight:"600", textAlign: "center"}}>
        Undergraduate Courses
      </h2>
      <br />
      <div className={rowContainerClasses}>
        <Row className="justify-content-center gx-1">
          {undergraduateCourseData.map((data, index) => (
            <Col
              md="3"
              className="d-flex align-items-stretch mb-4 mb-md-0"
              key={"course-responsive" + index}
            >
              <CoursesMiniCard {...data} />
            </Col>
          ))}
        </Row>  
      </div>

    </PageSection>
  );
};

export default CoursesResponsive;
