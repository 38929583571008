export const newsData = [
	{
		date: "29th January , 2024",
		link: "/",
		title: "Seminar in the Department of English",
		description: "A seminar was held in the Department of English on the theme 'Discovering Distinct Shades of Partition' . The seminar was presided over by Miss. Debashree Debasmita Keshari; HOD English Department. Dr. Dushmant ku Pattanaik; Dy.Director, Dr. Ajit Narayan Mohanty; Dean of Humanities, Prof. Purnima Mitra, and Senior faculty members were present to encourage the students and gave valuable insights to their work and presentation.",
		imageUrl: require("../assets/news/55.jpg"),
	},
	{
		date: "27th January 2024",
		link: "/",
		title: "Conference on Sustainable Growth and Development",
		description: "International Conference on Sustainable Growth and Development through Emerging Technology & Innovations (ICSGD’ 2024) Research Education Solutions organised a virtual conference for discussions relating to agriculture technology & allied sciences. ",
		imageUrl: require("../assets/news/54.jpg"),
	},
	{
		date: "15th September 2023",
		link: "/",
		title: "Odisha Tech Conclave",
		description: "On 15th September 2023, it was a privilege for our students & faculty colleagues to participate in ODISHA TECHNOLOGICAL FORUM 2023 at Jaydev Bhawan,organised by EVER GREEN FORUM.",
		imageUrl: require("../assets/news/53.jpg"),
	},
	{
		date: "11th September 2023",
		link: "/",
		title: "National Seminar Governer",
		description: "National level seminar was held at Jayadev bhawan, Bhubaneswar on 11th September on the topic : contribution of Vino bhave towards Bhoodan movement on his 129th Birth anniversary organised by Vinoba Bave seva pratisthan  . His excellency Governor of Chattisgarh Sri Biswa Bhusan Harichandan graced the occasion",
		imageUrl: require("../assets/news/52.jpg"),
	},
	{
		date: "3rd October 2023",
		link: "/",
		title: "Participation of NSS Volunteer in Meri Maati Mera Desh Programme in the Campus of Utkal University Campus.",
		description: "NSS volunteers actively participated in the Meri Maati Mera Desh program held at the Utkal University campus, contributing to environmental conservation and community engagement, fostering a sense of responsibility towards the nation and its land.",
		imageUrl: require("../assets/news/51.jpeg"),
	},
	{
		date: "3rd October 2023",
		link: "/",
		title: "Awareness Programme on Virtual Police Station and Online FIR procedure in collaboration with CSNR",
		description: "The Awareness Programme on Virtual Police Station and Online FIR Procedure in collaboration with CSNR educated participants on the convenience and efficiency of virtual police services, demonstrating the online process for filing FIRs, making legal assistance more accessible.",
		imageUrl: require("../assets/news/50.jpeg"),
	},
	{
		date: "2nd October 2023",
		link: "/",
		title: "Clean India Mission Drive by NSS, NIBA",
		description: "The NSS (National Service Scheme) at NIBA (Name of Institution) organized a successful Clean India Mission Drive, where dedicated volunteers actively contributed to the nation's cleanliness initiative, promoting community well-being and environmental awareness.",
		imageUrl: require("../assets/news/49.jpeg"),
	},
	{
		date: "23rd September 2023",
		link: "/",
		title: "MBA students attend workshop",
		description: "MBA students attended an insightful workshop, gaining practical knowledge and skills relevant to their academic and professional growth.",
		imageUrl: require("../assets/news/48.jpeg"),
	},
	{
		date: "19th September 2023",
		link: "/",
		title: "Ganesh Puja Celebration",
		description: "The Ganesh Puja Celebration was a vibrant and spiritual event filled with devotional prayers, cultural performances, and the immersion of Lord Ganesha's idol, symbolizing the renewal of life and the removal of obstacles.",
		imageUrl: require("../assets/news/47.jpeg"),
	},
	{
		date: "16th September 2023",
		link: "/",
		title: "Induction Programme for MBA & MCA",
		description: "The Induction Program for MBA & MCA provided new students with a comprehensive orientation, offering an overview of the programs, campus resources, and expectations to ensure a smooth transition into their academic journey.",
		imageUrl: require("../assets/news/46.jpg"),
	},
	{
		date: "15th September 2023",
		link: "/",
		title: "Best Management Institute Award by Ever Green Forum",
		description: "The Best Management Institute Award was conferred upon our institution by the Ever Green Forum, recognizing our excellence in management education and our significant contributions to producing skilled and successful business professionals.",
		imageUrl: require("../assets/news/45.jpeg"),
	},
	{
		date: "14th September 2023",
		link: "/",
		title: "UG & PG Student Participate in Odisha Start-up Programme",
		description: "UG and PG students actively participated in the Odisha Start-up Program, gaining valuable entrepreneurship insights, mentorship, and resources to kickstart their innovative business ventures.",
		imageUrl: require("../assets/news/44.jpeg"),
	},
	// {
	// 	date: "14th September 2023",
	// 	link: "/",
	// 	title: "Orientation programme for PG Professional students",
	// 	description: "The Orientation Program for PG Professional Students introduced postgraduate students to their academic journey, offering insights into the curriculum, resources, and expectations, helping them embark on a successful educational experience.",
	// 	imageUrl: require("../assets/news/43.jpeg"),
	// },
	// {
	// 	date: "5th September 2023",
	// 	link: "/",
	// 	title: "Best Teacher Award to Prof. Indrajit Das by Sambad Newspaper.",
	// 	description: "Prof. Indrajit Das was honored with the Best Teacher Award by Sambad Newspaper, recognizing his outstanding contributions to education and his significant impact on students' lives.",
	// 	imageUrl: require("../assets/news/42.jpeg"),
	// },
	{
		date: "5th September 2023",
		link: "/",
		title: "Teachers Day Celebration",
		description: "The Teachers Day Celebration was marked by heartfelt tributes, student performances, and the presentation of awards, showing appreciation for the dedicated educators who shape future generations.",
		imageUrl: require("../assets/news/41.jpeg"),
	},
	{
		date: "5th September 2023",
		link: "/",
		title: "Pre-placement Training for PG Students",
		description: "Pre-placement Training for PG Students prepared postgraduate students with essential job-seeking skills, interview techniques, and resume building strategies to excel in their upcoming career placement endeavors.",
		imageUrl: require("../assets/news/40.jpeg"),
	},
	{
		date: "4th September 2023",
		link: "/",
		title: "Placement Training for PG students",
		description: "The Placement Training for PG Students focused on honing interview skills, resume building, and job search strategies, equipping postgraduate students with the tools needed for successful career placements.",
		imageUrl: require("../assets/news/39.jpeg"),
	},
	{
		date: "28th August 2023",
		link: "/",
		title: "Workshop on web application for MCA students",
		description: "The Workshop on Web Application for MCA Students provided in-depth training on web development, enhancing the skills of MCA students in designing and building web applications.",
		imageUrl: require("../assets/news/38.jpeg"),
	},
	{
		date: "23rd August 2023",
		link: "/",
		title: "Live Streaming of Chandrayan-3",
		description: "The live streaming of Chandrayan-3's mission showcased the thrilling moments of India's lunar exploration, including rocket launches, mission updates, and the historic moon landing, capturing the world's attention.",
		imageUrl: require("../assets/news/37.jpg"),
	},
	{
		date: "21st August 2023",
		link: "/",
		title: "World Entrepreneur Day Celebration",
		description: "The World Entrepreneur Day Celebration featured inspiring success stories, expert panels, and networking opportunities, empowering aspiring entrepreneurs and celebrating the spirit of innovation and business acumen.",
		imageUrl: require("../assets/news/36.jpeg"),
	},
	{
		date: "20th August 2023",
		link: "/",
		title: "Award Ceremony",
		description: "Hon’ble Chairman of NIIS Group of Institutions felicitated with Kalinga Gourav Samman Award by His Excellency Governor of Chhatisgarh",
		imageUrl: require("../assets/news/35.png"),
	},
	{
		date: "15th August 2023",
		link: "/",
		title: "Independence day celebration",
		description: "The Independence Day Celebration was marked with a grand parade, inspiring speeches, and a spectacular performances, bringing the community together to honor the nation's freedom.",
		imageUrl: require("../assets/news/34.jpeg"),
	},
	{
		date: "4th August 2023",
		link: "/",
		title: "Workshop on youth development.",
		description: "The Youth Development Workshop offers young individuals valuable insights and skills for personal growth and career readiness through interactive sessions and expert guidance.",
		imageUrl: require("../assets/news/33.jpeg"),
	},
	{
		date: "29th July 2023",
		link: "/",
		title: "International Conference on Innovation and Global Issues in IT, Management, Social Science & Agriculture",
		description:
			"A virtual conference was conducted on 29th July to discuss the trends in STEM, humanities & agriculture. The diversity of topics helped students grasp a knowledge of the events happening across the globe.",
		imageUrl: require("../assets/news/32.jpeg"),
	},
	{
		date: "23rd May 2023",
		link: "/",
		title: "Free Medical Check-Up Wellness  With Aditya Birla Group",
		description:
			"A health check-up camp was conducted I association with Aditya Birla to create awareness regarding proper diet, exercise and weight control for the faculty and staff members.",
		imageUrl: require("../assets/news/31.png"),
	},
	{
		date: "13th May 2023",
		link: "/",
		title: "Workshop On Life Skills",
		description:
			"The workshop was designed to nurture the life skills, professional etiquettes and customary business demeanors among the students of the institute.",
		imageUrl: require("../assets/news/30.png"),
	},
	{
		date: "25th March 2023",
		link: "/",
		title: "Boot Camp On Drone Technology",
		description:
			"Student from the department of MCA participated a workshop on Aerial Drone Technology and Application. ",
		imageUrl: require("../assets/news/29.png"),
	},
	{
		date: "20th March 2023",
		link: "/",
		title: "Self Defense For Girls By Taekwond Method",
		description:
			"A workshop on Self Defense was conducted to help he Girl student to be more aware of their surroundings and be prepared for the  unexpected at any point of time.  ",
		imageUrl: require("../assets/news/28.png"),
	},
	{
		date: "9th March 2023",
		link: "/",
		title: "Panchayatiraj Divas",
		description:
			"A seminar on Panchayat Raj Divas was conducted at NIBA Seminar Hall for MBA & MCA students with the presence of various dignitaries to enhance the knowledge of Panchayat Raj System in India. ",
		imageUrl: require("../assets/news/26.png"),
	},
	{
		date: "4th March 2023",
		link: "/",
		title: "Mou “Nestle”",
		description:
			"A memorandum of understanding was signed with NESTLE Company to provide service in our college campus. ",
		imageUrl: require("../assets/news/27.png"),
	},
	{
		date: "11th February 2023",
		link: "/",
		title: "Sensitiation Workshop On Waste Management",
		description:
			"A seminar on waste management was organized in association with Shree Ganesh  Recycling Pvt. Ltd., Bhubaneswar to find ways in which enormous quantity of solid waste  currently disposed off. ",
		imageUrl: require("../assets/news/25.png"),
	},
	{
		date: "3rd February 2023",
		link: "/",
		title: "Seminar On Union Budget 2023",
		description:
			"The seminar on Union Budget 2023-24 was organized by School of Management and School of Commerce. Professors from Department of Economics, Department of Commerce and Department of Management shared their individual views on the current budget.",
		imageUrl: require("../assets/news/23.png"),
	},
	{
		date: "24th January 2023",
		link: "/",
		title: "Plantation Programme",
		description:
			"Plantation Day was celebrated by NSS Wing of NIBA with CAPF Retired Association to create awareness in protecting the environment through personal involvement. ",
		imageUrl: require("../assets/news/21.png"),
	},
	{
		date: "18th January 2023",
		link: "/",
		title: "Intra College Volly Ball Tournament",
		description:
			"An intra college volley ball competition was conducted between different schools of  NIIS Group of Institutions. ",
		imageUrl: require("../assets/news/22.png"),
	},
	{
		date: "13th January 2023",
		link: "/",
		title: "Dance And Song Competition On Part Of Youth Day Celebration",
		description:
			"National Youth Day was celebrated in cooperation with Nehru Yuva Kendra, being the birth day of Swami Vivekananda. ",
		imageUrl: require("../assets/news/20.png"),
	},
	{
		date: "12th January 2023",
		link: "/",
		title: "MOU With Centurian University",
		description:
			"The MoU for the incubation support has been signed between  NIBA and Centurion University.",
		imageUrl: require("../assets/news/19.png"),
	},
	{
		date: "10th January 2023",
		link: "/",
		title: "Hockey Worlcup Promotional Campaign",
		description:
			" A seminar was organized to promote the Indian Hockey Team for up-coming World Cup – 2023. Schedule to begin from January 13, 2023 at Bhubaneswar. All the faculty members, students and college management were participated in this campaign with outside dignitaries. ",
		imageUrl: require("../assets/news/18.png"),
	},
	{
		date: "4th January 2023",
		link: "/",
		title: "Staff Cricket Tournament",
		description:
			" An inter college cricket tournament was organized at NIIS Institute of Engineering & Technology (NIET). In this tournament, 4 teams had participated. ",
		imageUrl: require("../assets/news/17.png"),
	},
	{
		date: "21st December 2022",
		link: "/",
		title: "Seminar On Stock Market's & Its Career",
		description:
			" A seminar on Stock Market was organized. Mr. Om Mahapatra from  Business Standard enlightened the student about the mechanism, investment procedure and job opportunities of the stock market.",
		imageUrl: require("../assets/news/16.png"),
	},
	{
		date: "16th December  2022",
		link: "/",
		title: "Industrial Visit to CTTC for MCA 1st Year Students ",
		description:
			"NIBA organised an industrial visit to CTTC for MCA 1st year students on 16th of December. The students witnessed the cutting edge technologies for manufacturing and the new tools that are going to help in Industry 4.0 or the 4th Industrial Revolution. ",
		imageUrl: require("../assets/news/15.jpeg"),
	},
	{
		date: "14th December  2022",
		link: "/",
		title: "IQAC, NIBA celebrated National Energy Conservation Day ",
		description:
			"IQAC Cell celebrated National Energy Conservation Day at NIBA on 14th of December for MBA & MCA students. Energy is going to play a vital role in the future development of the nation and it is important that our future managers & technocrats understand the situation and plan accordingly. ",
		imageUrl: require("../assets/news/14.jpeg"),
	},
	{
		date: "10th & 11th December 2022 ",
		link: "/",
		title:
			"NIBA, in association with Ascent Conclave 2022 organised a 2-day National MUN. ",
		description:
			"Diplomacy is vital to the interests and development of every nation. Our institute organised a 2-day MUN to develop confidence & leadership skills within our students. ",
		imageUrl: require("../assets/news/9.jpeg"),
	},
	{
		date: "6th December  2022",
		link: "/",
		title:
			"Poster Making Competition organised by NIIS Group of Institutions in collaboration with Switchon Foundation.",
		description: "",
		imageUrl: require("../assets/news/7.jpeg"),
	},
	{
		date: "12th June 2022",
		link: "/",
		title:
			"IQAC, NIBA conducted a Mentor Development Program in association withBharatiya Yuva Shakti Trust.",
		description:
			"Our aim with the Mentor Development Program was to encourage people to connect with others on a personal level and build strong, trusting relations.",
		imageUrl: require("../assets/news/4.jpeg"),
	},

	{
		date: "7th June 2022",
		link: "/",
		title:
			"IQAC NIBA initiated a 5day long Faculty Development Program for our Computer Science Department",
		description:
			" We had an eminent panel of resource persons, Dr Prafulla Kumar Behera of Utkal University, Bhubaneswar; Dr. Alok Ranjan Tripathy, Ravenshaw University, Cuttack; Dr Manash Ranjan Kabat of VSSUT Burla; Dr. D.C. Rao of VSSUT Burla and Dr. Pradyut Biswal, IIIT Bhubaneswar.",
		imageUrl: require("../assets/news/3.jpeg"),
	},
	{
		date: "14th May 2022",
		link: "/",
		title: "National Seminar: “Industry 4.0: The Evolving Business Landscape.”",
		description:
			" We were privileged to host the Vice-Chancellor of Fakir Mohan University, Balasore,  Prof.(Dr) Santosh Kumar Tripathy; our keynote speakers Dr. Ramakrushna Padhy, Associate Professor, IIM Sambalpur; Dr W.S. William, Professor XIMB Bhubaneswar; Dr Suman Bhattacharya, Director CAAS & Head Next Gen Learning, KIIT University; Dr Debabrata Dash, VP HR Ops. MSPL.",
		imageUrl: require("../assets/news/2.jpg"),
	},
	{
		date: "16th April 2022",
		link: "/",
		title:
			"National Seminar: “Revisiting the landscape of IoT: A technology for common man”",
		description:
			"TWe were privileged to host the Vice-Chancellor of BPUT,  Prof.(Dr) Chitaranjan Tripathy, Prof.(Dr) Amiya Kumar Rath, Advisor, NAAC Bangalore and our keynote speaker Dr. Suraj Sharma, IIIT Bhubaneswar.",
		imageUrl: require("../assets/news/1.jpg"),
	},
	
];

// {
// 	date: "11th October 2023",
// 	link: "/",
// 	title: "PRE Silver Jubli function “Barnali",
// 	description: "The "PRE Silver Jubilee Function 'Barnali'" was a prelude to the upcoming Silver Jubilee celebration, setting the stage for a grand event filled with anticipation, excitement, and a sneak peek into what's to come.",
// 	imageUrl: require("../assets/news/52.jpeg"),
// },
// {
// 	date: "3rd October 2023",
// 	link: "/",
// 	title: "Participation of NSS Volunteer in Meri Maati Mera Desh Programme in the Campus of Utkal University Campus.",
// 	description: "NSS volunteers actively participated in the "Meri Maati Mera Desh" program held at the Utkal University campus, contributing to environmental conservation and community engagement, fostering a sense of responsibility towards the nation and its land.",
// 	imageUrl: require("../assets/news/51.jpeg"),
// },
// {
// 	date: "3rd October 2023",
// 	link: "/",
// 	title: "Awareness Programme on Virtual Police Station and Online FIR procedure in collaboration with CSNR",
// 	description: "The "Awareness Programme on Virtual Police Station and Online FIR Procedure" in collaboration with CSNR educated participants on the convenience and efficiency of virtual police services, demonstrating the online process for filing FIRs, making legal assistance more accessible.",
// 	imageUrl: require("../assets/news/50.jpeg"),
// },
// {
// 	date: "2nd October 2023",
// 	link: "/",
// 	title: "Clean India Mission Drive by NSS, NIBA",
// 	description: "The NSS (National Service Scheme) at NIBA (Name of Institution) organized a successful "Clean India Mission Drive," where dedicated volunteers actively contributed to the nation's cleanliness initiative, promoting community well-being and environmental awareness.",
// 	imageUrl: require("../assets/news/49.jpeg"),
// },
// {
// 	date: "23rd September 2023",
// 	link: "/",
// 	title: "MBA students attend workshop",
// 	description: "MBA students attended an insightful workshop, gaining practical knowledge and skills relevant to their academic and professional growth.",
// 	imageUrl: require("../assets/news/48.jpeg"),
// },
// {
// 	date: "19th September 2023",
// 	link: "/",
// 	title: "Ganesh Puja Celebration",
// 	description: "The "Ganesh Puja Celebration" was a vibrant and spiritual event filled with devotional prayers, cultural performances, and the immersion of Lord Ganesha's idol, symbolizing the renewal of life and the removal of obstacles.",
// 	imageUrl: require("../assets/news/47.jpeg"),
// },
// {
// 	date: "16th September 2023",
// 	link: "/",
// 	title: "Induction Programme for MBA & MCA",
// 	description: "The "Induction Program for MBA & MCA" provided new students with a comprehensive orientation, offering an overview of the programs, campus resources, and expectations to ensure a smooth transition into their academic journey.",
// 	imageUrl: require("../assets/news/46.jpg"),
// },
// {
// 	date: "15th September 2023",
// 	link: "/",
// 	title: "Best Management Institute Award by Ever Green Forum",
// 	description: "The "Best Management Institute Award" was conferred upon our institution by the Ever Green Forum, recognizing our excellence in management education and our significant contributions to producing skilled and successful business professionals.",
// 	imageUrl: require("../assets/news/45.jpeg"),
// },
// {
// 	date: "14th September 2023",
// 	link: "/",
// 	title: "UG & PG Student Participate in Odisha Start-up Programme",
// 	description: "UG and PG students actively participated in the Odisha Start-up Program, gaining valuable entrepreneurship insights, mentorship, and resources to kickstart their innovative business ventures.",
// 	imageUrl: require("../assets/news/44.jpeg"),
// },
// {
// 	date: "14th September 2023",
// 	link: "/",
// 	title: "Orientation programme for PG Professional students",
// 	description: "The "Orientation Program for PG Professional Students" introduced postgraduate students to their academic journey, offering insights into the curriculum, resources, and expectations, helping them embark on a successful educational experience.",
// 	imageUrl: require("../assets/news/43.jpeg"),
// },
// {
// 	date: "5th September 2023",
// 	link: "/",
// 	title: "Best Teacher Award to Prof. Indrajit Das by Sambad Newspaper.",
// 	description: "Prof. Indrajit Das was honored with the "Best Teacher Award" by Sambad Newspaper, recognizing his outstanding contributions to education and his significant impact on students' lives.",
// 	imageUrl: require("../assets/news/42.jpeg"),
// },
// {
// 	date: "5th September 2023",
// 	link: "/",
// 	title: "Teachers Day Celebration",
// 	description: "The "Teachers Day Celebration" was marked by heartfelt tributes, student performances, and the presentation of awards, showing appreciation for the dedicated educators who shape future generations.",
// 	imageUrl: require("../assets/news/41.jpeg"),
// },
// {
// 	date: "5th September 2023",
// 	link: "/",
// 	title: "Pre-placement Training for PG Students",
// 	description: ""Pre-placement Training for PG Students" prepared postgraduate students with essential job-seeking skills, interview techniques, and resume building strategies to excel in their upcoming career placement endeavors.",
// 	imageUrl: require("../assets/news/40.jpeg"),
// },
// {
// 	date: "4th September 2023",
// 	link: "/",
// 	title: "Placement Training for PG students",
// 	description: "The "Placement Training for PG Students" focused on honing interview skills, resume building, and job search strategies, equipping postgraduate students with the tools needed for successful career placements.",
// 	imageUrl: require("../assets/news/39.jpeg"),
// },
// {
// 	date: "28th August 2023",
// 	link: "/",
// 	title: "Workshop on web application for MCA students",
// 	description: "The "Workshop on Web Application for MCA Students" provided in-depth training on web development, enhancing the skills of MCA students in designing and building web applications.",
// 	imageUrl: require("../assets/news/38.jpeg"),
// },
// {
// 	date: "23rd August 2023",
// 	link: "/",
// 	title: "Live Streaming of Chandrayan-3",
// 	description: "The live streaming of Chandrayan-3's mission showcased the thrilling moments of India's lunar exploration, including rocket launches, mission updates, and the historic moon landing, capturing the world's attention.",
// 	imageUrl: require("../assets/news/37.jpg"),
// },
// {
// 	date: "21st August 2023",
// 	link: "/",
// 	title: "World Entrepreneur Day Celebration",
// 	description: "The "World Entrepreneur Day Celebration" featured inspiring success stories, expert panels, and networking opportunities, empowering aspiring entrepreneurs and celebrating the spirit of innovation and business acumen.",
// 	imageUrl: require("../assets/news/36.jpeg"),
// },
// {
// 	date: "20th August 2023",
// 	link: "/",
// 	title: "Award Ceremony",
// 	description: "Hon’ble Chairman of NIIS Group of Institutions felicitated with Kalinga Gourav Samman Award by His Excellency Governor of Chhatisgarh",
// 	imageUrl: require("../assets/news/35.png"),
// },
// {
// 	date: "15th August 2023",
// 	link: "/",
// 	title: "Independence day celebration",
// 	description: "The "Independence Day Celebration" was marked with a grand parade, inspiring speeches, and a spectacular performances, bringing the community together to honor the nation's freedom.",
// 	imageUrl: require("../assets/news/34.jpeg"),
// },
// {
// 	date: "4th August 2023",
// 	link: "/",
// 	title: "Workshop on youth development.",
// 	description: "The "Youth Development Workshop" offers young individuals valuable insights and skills for personal growth and career readiness through interactive sessions and expert guidance.",
// 	imageUrl: require("../assets/news/33.jpeg"),
// },