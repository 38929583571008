import React from "react";
import { Image, Card, Row, Col } from "react-bootstrap";

const NewsBitesCard = ({ title, link, imageSrc, date }) => {
  return (
    <Card className="border shadow-sm w-100 mb-4">
      <Card.Body>
        <Row>
          <Col xs="12" sm="4" className="text-center mb-2">
            <Image src={imageSrc} alt={title} className="img-fluid" />
          </Col>
          <Col xs="12" sm="8">
            <Row className="justify-content-between">
              <Col xs="6">{date}</Col>
              <Col xs="6" className="text-right">
                <a href={link} className="font-mono">
                  View/Download
                </a>
              </Col>
              <Col xs="12">
                <Card.Title as="h5" className="text-brand-1">{title}</Card.Title>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default NewsBitesCard;
