const informationScienceUrl = "/niis-information-science";
const juniorCollegeUrl = "/niis-junior-college";

export const niisDefaultFooterLinks = [
  {
    title: "Home",
    link: `/`,
  },
  {
    title: "Media Updates",
    link: `/media-updates`,
  },
  {
    title: "Preview 2017-2018",
    link: `/redirect-preview-2017-2018`,
  },
  {
    title: "Contact Us",
    link: `/contact-us`,
  },
];

export const informationScienceFooterLinks = [
  {
    title: "Home",
    link: `${informationScienceUrl}`,
  },
  {
    title: "UGC",
    link: `${informationScienceUrl}/redirect-ugc`,
  },
  {
    title: "Utkal University",
    link: `${informationScienceUrl}/redirect-utkal`,
  },
  {
    title: "DHE Odisha",
    link: `${informationScienceUrl}/redirect-dhe`,
  },
  {
    title: "Contact Us",
    link: `${informationScienceUrl}/contact-us`,
  },
];

export const juniorCollegeFooterLinks = [
  {
    title: "Home",
    link: `${juniorCollegeUrl}`,
  },
  {
    title: "Admission Procedure",
    link: `${juniorCollegeUrl}/admissions/admission-procedure`,
  },
  {
    title: "Application Form",
    link: `${juniorCollegeUrl}/admissions/redirect-download-application-form`,
  },
  {
    title: "Contact Us",
    link: `${juniorCollegeUrl}/contact-us`,
  },
];
