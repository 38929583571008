import React from "react";
import PageSection from "../NIISPageSection/PageSection";
import ResponsivePageHeader from "../NIISResponsivePageHeader/ResponsivePageHeader";
import { Col, Row } from "react-bootstrap";
import QuickLinksMiniCard from "../NIISQuickLinksMiniCard/QuickLinksMiniCard";
import { quickLinksData } from "../../static-data/quicklinks.data";

const QuickLinksResponsive = () => {

  return (
    <PageSection bgClass="background-color">
      <ResponsivePageHeader centered={true} title="Quick Links" />
      <Row>
        {quickLinksData.map((data, index) => (
          <Col
            md="3"
            className="d-flex align-items-stretch mb-4 mb-md-0"
            key={"quick-links-home" + index}
          >
            <QuickLinksMiniCard
              {...data}
            />
          </Col>
        ))}
      </Row>
    </PageSection>
  );
};

export default QuickLinksResponsive;
