import React from "react";
import { Switch, useRouteMatch, Route } from "react-router-dom";
import { juniorCollegeLinks, newLinks } from "../../static-data/niisnavbar.data";
import { juniorCollegeFooterLinks } from "../../static-data/niisfooter.data";


import FourOFour from "../../pages/FourOFour/FourOFourPage";

import CustomNavbar from "../../components/NIISCustomNavbar/CustomNavbar";
import CustomFooter from '../../components/NIISCustomFooter/CustomFooter';
import LogoBanner from "../../components/NIISLogoBanner/LogoBanner";

import ContactUsPage from "../../pages/ContactUsPage/ContactUsPage";
import PrincipalMessagePage from "../../pages/NIIS_Junior_College/PrincipalMessagePage/PrincipalMessagePage";
import CourseStructurePage from '../../pages/NIIS_Junior_College/CourseStructurePage/CourseStructurePage';
import SciencePage from '../../pages/NIIS_Junior_College/SciencePage/SciencePage';
import CommercePage from '../../pages/NIIS_Junior_College/CommercePage/CommercePage';
import AdmissionProcedurePage from '../../pages/NIIS_Junior_College/AdmissionProcedurePage/AdmissionProcedurePage';
import RulesAndRegulationsPage from '../../pages/NIIS_Junior_College/RulesAndRegulationsPage/RulesAndRegulationsPage';
import FacilitiesPage from "../../pages/NIIS_Junior_College/FacilitiesPage/FacilitiesPage";
import ExaminationPage from "../../pages/NIIS_Junior_College/ExaminationPage/ExaminationPage";
import ActivitiesPage from "../../pages/NIIS_Junior_College/ActivitiesPage/ActivitiesPage";
import AdmissionEnquiryPage from "../../pages/NIIS_Junior_College/AdmissionEnquiryPage/AdmissionEnquiryPage";
import JuniorCollegeHomePage from "../../pages/NIIS_Junior_College/JuniorCollegeHomePage/JuniorCollegeHomePage";
import AcademicAdvisorPage from '../../pages/AcademicAdvisorPage/AcademicAdvisorPage';

const JuniorCollegeRouterPage = () => {
  const { path } = useRouteMatch();


  return (
    
    <div>
      <LogoBanner name="NIIS HIGHER SECONDARY SCHOOL"/>
			<CustomNavbar linkList={juniorCollegeLinks}  newLinks={newLinks} collegeName="NIIS HIGHER SECONDARY SCHOOL"/>

      <Switch>
        <Route exact path={path} component={JuniorCollegeHomePage} />
        <Route path={`${path}/about/principal-message`} component={PrincipalMessagePage}/>
        <Route path={`${path}/about/academic-advisor`} component={AcademicAdvisorPage}/>
        <Route path={`${path}/academics/course-structure`} component={CourseStructurePage}/>
        <Route path={`${path}/academics/science`} component={SciencePage}/>
        <Route path={`${path}/academics/commerce`} component={CommercePage}/>
        <Route path={`${path}/examination`} component={ExaminationPage}/>
        <Route path={`${path}/admissions/admission-procedure`} component={AdmissionProcedurePage}/>
        <Route path={`${path}/admissions/rules-and-regulations`} component={RulesAndRegulationsPage}/>
        <Route path={`${path}/admissions/application-form`} component={AdmissionEnquiryPage}/>
        <Route path={`${path}/activities/`} component={ActivitiesPage}/>
        <Route path={`${path}/infrastructure`} component={FacilitiesPage}/>
        <Route path={`${path}/contact-us`} component={ContactUsPage}/>
        <Route path="*" component={FourOFour} />
      </Switch>

      <CustomFooter linkList={juniorCollegeFooterLinks} />
    </div>
  );
};

export default JuniorCollegeRouterPage;
