import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import {
	Navbar,
	Nav,
	Image,
	Row,
	Col,
	ButtonGroup,
	Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import NissLogo from "../../assets/logo/niis-logo.png";
import RegistarLogo from "../../assets/logo/registar-logo.png";


const LogoBanner = ({name}) => {
  return <div>
    <div className="logo-banner container-fluid">
                    <Row className="logo-banner-icons">
							<Col xs="4" >
								<Navbar.Brand as={Link} to="/" style={{paddingTop:"0.5rem",paddingBottom:"1rem"}}>
									<Image src={NissLogo} width={64} height={64} />
								</Navbar.Brand>
							</Col>
							
							<Col xs="4">
								<Nav className="mx-auto" style={{paddingLeft:"6rem",paddingTop:"0.5rem",paddingBottom:"1rem"}}>
									<Image src={RegistarLogo} height={64} />
								</Nav>
							</Col>
								
							<Col md="4" >
								<div style={{paddingLeft:"2rem",paddingTop:"0.5rem",paddingBottom:"0.5rem"}}>
									<h4 className="text-brand-1 font-weight-bold">{name}</h4>
								</div>
							</Col>
					</Row>
    </div>
  </div>
};

export default LogoBanner;
