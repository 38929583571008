export const aboutusicons = [
	{
		title: "Industry Interface Center",
		imageUrl: require("../assets/about-us/AboutUs Icon (1).png"),
	},
	{
		title: "World-Class Infrastructure ",
		imageUrl: require("../assets/about-us/AboutUs Icon (2).png"),
	},
	{
		title: "Leadership & Management",
		imageUrl: require("../assets/about-us/AboutUs Icon (3).png"),
	},
	{
		title: "Values of the Institution ",
		imageUrl: require("../assets/about-us/AboutUs Icon (4).png"),
	},
];