import React from "react";
import { Image } from "react-bootstrap";

import "./studentFeedbackCard.style.scss";

const StudentFeedbackCard = ({ imageUrl, name, company, feedback }) => {
	return (
		<div className="student-feedback-card" style={{ height: "32rem" }}>
			<div
				className="student-feedback-card--head"
				style={{ overflow: " hidden" }}
			>
				<Image
					src={imageUrl.default ? imageUrl.default : imageUrl}
					alt={name}
					className="img-fluid student-feedback-card--image"
				/>
			</div>
			<div className="student-feedback-card--title">
				<h6 className="display-9 font-weight-bold text-heading-color mb-0">
					{name}
				</h6>
				<p className="text-highlight-color mb-0">{company}</p>
			</div>
			<div className="student-feedback-card--content">
				<p className="font-md font-italic">{feedback}</p>
			</div>
		</div>
	);
};

export default StudentFeedbackCard;
