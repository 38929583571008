import React from "react";
import PageWrapper from "../../../components/NIISPageWrapper/PageWrapper";
import HeroSection from "../../../components/NIISHeroSection/HeroSection";
import HeroCaption from "../../../components/NIISHeroCaption/HeroCaption";
import AboutAlumniResponsive from "../../../components/AboutAlumniResponsive/AboutAlumniResponsive";
import AlumniStoriesResponsive from "../../../components/AlumniStoriesResponsive/AlumniStoriesResponsive";
import AlumniRegisterResponsive from "../../../components/AlumniRegisterResponsive/AlumniRegisterResponsive";
import AlumniInfographyResponsive from "../../../components/AlumniInfographyResponsive/AlumniInfographyResponsive";

const AlumniPage = () => {
	return (
		<PageWrapper className="mt-n6">
			<HeroSection className="bg-page-background-4">
				<HeroCaption
					title="Alumni"
					position="bottom-left"
					breadcrumb={true}
					breadcrumbPrefix="Home"
				/>
			</HeroSection>

			<AboutAlumniResponsive />
			<AlumniStoriesResponsive />
			<AlumniInfographyResponsive/>
			<AlumniRegisterResponsive />
		</PageWrapper>
	);
};

export default AlumniPage;

// <AboutNibaResponsive />
// <TrusteeResponsive />
// <InfographyResponsive />
// <FromStudentsResponsive />
// <QualityPolicyContentResponsive />
