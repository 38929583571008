import React from "react";
import PageHeader from "../../../components/NIISPageHeader/PageHeader";
import PageSection from "../../../components/NIISPageSection/PageSection";
import PageWrapper from "../../../components/NIISPageWrapper/PageWrapper";

const FacultyDevelopmentProgramme = () => {
    return (
        <PageWrapper>
            <PageHeader title="Faculty Development Programme"/>
            <PageSection>
                <p>The academic excellence of NIIS has been
spurred by the group of highly qualified, competent and motivated faculty
members having updated knowledge in core subjects and experts in theirconcerned fields. But to help them keep up with the latest trends and
technologies, to understand student psychology to the deepest, we guide
the teachers with rigorous training programs and seminars as well- so
they can be more interactive and malleable into the student world and
understand their perspectives clearly as well.</p>
            </PageSection>
        </PageWrapper>
    )
}

export default FacultyDevelopmentProgramme;
