import React from "react";
import PageHeader from "../../../components/NIISPageHeader/PageHeader";
import PageSection from "../../../components/NIISPageSection/PageSection";
import PageWrapper from "../../../components/NIISPageWrapper/PageWrapper";
import CustomIcon from "../../../components/NIISCustomIcon/CustomIcon";
import { AiFillFilePdf } from "react-icons/ai";
import PageSectionHeader from "../../../components/NIISPageSectionHeader/PageSectionHeader";
import Blockquote from "../../../components/NIISBlockquote/Blockquote";

const BachelorOfScienceHonoursPage = () => {
  return (
    <PageWrapper>
      <PageHeader title="Bachelor of Science (Honours)" />
      <PageSection>

        <p className="mb-4">
        In this course the students will study an
extensive range of courses underpinned by cutting edge research and develop
high levels of personal initiative, independent thinking and communication skills.
        </p>

        <p className="text-center">
          <a href="/#" className="download-link">
            <CustomIcon currentIcon={AiFillFilePdf} />
            <span className="ml-2">DOWNLOAD COMPLETE SYLLABUS</span>
          </a>
        </p>
      </PageSection>
      <PageSection bgClass="light">
        <PageSectionHeader title="Course Structure" leftStyle="bottom" />
        <div className="ml-2">
          <Blockquote>
            <p>
              <span className="font-weight-bold">Semester : </span>
              BSc. (Phy,Chem,Bot,Zoology,Math)
            </p>
            <p>
              <span className="font-weight-bold">Duration : </span>3 yrs (6
              Semesters)
            </p>
            <p>
              <span className="font-weight-bold">Intake Capacity : </span>60
            </p>
            <p>
              <span className="font-weight-bold">Tuition Fees per Annum :</span>
              As per Govt. Directives
            </p>
          </Blockquote>
        </div>
      </PageSection>
      <PageSection>
        <PageSectionHeader title="Eligibility" rightStyle="bottom" />
        <p>+2 Passed Student in Science Stream.</p>
      </PageSection>
      <PageSection bgClass="light">
        <PageSectionHeader title="Selection Procedure" leftStyle="bottom" />
        <p>Online admission conducted by Govt. of Odisha</p>
      </PageSection>
      <PageSection>
        <PageSectionHeader title="Career Opportunity" rightStyle="bottom" />
        <p>
          After the completion of the B.Sc degree there are various options
          available for the science students, they can go for master degree in
          Science i.e. M.Sc, go in a research area and can even look for
          professional job oriented courses. Often, in some reputed universities
          or colleges in India and abroad the students are recruited directly by
          big MNC’s after their completion of the course.
        </p>
      </PageSection>
    </PageWrapper>
  );
};

export default BachelorOfScienceHonoursPage;
