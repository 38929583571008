import React from "react";
import PageHeader from "../../../components/NIISPageHeader/PageHeader";
import PageSection from "../../../components/NIISPageSection/PageSection";
import PageWrapper from "../../../components/NIISPageWrapper/PageWrapper";
import CustomIcon from "../../../components/NIISCustomIcon/CustomIcon";
import { AiFillFilePdf } from "react-icons/ai";
import PageSectionHeader from "../../../components/NIISPageSectionHeader/PageSectionHeader";
import Blockquote from "../../../components/NIISBlockquote/Blockquote";

const BachelorInBioTechnologyPage = () => {
  return (
    <PageWrapper>
      <PageHeader title="Bachelor in BIO-Technology" imageUrl={require('../../../assets/information-science-academic/page/Biotech.jpg')}/>
      <PageSection>

        <p className="mb-4">
        With the current pace of technological
advancement, Bachelor of Biotechnology graduates can have excellent career
prospects. Bachelor of Biotechnology curriculum is designed to provide
fundamental knowledge in the field of biotechnology and molecular biology.
        </p>

        <p className="text-center">
          <a href="/#" className="download-link">
            <CustomIcon currentIcon={AiFillFilePdf} />
            <span className="ml-2">DOWNLOAD COMPLETE SYLLABUS</span>
          </a>
        </p>
      </PageSection>
      <PageSection bgClass="light">
        <PageSectionHeader title="Course Structure" leftStyle="bottom" />
        <div className="ml-2">
          <Blockquote>
            <p>
              <span className="font-weight-bold">Semester : </span>
              BSc. (Phy,Chem,Bot,Zoology,Math)
            </p>
            <p>
              <span className="font-weight-bold">Duration : </span>3 yrs (6
              Semesters)
            </p>
            <p>
              <span className="font-weight-bold">Intake Capacity : </span>128
            </p>
            <p>
              <span className="font-weight-bold">Tuition Fees per Annum :</span>
              As per Govt. Directives
            </p>
          </Blockquote>
        </div>
      </PageSection>
      <PageSection>
        <PageSectionHeader title="Eligibility" rightStyle="bottom" />
        <p>+2 Passed Student in Science Stream</p>
      </PageSection>
      <PageSection bgClass="light">
        <PageSectionHeader title="Selection Procedure" leftStyle="bottom" />
        <p>Online admission conducted by Govt. of Odisha.</p>
      </PageSection>
      <PageSection>
        <PageSectionHeader title="Career Opportunity" rightStyle="bottom" />
        <p>
          It is predicted that biotechnology will be one of the most important
          applied sciences of the 21st century. Our graduates count as ‘highly
          technically skilled’ workers, i.e. professionals who can combine their
          knowledge of biology with technology. The combination of ‘good brains
          and good hands’ is high in demand on the job market. This is one of
          the reasons why the government is stimulating the choice for an
          exact-technical study programme, and employers keep looking for
          technically educated graduates.
        </p>
        <p>
          During your studies, you will learn how to produce a vaccine. You can
          turn this into your job by developing new procedures in a research
          laboratory and implementing them as large-scale cell cultivation
          processes in a production facility. A degree in Biotechnology opens a
          lot of different doors! Like Biomedical Research ,Forensic Sciences,
          Process Engineering, Water Technology etc.
        </p>
      </PageSection>
    </PageWrapper>
  );
};

export default BachelorInBioTechnologyPage;
