import React, { useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { db } from "../../services/firebase";
import emailjs from '@emailjs/browser';
// import emailjs from '@emailjs/browser';

const JuniorScienceEnquiryForm = () => {
	const [enquiryForm, setEnquiryForm] = useState({});
	const [loading, setLoading] = useState(false);
	const handleChange = (event) => {
		const { name, value } = event.target;

		setEnquiryForm({ ...enquiryForm, [name]: value });
	};

	const handleSubmit = async (event) => {
		const serviceId = 'service_46b23gc';
		const templateId = 'template_lgfpo9n';
		const publicKey = 'iXGd58_chlSJaIeAI';

		const templateParams = {
			from_name: enquiryForm['name'],
			phoneNumber: enquiryForm['phoneNumber'],
			email: enquiryForm['email'],
			course: enquiryForm['course'],
			message: enquiryForm['message'],
		}


		event.preventDefault();
		const enquiryRef = db.collection("junior_science");

		try {
			await enquiryRef.add({
				...enquiryForm,
			});

			await emailjs.send(serviceId, templateId, templateParams, publicKey)
			.then((response) => {
				console.log(response);
				setEnquiryForm(null);
			}).catch((error) => {
				console.error(error);
			});
			console.log(enquiryForm);

			setEnquiryForm({});
			alert("Form submitted");
			window.location.reload();
		} catch (err) {
			alert(err);
		}


		// event.preventDefault();
		// const enquiryRef = db.collection("inquiriesHSS");

		// try {
		// 	await enquiryRef.add({
		// 		...enquiryForm,
		// 	});
		// 	emailjs
		// 		.send(
		// 			"service_jwkghuf",
		// 			"template_7p2xbuw",
		// 			enquiryForm,
		// 			"doc1q9WT9n3-ROqTA"
		// 		)
		// 		.then(
		// 			(res) => {
		// 				setLoading(false);
		// 				alert("From submitted");
		// 				window.location.reload();
		// 			},
		// 			(error) => {
		// 				setLoading(false);
		// 				alert("Form not submitted, please try again..");
		// 			}
		// 		);
		// 	setEnquiryForm({});
		// } catch (err) {
		// 	alert(err);
		// }
	};

	return (
		<Form onSubmit={handleSubmit}>
			<Row>
				<Col md="6">
					<Form.Group controlId="info-science-enquiry-name">
						<Form.Label>Name*</Form.Label>
						<Form.Control
							type="text"
							placeholder="Enter Name"
							required
							className="shadow-sm"
							name="name"
							onChange={handleChange}
						/>
					</Form.Group>
				</Col>
				<Col md="6">
					<Form.Group controlId="info-science-enquiry-email">
						<Form.Label>Email*</Form.Label>
						<Form.Control
							type="email"
							placeholder="Enter Email"
							required
							className="shadow-sm"
							name="email"
							onChange={handleChange}
						/>
					</Form.Group>
				</Col>
				<Col md="6">
					<Form.Group controlId="info-science-enquiry-phone">
						<Form.Label>Phone Number*</Form.Label>
						<Form.Control
							type="tel"
							size="12"
							placeholder="Enter Phone number"
							pattern="[0-9]{10,12}"
							required
							className="shadow-sm"
							name="phoneNumber"
							onChange={handleChange}
						/>
					</Form.Group>
				</Col>
				<Col md="6">
					<Form.Group controlId="info-science-enquiry-course">
						<Form.Label>Select a course*</Form.Label>
						<Form.Control
							as="select"
							required
							className="shadow-sm"
							name="course"
							onChange={handleChange}
						>
							<option value="">Select courses</option>
							<option value="+2 Sc.">+2 Sc.</option>
							<option value="+2Com.">+2Com.</option>
						</Form.Control>
					</Form.Group>
				</Col>
				<Col>
					<Form.Group controlId="info-science-enquiry-content">
						<Form.Label>Write to us*</Form.Label>
						<Form.Control
							as="textarea"
							rows={5}
							required
							placeholder="Write your query here"
							className="shadow-sm"
							name="message"
							onChange={handleChange}
						/>
					</Form.Group>
				</Col>
			</Row>
			<Button type="submit" disabled={loading}>
				Submit
			</Button>
		</Form>
	);
};

export default JuniorScienceEnquiryForm;
