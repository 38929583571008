import React from "react";
import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";

import "./quickLinksMiniCard.style.scss";

const QuickLinksMiniCard = ({ title, imageUrl, link, external }) => {
  const Wrapper = ({ children }) =>
    external ? (
      <a
        href={link}
        target="_blank"
        rel="noreferrer"
        className="quick-links-mini-card"
      >
        {children}
      </a>
    ) : (
      <Link className="quick-links-mini-card" to={link}>
        {children}
      </Link>
    );

  return (
    <Wrapper>
      <Image
        src={imageUrl.default ? imageUrl.default : imageUrl}
        alt={title}
        className="quick-links-mini-card--image"
      />
      <div className="quick-links-mini-card--title">
        <h6>{title}</h6>
      </div>
    </Wrapper>
  );
};

export default QuickLinksMiniCard;
