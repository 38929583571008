import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import PageHeader from "../../../components/NIISPageHeader/PageHeader";
import PageSection from "../../../components/NIISPageSection/PageSection";
import PageWrapper from "../../../components/NIISPageWrapper/PageWrapper";

const ExaminationPage = () => {
  return (
    <PageWrapper>
      <PageHeader title="Examination" />
      <PageSection>
        <p>
          Our Weekly Test Series is a weekend test which is conducted in the
          CHSE pattern from the beginning to the end of the whole academic
          year(July-Next September) to monitor minute growth and results of the
          students.
        </p>
        <p>
          We conduct the Revision Test Series(September/October) in addition to
          the Weekly Test Series to test the children’s revision and retention
          skills. It is conducted during the month of November/December.
        </p>
        <p>
          The Pre Boards are conducted in the month of November every year to
          give a board experience, pattern recognition and self analysis to the
          students before they appear the final exam.
        </p>
        <p>
          The Annual Exam is conducted in the month of January which covers the
          entire CHSE syllabus of the entire year.
        </p>
      </PageSection>
      <PageSection bgClass="light">
        <Row className="justify-content-center">
        <Col md="6">
            <Image
              src={
                require("../../../assets/exam/exam.JPG")
                  .default
              }
              className="img-fluid rounded mb-4"
            />
          </Col>
        </Row>
      </PageSection>
    </PageWrapper>
  );
};

export default ExaminationPage;
