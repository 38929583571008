export const footerData = [
  // {
  //   title: "Home",
  //   link: `/`,
  // },
  // {
  //   title: "Placement & Training",
  //   link: `/placement/placement-and-training`,
  // },
  // {
  //   title: "Academic Advisor",
  //   link: `/about/academic-advisor`,
  // },
  // {
  //   title: "Online Exam",
  //   link: `/online-exam`,
  // },
  // {
  //   title: "Admission Enquiry",
  //   link: `/admission/enquiry`,
  // },
  // {
  //   title: "Gallery",
  //   link: `/gallery`,
  // },
  // {
  //   title: "Contact Us",
  //   link: `/contact-us`,
  // },
];
