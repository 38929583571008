import React from "react";
import PageHeader from "../../../components/NIISPageHeader/PageHeader";
import PageSection from "../../../components/NIISPageSection/PageSection";
import PageWrapper from "../../../components/NIISPageWrapper/PageWrapper";
import PageSectionHeader from "../../../components/NIISPageSectionHeader/PageSectionHeader";

const StudentClubPage = () => {
  return (
    <PageWrapper>
      <PageHeader title="Students Club" />
      <PageSection>
        <p>
          NIIS is a centre of excellence where education is a journey of
          exploring both the self and the world around. It goes beyond the
          confines of classroom and curriculum. Different learning clubs like IT
          Club (Satya), Cultural Club (Shiva), Nature's Club (Sampad), Marketing
          Club (Sambhav), HR Club (Sampark), Finance Club (Nidhi),
          Entrepreneur's Club (Sangathak), Sports Club(Swasth), Teacher's Club
          (Sikshyak), Literature Club (Smaranika) are functioning at NIIS
          specifically to cater to the needs of the students/teachers in
          realizing their innate potential in different areas of interest and
          showcasing their talent.
        </p>
        <p>
          Apart from providing an avenue for students to showcase their immense
          talents, these clubs also encourage students to take the initiative in
          organizing events and set new standards of excellence. To quote
          Abraham Lincoln, these are clubs "of the students, by the students,
          for the students". A brief overview on the clubs is provided to give a
          glimpse of the activity that NIISians pursue in spite of their hectic
          academic schedule.
        </p>
      </PageSection>
      <PageSection bgClass="light">
        <PageSectionHeader
          title="Cultural & Theatrical Society (SHIVA)"
          leftStyle="bottom"
        />
        <p>
          The world's a stage and NIIS is no exception. The dramatics society
          provides a platform for students to display their skills at acting,
          direction, or production. To see or not to see is never a question
          because the performances of the club have always won rave reviews,
          both from students within the campus and from viewers outside who
          eagerly await each semester.
        </p>
      </PageSection>
      <PageSection>
        <PageSectionHeader title="Nature (SUNDARAM)" rightStyle="bottom" />
        <p>
          It encourages students to appreciate nature and work towards
          preserving it. The various activities of the club include nature
          camps, bird watching expeditions, guest lectures, photography/poster
          competitions within the campus, interacting with other NGOs and
          government bodies in the city. The club brings out The Green Diary, a
          magazine where students and faculty can write about their experiences
          and experiments with nature. So if your heart leaps up when you behold
          a rainbow in the sky and the poetry of Wordsworth rivets you, you're a
          'natural' for this club.
        </p>
      </PageSection>
      <PageSection bgClass="light">
        <PageSectionHeader title="IT Club (SATYA)" leftStyle="bottom" />
        <p>
          The booming IT sector in India has brightened the prospects of the
          systems industry and opening ample scopes for students. It also
          facilitates close interaction and contact between students and
          Information Technology companies. The club also gives guidance about
          the various lucrative career options in IT, ITES, offshoring,
          outsourcing, IT consult etc. and intends to bring live paid projects
          to the campus for the students to gain hands on experience, get
          industry certification and make money.
        </p>
      </PageSection>
      <PageSection>
        <PageSectionHeader
          title="Marketing Club (SAMBHAV)"
          rightStyle="bottom"
        />
        <p>
          Marketing is the umbilical connection between customer needs and
          creative solutions. It seeks to provide information and experiences
          that increase awareness about the marketing function and inform
          students about the varied and exciting career opportunities available
          in this field.
        </p>
        <p>
          A variety of events such as ad contests, brand equity quizzes and
          brand crosswords, it promotes student's interest in the field of
          marketing. It also organizes workshops on strategic selling, market
          forecasting, market research and advertising, conducted by top
          industry professionals in order to give students an insight into the
          exciting and dynamic world of marketing.
        </p>
      </PageSection>
      <PageSection bgClass="light">
        <PageSectionHeader title="Finance Club (Nidhi)" leftStyle="bottom" />
        <p>
          It is a club where you will find likeminded people those who are
          passionate about money markets and stock exchanges then. It informs
          the students regarding latest indexes in Stock Market, Positions of
          the enlisted Companies etc. An important agenda of the club is to
          facilitate communication between students on various financial issues
          and queries and produced finance paper presentations, quizzes, equity
          and fixed income research reports, arbitrage spotting contests and the
          like for students at an intra-institute, national and international
          level. It arranges for guest lecturers from distinguished
          personalities from the field of finance in order to give students a
          picture of how the financial industry actually works.
        </p>
      </PageSection>
      <PageSection>
        <PageSectionHeader title="The HR Club (SAMPARK)" rightStyle="bottom" />
        <p>
          HR Club takes initiation to generate information about key personnel
          of reputed corporate houses in the field of recruitment & training.
          Club organizes HR meet, HR conclave and periodic interaction of HR
          executives with the students for developing employability.
        </p>
      </PageSection>
      <PageSection bgClass="light">
        <PageSectionHeader
          title="The Entrepreneurship Club (SANGATHAK)"
          leftStyle="bottom"
        />
        <p>
          It promotes student initiative in business and also arranges for
          interactions with successful entrepreneurs who can pass on winning
          tips to others eager to emulate them. It also organizes various
          workshops for those peoples whose have a dream to start their own
          business starting from book binding, sauce industry, milk collection
          and distribution, motor mechanics to larger one.
        </p>
      </PageSection>
      <PageSection>
        <PageSectionHeader title="Sports Club (SWASTH)" rightStyle="bottom" />
        <p>
          Its role is to engage the students by organizing different sports
          activities to make them fit as well as free them mental pressure. It
          also taking initiatives to participate in many local tournaments and
          events organized different college, university, state and national
          level organization. The institute has a vibrant sports culture thanks
          to the Sports Club which maintains cricket, volley-ball, badminton,
          table tennis as well as a well-equipped gym.{" "}
        </p>
      </PageSection>
      <PageSection bgClass="light">
        <PageSectionHeader
          title="Teachers Club (SIKSHYAN)"
          leftStyle="bottom"
        />
        <p>
          NIIS is a group of institutions. Through teachers club, NIIS tries to
          keep a binding between different subject teachers. Through developing
          this linkage a better teacher force is created and multidisciplinary
          use of teachers is made which gives a good sign for organizational
          group.
        </p>
      </PageSection>
      <PageSection>
        <PageSectionHeader
          title="Literature Club (SMARANIKA)"
          rightStyle="bottom"
        />
        <p>
          To develop literary attitude of students & faculty members in
          different field, we have wall magazine "NIIS DUNIYA" and we publish a
          research journal having ISSN No. - 2348-6619 bi-annually namely
          "Innovative Professional Science & IT (JIPSIT). We also publish a
          souvenir on annual basis namely "Smaranika" and to publish the events
          each year there is a print documentation namely "Mirror" on annual
          basis. Students, teachers and educationist of repute contribute
          articles and after due scrutiny, articles are published. Student
          Corner GuidelinesDress CodeDownload NotesStudents ClubSyllabus
        </p>
      </PageSection>
    </PageWrapper>
  );
};

export default StudentClubPage;
