import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import PageSection from "../NIISPageSection/PageSection";
import ResponsivePageHeader from "../NIISResponsivePageHeader/ResponsivePageHeader";

const AboutUsResponsive = () => {
    return <div>
        <PageSection bgClass="light">
        <ResponsivePageHeader centered={true} title="About Us " />
        {/* <PageSectionHeader title="About NIIS" leftStyle="bottom" /> */}
        <Row>
          <Col md="7" className="mb-4">
            <p>
              The group was established in the year 2000 under the aegis of Nilachal Management Trust (NEMT). We are a group of visionaries, aiming to pioneer into the sector of IT services, Management and Social wings of studies. With a motto of providing a world class learning curriculum to all our students, we have established constituent colleges of different domains, namely NIIS Institute of Business Administration, NIIS Institute of Information Science and Management and NIIS Higher Secondary School.
            </p>
            <p>NIIS aims on providing an education that-</p>
        <div className="ml-2">
          <ul>
            <li>
              <p> offers lifelong learning opportunities</p>
            </li>
            <li>
              <p>
                fosters dynamic learning environments designed to meet the needs
                of our students
              </p>
            </li>
            <li>
              <p>guarantees equality of rights and access.</p>
            </li>
            <li>
              <p>ensures integrity and honesty in the learning process</p>
            </li>
          </ul>
        </div>
          </Col>
          <Col md="5" className="mb-4">
            <br />
        
            <Image
              src={require("../../assets/college-image3.png").default}
              className="shadow-lg img-fluid" 
            />
          </Col>
        </Row>
        
        <p>
          A good curriculum or classroom knowledge are all in vain if the ward
          isn’t excelling in a proper communication tactic and overall
          development. We at NIIS ensure that the students always go through
          intense yet interactive sessions of personality developments, soft
          skills enhancement.
        </p>
      </PageSection>
    </div>
}

export default AboutUsResponsive;