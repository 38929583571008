import React from "react";
import PageWrapper from "../../../components/NIISPageWrapper/PageWrapper";
import PageSection from "../../../components/NIISPageSection/PageSection";
import PageSectionHeader from "../../../components/NIISPageSectionHeader/PageSectionHeader";
import PageHeader from "../../../components/NIISPageHeader/PageHeader";
import { Col, Row } from "react-bootstrap";
import AdmissionMiniCard from "../../../components/AdmissionMiniCard/AdmissionMiniCard";
import { ugAdmissionPageData } from "../../../static-data/ug-admissionpage.data";
import HeroSection from "../../../components/NIISHeroSection/HeroSection";
import HeroCaption from "../../../components/NIISHeroCaption/HeroCaption";

const UGAdmissionPage = () => {
	return (
		<PageWrapper>
		<PageHeader title="Under Graduate Programs" />
		<PageSection>
		  <p style={{textAlign: "justify"}}>
		  	Embark on a transformative academic journey at NIIS, where education meets innovation, and aspirations find wings. 
		  	Our undergraduate programs are crafted to nurture curious minds, fostering holistic development and preparing 
		  	you for success in a rapidly evolving world.
		  </p>
		</PageSection>
		<PageSection bgClass="light">
		  <PageSectionHeader title="Why Choose NIIS for Your UG Adventure?" rightStyle="bottom" />
		  <PageSection bgClass="background-white">
				<Row className="justify-content-center">
					{ugAdmissionPageData.map((data, index) => (
						// <Row className="justify-content-center">
							<Col
								md="4 mt-2"
								className="d-flex align-items-stretch mb-4 mb-md-0"
								key={"about-us-home" + index}
							>
								<AdmissionMiniCard {...data} />
							</Col>
						//</Row>
					))}
				</Row>
			</PageSection>
		</PageSection>
	  </PageWrapper>
	);
};

export default UGAdmissionPage;