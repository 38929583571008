import React from "react";
import PageHeader from "../../../components/NIISPageHeader/PageHeader";
import PageSection from "../../../components/NIISPageSection/PageSection";
import PageWrapper from "../../../components/NIISPageWrapper/PageWrapper";

const RulesAndRegulationsPage = () => {
  return (
    <PageWrapper>
      <PageHeader title="Rules & Regulations" />
      <PageSection>
        <ul>
          <li>
            <p>
              Attendance in theory and practical classes is a must where
              University/CHSE stipulation is that it should not be less than
              75%.
            </p>
          </li>
          <li>
            <p>
              Continuation of studentship in the Institute will be subject to
              satisfactory academic progress and good behavior inside and
              outside the campus.
            </p>
          </li>
          <li>
            <p>
              Damage to the Institute property by a student will be charged to
              the student as to be fixed by the authority.
            </p>
          </li>
          <li>
            <p>
              Students are required to follow the library, laboratory and the
              hostel rules as prescribed and amended from time to time.
            </p>
          </li>
          <li>
            <p>
              Students are advised not to disturb the academic calendar and in
              specific the examination schedules both internal and university
              exams.
            </p>
          </li>
          <li>
            <p>
              Home assignments given by teachers must be attended to in time and
              submitted in due dates.
            </p>
          </li>
          <li>
            <p>
              All students must observe the dress code/uniforms specified by the
              Institute and carry their Identity cards duly renewed in every
              academic year at all times.
            </p>
          </li>
          <li>
            <p>
              Fees must be paid in time. Delay in payment of fees may attract
              fines.
            </p>
          </li>
          <li>
            <p>
              The students cannot attempt or persuading others to attempt
              towards boycott or postpone of classes and examinations.
            </p>
          </li>
          <li>
            <p>
              Raging inside and outside the campus is strictly prohibited and
              the concerned students will be punished by the authority.
            </p>
          </li>
          <li>
            <p>
              After completion of the course of study i.e. after the final
              examination of the final year of study of the concerned course,
              the studentship will automatically lapse.
            </p>
          </li>
          <li>
            <p>
              Institute Authorities reserve the right to cancel the studentship
              under disciplinary and other appropriate grounds.
            </p>
          </li>
          <li>
            <p>
              The University examination, evaluation of answer papers and
              publication of results are scheduled and conducted by the Utkal
              University Authority. The institute will not be held responsible
              for delay in examinations and declaration of results though
              necessary efforts shall be taken by the institute.
            </p>
          </li>
          <li>
            <p>
              The Institute reserve the right to continue the academic
              sessions/semester curriculum of subsequent semesters if the
              examination is not being held in due time, due to some unavoidable
              reasons. • The student is required to deposit the course fees on
              the basis of academic sessions only i.e. from July June or as
              amended.
            </p>
          </li>
          <li>
            <p>
              The Institute reserves the right to restrict any student due to
              non payment of fees or on any disciplinary ground.
            </p>
          </li>
        </ul>
      </PageSection>
    </PageWrapper>
  );
};

export default RulesAndRegulationsPage;
