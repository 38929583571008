import React from "react";
import PageHeader from "../../../components/NIISPageHeader/PageHeader";
import PageSection from "../../../components/NIISPageSection/PageSection";
import PageWrapper from "../../../components/NIISPageWrapper/PageWrapper";
import CustomIcon from "../../../components/NIISCustomIcon/CustomIcon";
import { AiFillFilePdf } from "react-icons/ai";
import PageSectionHeader from "../../../components/NIISPageSectionHeader/PageSectionHeader";
import Blockquote from "../../../components/NIISBlockquote/Blockquote";

const BachelorOfScienceITMPage = () => {
  return (
    <PageWrapper>
      <PageHeader title="Bachelor of Science (I.T.M)" imageUrl={require('../../../assets/information-science-academic/page/ITM.jpg')}/>
      <PageSection>
        <p className="mb-4">
        This course is the stepping stone for every young
student who aspires for a successful career in Information Technology and
Management Science.
        </p>

        <p className="text-center">
          <a href="/#" className="download-link">
            <CustomIcon currentIcon={AiFillFilePdf} />
            <span className="ml-2">DOWNLOAD COMPLETE SYLLABUS</span>
          </a>
        </p>
      </PageSection>
      <PageSection bgClass="light">
        <PageSectionHeader title="Course Structure" leftStyle="bottom" />
        <div className="ml-2">
          <Blockquote>
            <p>
              <span className="font-weight-bold">Semester : </span>
              BSc. I.T.M
            </p>
            <p>
              <span className="font-weight-bold">Duration : </span>3 yrs (6
              Semesters)
            </p>
            <p>
              <span className="font-weight-bold">Intake Capacity : </span>128
            </p>
            <p>
              <span className="font-weight-bold">Tuition Fees per Annum :</span>
              As per Govt. Directives
            </p>
          </Blockquote>
        </div>
      </PageSection>
      <PageSection>
        <PageSectionHeader title="Eligibility" rightStyle="bottom" />
        <p>
          NIIS Aptitude Test and Higher Secondary / 10 +2 passed in Science.
        </p>
      </PageSection>
      <PageSection bgClass="light">
        <PageSectionHeader title="Selection Procedure" leftStyle="bottom" />
        <p>
          Through AIMA-UGAT/NAT/Merit based on previous academic record,
          followed by personal interview.
        </p>
      </PageSection>
      <PageSection>
        <PageSectionHeader title="Career Opportunity" rightStyle="bottom" />
        <p>
          A successful BSc.(ITM) student may get suitable employment opportunity
          as Software Developer Programmer, System Analyst-Com Specialist,
          Software Engineer, Quality Analyst, Web Designer, Web Developer,
          Technical Consultant etc. in Govt. Deptt, PSUs, Banks, Pvt. Industry,
          etc. as PSUs, Banks, Pvt. Industry, Retail , & Logistics. One can go
          for administration & Managerial line & can become management
          consultant, training consultant or pursue higher study like MBA, MSc
          (Computer Science) or 3 year MCA or 2 year MCA (Lateral Entry) after
          completion of the course.
        </p>
      </PageSection>
    </PageWrapper>
  );
};

export default BachelorOfScienceITMPage;
