export const collegeNameCardData = [
	{
		title: "NIIS Institute of Information Science & Management ",
		imageUrl: require("../assets/colleges/niism.jpg"),
	},
	{
		title: "NIIS Higher Secondary School ",
		imageUrl: require("../assets/colleges/nhss.JPG"),
	},
	{
		title: "NIIS Institute of Business Administration ",
		imageUrl: require("../assets/colleges/niba.jpg"),
	},
	{
		title: "NIIS Institute of Engineering & Technology ",
		imageUrl: require("../assets/colleges/niet.jpg"),
	},
];
