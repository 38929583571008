export const niisAwards = [
    {
        imageUrl: require('../assets/award/award-1.png'),
        content: "Awarded as Pioneer Management Institute of Odisha-2017, By Estrade Education Awards-2017, Singapore."
    },
    {
        imageUrl: require('../assets/award/award-2.png'),
        content: "Best management Institute in Odisha-2016, By Asia Education Summit Awards."
    },
    {
        imageUrl: require('../assets/award/award-3.png'),
        content: "Awarded as ‘Best Technical Institute for Higher Education’, Odisha 2016 By BPUT student association."
    }
]
