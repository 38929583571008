import React from "react";
import PageHeader from "../../../components/NIISPageHeader/PageHeader";
import PageSection from "../../../components/NIISPageSection/PageSection";
import PageWrapper from "../../../components/NIISPageWrapper/PageWrapper";

const SeminarPage = () => {
  return (
    <PageWrapper>
      <PageHeader title="Seminar" />
      <PageSection>
        <p>
        Seminars are always considered as windows to the current leads of
exposure to trends, techniques and tactics. With our profound guest
lectures and seminars, we aim to imbibe in students the current world’s
techniques and practices to transform them into dynamic persons.
Periodic seminars and lectures are conducted on various topics of
academic and employment interest by academicians, industrialists and
practicing professionals to make the students aware of various concepts,
ideas, practices and latest developments in IT and management sector,
with the scope for interaction with the presentator for development and
success in professional career.
        </p>
      </PageSection>
    </PageWrapper>
  );
};

export default SeminarPage;
