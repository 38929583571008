import React, { useState, useEffect } from "react";
import bsBreakpoints from "bs-breakpoints";
import classNames from "classnames";

const ResponsivePageHeader = ({ title, centered, colorClass, marginClass }) => {
  const [currentBreakPoint, setCurrentBreakPoint] = useState("");

  useEffect(() => {
    bsBreakpoints.init();
    setCurrentBreakPoint(bsBreakpoints.getCurrentBreakpoint());

    const handleBreakPoint = (event) => {
      setCurrentBreakPoint(event.detail);
    };

    window.addEventListener("new.bs.breakpoint", handleBreakPoint);

    return () => {
      window.removeEventListener("new.bs.breakpoint", handleBreakPoint);
    };
  }, []);

  const headingClasses = classNames(
    "text-uppercase font-weight-bold",
    {
      "display-6": !(
        currentBreakPoint === "xSmall" || currentBreakPoint === "small"
      ),
    },
    {
      "display-8":
        currentBreakPoint === "xSmall" || currentBreakPoint === "small",
    },
    {
      "text-center": centered,
    },
    {
      "text-heading-color": !colorClass,
      [colorClass]: colorClass,
    },
    {
      "mb-4": !marginClass,
      [marginClass]: marginClass,
    }
  );

  return <h2 className={headingClasses}>{title}</h2>;
};

export default ResponsivePageHeader;
