export const navbarData = [
	{
		title: "Home",
		mobileStart: true,
		link: "/",
	},
	{
		title: "NIIS Institute of Information Science & Management",
		link: "/niis-information-science",
		leftMargin: true,
	},
	{
		title: "NIIS Higher Secondary School",
		link: `/niis-junior-college`,
		leftMargin: true,
		
	},
	{
		title: "NIIS Institute of Business Administration",
		link: `https://niisinstitute.edu.in/`,
		external: true,
		leftMargin: true,
		
	},
	{
		title: "NIIS Institute of Engineering & Technology",
		link: `https://nietbbsr.edu.in/`,
		external: true,
		leftMargin: true,
		
	},
	
	
	
];

export const newLinks = [
	"/",
	"/about-us",
	"/about/academic-advisor",
	"/academics/courses",
	"/academics/courses/mba",
	"/industry-interface-centre",
];
