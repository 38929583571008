import React from "react";

const EventsCard = ({ date, title, venue, time }) => {
  return (
    <div
      className="bg-white text-center py-4 px-2"
    >
      <p className="text-highlight-color font-weight-medium">{date}</p>
      <h6 className="text-link-color font-weight-bold">{title}</h6>
      <p className="font-italic mb-2">{venue}</p>
      <p className="font-italic mb-0">{time}</p>
    </div>
  );
};

export default EventsCard;
