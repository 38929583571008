import React, { useState, useEffect } from "react";
import bsBreakpoints from "bs-breakpoints";
import classNames from "classnames";

import PageSection from "../NIISPageSection/PageSection";
import ResponsivePageHeader from "../NIISResponsivePageHeader/ResponsivePageHeader";
import { Col, Row } from "react-bootstrap";
import { collegeNameCardData } from "../../static-data/niiscollegenames.data";
import CollegeNameMiniCard from "../NIISCollegeNameMiniCard/CollegeNameMiniCard";

const CollegeNameResponsive = () => {
	const [currentBreakPoint, setCurrentBreakPoint] = useState("");

	const descriptionClasses = classNames("mb-4 text-text-color", {
		"font-sm": currentBreakPoint === "xSmall" || currentBreakPoint === "small",
	});

	useEffect(() => {
		bsBreakpoints.init();
		setCurrentBreakPoint(bsBreakpoints.getCurrentBreakpoint());

		const handleBreakPoint = (event) => {
			setCurrentBreakPoint(event.detail);
		};

		window.addEventListener("new.bs.breakpoint", handleBreakPoint);

		return () => {
			window.removeEventListener("new.bs.breakpoint", handleBreakPoint);
		};
	}, []);

	return (
		<PageSection bgClass="background-color">
			<ResponsivePageHeader centered={true} title="Our Constituent Colleges " />
			{/* <p className={descriptionClasses}>
				NIIS Institute of Business Administration (NIBA) is one of the leading
				institutions providing postgraduate management & technical education in
				the state. It is the youngest institute under the ambit of NIIS Group of
				Institutions, Odisha. We have a long history and a rich alumni culture
				of over 14 years located in India and abroad.
			</p> */}
			<Row>
				{collegeNameCardData.map((data, index) => (
					<Col
						md="6"
						className="mb-4 mb-md-4"
						key={"about-us-home" + index}
					>
						<CollegeNameMiniCard type="about" {...data} />
					</Col>
				))}
			</Row>
		</PageSection>
	);
};

export default CollegeNameResponsive;
