import React from "react";
import PageWrapper from "../../components/NIISPageWrapper/PageWrapper";
import PageHeader from "../../components/NIISPageHeader/PageHeader";
import PageSection from "../../components/NIISPageSection/PageSection";
import {  Col, Row } from "react-bootstrap";
import AdvisorCard from '../../components/NIISAdvisorCard/AdvisorCard';
import { academicAdvisorData } from "../../static-data/academic-advisor.data";

const AcademicAdvisorPage = () => {
  return (
    <PageWrapper>
      <PageHeader title="Academic Advisors" />
      {/* <PageSection>
        <p>
          A panel of honorary advisors in the field of Administration, IT,
          Business Management, Social Welfare and Economics are there to advise
          the management in various academic matters.They are:
        </p>
      </PageSection> */}
      <PageSection >
        <Row>
          {academicAdvisorData &&
            academicAdvisorData.map(advisor => (
              <Col
                md="6"
                key={advisor.name}
                className="d-flex align-itmes-stretch mb-4"
              >
                <AdvisorCard {...advisor}/>
              </Col>
            ))}
        </Row>
      </PageSection>
    </PageWrapper>
  );
};

export default AcademicAdvisorPage;
