export const postgraduateCourseData = [
	{
		imageUrl: require("../assets/courses/card/mcom.png"),
		title: "Master of Commerce ",
		link: "/master-of-commerce",
		name: "M.Com"
	},

	{
		imageUrl: require("../assets/courses/card/mcomfc.png"),
		title: " Master of Commerce (F&C)",
		link: "/master-of-finance-and-control",
		name: "M.Com(FC)"
	},
	{
		imageUrl: require("../assets/courses/card/mpmir.png"),
		title: "Master in Personal Management & Industrial Relations",
		link: "/master-in-personal-management-and-industrial-relations",
		name: "MPMIR"
	},
	{
		imageUrl: require("../assets/courses/card/msc.png"),
		title: "Master of Science CS",
		link: "/master-in-computer-science",
		name: "M.Sc(CS)"
	},
    {
		imageUrl: require("../assets/courses/card/mscphy.png"),
		title: "Master of Science Physics",
		link: "/master-in-physics",
		name: "M.Sc(Phy)"
	},
    {
		imageUrl: require("../assets/courses/card/mscchem.png"),
		title: "Master of Science Chemistry",
		link: "/master-in-chemistry",
		name: "M.Sc(Chem)"
	},
	{
		imageUrl: require("../assets/courses/card/msw.png"),
		title: "Master in Social Work ",
		link: "/master-in-biotech",
		name: "MSW"
	},

];

export const undergraduateCourseData = [
	{
		imageUrl: require("../assets/courses/card/bba.png"),
		title: "Bachelor of Business Administration",
		link: "/bachelor-of-business-administration",
		name:"BBA"
	},
	{
		imageUrl: require("../assets/courses/card/bca.png"),
		title: "Bachelor of Computer Application ",
		link: "/bachelor-of-computer-application",
		name:"BCA"
	},
	{
		imageUrl: require("../assets/courses/card/bsccs.png"),
		title: "Bachelor of Science CS",
		link: "bachelor-of-science",
		name:"B.Sc(CS)"
	},
	{
		imageUrl: require("../assets/courses/card/bschons.png"),
		title: "Bachelor of Science Hons",
		link: "/bachelor-of-science-honours",
		name:"B.Sc(Hons)"
	},
	{
		imageUrl: require("../assets/courses/card/bcom.png"),
		title: "Bachelor of Commerce",
		link: "/bachelor-of-commerce",
		name:"B.Com"
	},
	{
		imageUrl: require("../assets/courses/card/ba.png"),
		title: "Bachelor of Arts",
		link: "/bachelor-of-arts",
		name:"B.A"
	},
	{
		imageUrl: require("../assets/courses/card/bscitm.png"),
		title: "Bachelor of Science ITM ",
		link: "/bachelor-of-science-itm",
		name:"B.Sc ITM"
	},
	{
		imageUrl: require("../assets/courses/card/bscbio.png"),
		title: "Bachelor of Science Bio-technology",
		link: "/bachelor-in-bio-technology",
		name:"B.Sc Bio-tech"
	},
	
];
