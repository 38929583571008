import React, { useState, useEffect } from "react";
import bsBreakpoints from "bs-breakpoints";
import classNames from "classnames";
import ResponsivePageHeader from "../NIISResponsivePageHeader/ResponsivePageHeader";
import { Col, Row } from "react-bootstrap";
import PageSection from "../NIISPageSection/PageSection";
import { aboutUsCardsData } from "../../static-data/aboutus.data";
import AboutUsMiniCard from "../NIISAboutUsMiniCard/AboutUsMiniCard";


const AboutNibaResponsive = () => {
	const [currentBreakPoint, setCurrentBreakPoint] = useState("");

	useEffect(() => {
		bsBreakpoints.init();
		setCurrentBreakPoint(bsBreakpoints.getCurrentBreakpoint());

		const handleBreakPoint = (event) => {
			setCurrentBreakPoint(event.detail);
		};

		window.addEventListener("new.bs.breakpoint", handleBreakPoint);

		return () => {
			window.removeEventListener("new.bs.breakpoint", handleBreakPoint);
		};
	}, []);

	const descriptionClasses = classNames("mb-4 text-center text-text-color", {
		"font-sm": currentBreakPoint === "xSmall" || currentBreakPoint === "small",
	});

	return (
		<PageSection bgClass="background-color">
			<ResponsivePageHeader centered={true} title="About NIIS" />
			<p className={descriptionClasses}>
			Nilachal Education & Management Trust (NEMT) extends a warm welcome as a renowned educational organization dedicated
			to delivering exceptional education and nurturing intellectual development. With a firm conviction in the power of 
			education to bring about life-changing transformations, NEMT oversees the operations of four esteemed institutions: 
			NIIS Institute of Business Administration, NIIS Institute of Information Science & Management, NIIS Higher Secondary School, 
			and NIIS Institute of Engineering & Technology. These colleges are known for their excellence in providing a comprehensive 
			educational experience to students. NIIS Institute of Information Science & Management, affiliated to Utkal University 
			provides undergraduate & postgraduate programs in the field of information technology, management, pure sciences, commerce
			& humanities. The college fosters a supportive and engaging learning environment, enabling students to thrive and become 
			successful professionals in the dynamic world of information science and management.
			</p>

			<Row className="mb-5">
				{aboutUsCardsData.map((data, index) => (
					<Col
						md="3"
						className="d-flex align-items-stretch mb-4 mb-md-0"
						key={"about-us-home" + index}
					>
						<AboutUsMiniCard {...data} />
					</Col>
				))}
			</Row>

			<Row className="justify-content-between">
				<Col md="6" className="mb-5 mb-md-0 ">
					<div
						className="border mb-4 rounded px-5 py-4 bg-light"
						style={{ height: "85%" }}
					>
						<p className="display-9 font-weight-normal font-heading-font">
							Vision
						</p>
						<p style={{ textAlign: "justify" }}>
							To become a nationally reckoning institute by developing value
							based transformational leaders who can contribute to the economic
							growth and social development of our nation.
						</p>
					</div>
				</Col>
				<Col md="6" className="mb-5 mb-md-0 ">
					<div
						className="border mb-4 rounded px-5 py-4 bg-light"
						style={{ height: "85%" }}
					>
						<p className="display-9 font-weight-normal font-heading-font">
							Mission
						</p>
						<p style={{ textAlign: "justify" }}>
							Our mission is to foster knowledge, wisdom, skill and competence
							among new age young minds to face the adversity and challenges of
							complex business scenarios having high adversity quotient and
							positive attitude with social values and ethos.
						</p>
					</div>
				</Col>
			</Row>
		</PageSection>
	);
};

export default AboutNibaResponsive;
