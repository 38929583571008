import React, {useState} from "react";
import { Row, Col, Image } from "react-bootstrap";
import PageWrapper from "../../../components/NIISPageWrapper/PageWrapper";
import PageSection from "../../../components/NIISPageSection/PageSection";
import InformationScienceCoursesResponsive from "../../../components/NIISInformationScienceCoursesResponsive/InformationScienceCoursesResponsive";
import AboutUsResponsive from "../../../components/NIISAboutUsResponsive/AboutUsResponsive";
import AboutUsIconsResponsive from "../../../components/NIISAboutUsIcons/AboutUsIconsResponsive";
import NiisHero from "../../../components/NIISNiisHero/NiisHero";
import PageSectionHeader from "../../../components/NIISPageSectionHeader/PageSectionHeader";
import NewsBites from "../../../components/NIISNewsBites/NewsBites";
import TestimonialSlides from "../../../components/NIISTestimonialSlides/TestimonialSlides";
import EnquirySection from "../../../components/NIISEnquirySection/EnquirySection";
import FacilitiesCards from "../../../components/NIISFacilitiesCards/FacilitiesCards";
import TrusteeResponsive from "../../../components/NIISTrusteeResponsive/TrusteeResponsive";
import FromStudentsResponsive from "../../../components/NIISFromStudentsResponsive/FromStudentsResponsive";
import InfrastructureAndFacilitesResponsive from "../../../components/NIISInfrastructureAndFacilitesResponsive/InfrastructureAndFacilitesResponsive";
import InfographyResponsive from "../../../components/NIISInfographyResponsive/InfographyResponsive";
import SocialBlogResponsive from "../../../components/NIISSocialBlogResponsive/SocialBlogResponsive";
import UpcommingEventsResponsive from "../../../components/NIISUpcommingEventsResponsive/UpcommingEventsResponsive";
import ResponsiveContactForm from "../../../components/NIISContactResponsive/ContactResponsive";
import {informationScienceCourses} from "../../../static-data/collegecourses.data";
import {
  managingTrustee,
  niisStudentTestimonial,
} from "../../../static-data/testimonial.data";
import { niisAwards } from "../../../static-data/awards.data";
import TestimonialCard from "../../../components/NIISTestimonialCard/TestimonialCard";
import QuickLinksResponsive from "../../../components/NIISQuickLinksResponsive/QuickLinksResponsive";

const InformationScienceHomePage = () => {

  const [informationScienceCourse, setInformationScienceCourse] = useState(informationScienceCourses);

  return (
    <PageWrapper>
      <NiisHero />
      <AboutUsResponsive/>
			<AboutUsIconsResponsive/>
      <InformationScienceCoursesResponsive/>
      <TrusteeResponsive />
      <FromStudentsResponsive />
      <InfrastructureAndFacilitesResponsive/>
      <InfographyResponsive/>
      <QuickLinksResponsive/>
      <UpcommingEventsResponsive />
			<SocialBlogResponsive />
      <ResponsiveContactForm course={informationScienceCourse} />
      
    </PageWrapper>
  );
};

export default InformationScienceHomePage;
