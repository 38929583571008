import React from "react";
import PageHeader from "../../../components/NIISPageHeader/PageHeader";
import PageSection from "../../../components/NIISPageSection/PageSection";
import PageSectionHeader from "../../../components/NIISPageSectionHeader/PageSectionHeader";
import PageWrapper from "../../../components/NIISPageWrapper/PageWrapper";

const AdmissionProcedurePage = () => {
  return (
    <PageWrapper>
      <PageHeader title="Admission Procedure" />
      <PageSection>
        <p>
          An eligible candidate seeking admission into +2 class can log on the
          website www.dheorissa.in and can apply online by filling up Common
          Application Form (CAF) giving details of applicant name, parents name,
          address, 10th class Board Exam Roll No., Total Marks secured in 10th
          Board Exam etc and take a print out of the CAF, one College Copy and
          another one Applicant Copy.
        </p>

        <p>
          CAF along with photocopy of 10th Board Exam Mark Sheet-cum-Certificate
          has to be deposited at SAM Resource Center.
        </p>
      </PageSection>
      <PageSection bgClass="light">
        <PageSectionHeader
          title="Document to be deposited at SAM Center"
          leftStyle="bottom"
        />
        <div className="ml-2 mb-4">
          <ul>
            <li>
              <p> CAF College Copy along with requisite Application Fee</p>
            </li>
            <li>
              <p>Xerox copy of Mark Sheet-cum-Certificate.</p>
            </li>
            <li>
              <p>One Passport Photo (Color)</p>
            </li>
          </ul>
        </div>
        <p>
          Selected candidates in the 1st selection process to be intimated by
          post and candidates have to take admission in the selected College.
          Any candidate selected in 1st selection, does not take admission then
          that candidate is out of the admission process.
        </p>

        <p>
          Candidate taking admission in lower preference college and continue to
          study there have to fill up an undertaking - Annexure-7 (Available
          www.dheorissa.in).
        </p>

        <p>
          Candidates belonging to ICSE, CBSE Board have to submit the photocopy
          of Original 10th Mark Sheet along with the CAF. Downloaded mark sheet
          will be disqualified.
        </p>
      </PageSection>
      <PageSection>
        <PageSectionHeader
          title="Check List for Admission"
          rightStyle="bottom"
        />
        <div className="ml-2">
          <ul>
            <li>
              <p>Intimation Letter for Admission.</p>
            </li>
            <li>
              <p>Admission Fee, Mark Sheet, Certificate in Original.</p>
            </li>
            <li>
              <p>3 Passport size color Photographs.</p>
            </li>
            <li>
              <p>School Leaving Certificate in Original.</p>
            </li>
            <li>
              <p>Conduct Certificate in Original.</p>
            </li>
            <li>
              <p>Migration Certificate in Original.</p>
            </li>
            <li>
              <p>Annexure – 8 duly filled up.</p>
            </li>
            <li>
              <p>Annexure -7 duly filled up.</p>
            </li>
          </ul>
        </div>
      </PageSection>
    </PageWrapper>
  );
};

export default AdmissionProcedurePage;
