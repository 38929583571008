export const infrastructureData = [
	{
		title: "Classroom lectures",
		imageUrl: require("../assets/infrastructure/cards/typicalweek (1).png"),
	},
	{
		title: "Live sessions with industry experts",
		imageUrl: require("../assets/infrastructure/cards/typicalweek (2).png"),
	},
	{
		title: "Assignments",
		imageUrl: require("../assets/infrastructure/cards/typicalweek (3).png"),
	},
	{
		title: "Doubt solving sessions",
		imageUrl: require("../assets/infrastructure/cards/typicalweek (4).png"),
	},
	{
		title: "Personality coaching",
		imageUrl: require("../assets/infrastructure/cards/typicalweek (5).png"),
	},
	{
		title: "Networking sessions and engagement activities with peers",
		imageUrl: require("../assets/infrastructure/cards/typicalweek (6).png"),
	},
	{
		title: "Sessions on emerging technologies and businesses",
		imageUrl: require("../assets/infrastructure/cards/typicalweek (7).png"),
	},
];
