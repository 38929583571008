import React from "react";
import Blockquote from "../../../components/NIISBlockquote/Blockquote";
import PageHeader from "../../../components/NIISPageHeader/PageHeader";
import PageSection from "../../../components/NIISPageSection/PageSection";
import PageSectionHeader from "../../../components/NIISPageSectionHeader/PageSectionHeader";
import PageWrapper from "../../../components/NIISPageWrapper/PageWrapper";

const CourseStructurePage = () => {
  return (
    <PageWrapper>
      <PageHeader title="Course Structure" />
      <PageSection>
        <p>
          Many students are beginning to consider their future plans when they
          approach their junior year in high school. If they are planning on
          attending college, 11th graders will begin taking college entrance
          tests and concentrating on academic and emotional preparation for
          college. Students may begin to refine their skills if they are taking
          a different direction, such as entrepreneurship or joining the
          workforce.
        </p>
        {/* <Blockquote>
          <p>
            “Education is our passport to the future, for tomorrow belongs to
            the people who prepare for it today.” – Malcolm X
          </p>
        </Blockquote>
        <p>
          "NISS Junior college" which is the sister institution of "NIIS
          Institute of Information Science & Management" has been permitted by
          Dept. of Higher Education under Council of Higher Secondary
          Education(CHSE), Govt. of Orissa to impart in +2 level courses in
          Science & Commerce i.e. academic session 2007-08 for both residential
          and days scholars. Our +2 stream rightly justifies itself as a center
          for Excellency where the teacher and students interact in a friendly
          atmosphere ,which helps the students to build up their excellence
          career.
        </p> */}
      </PageSection>
      <PageSection bgClass="light">
        <PageSectionHeader title="+2 Science" leftStyle="bottom" />
        <p>
          Selecting a stream is comparatively easier than selecting a career,
          but making a careful choice is still important as your stream can
          influence your career. Science is based on the theory and application
          of scientific concepts, which opens up your career opportunities in
          various dimensions and builds a wide scope of concepts to be grasped,
          applied and learnt.
        </p>
        <Blockquote>
          <p>
            <span className="font-weight-bold">Subjects: </span> Physics,
            Chemistry, Mathematics, Biology, English, Computer Science &
            information technology.
          </p>
        </Blockquote>
      </PageSection>
      <PageSection>
        <PageSectionHeader title="+2 Commerce" rightStyle="bottom" />
        <p>
          Business studies taught at our institution are targeted to give
          students practical and useful knowledge to prepare them for real life
          situations with skills needed to manage businesses in current
          vulnerable markets.
        </p>
        <Blockquote>
          <p>
            <span className="font-weight-bold">Subjects: </span> Accountancy,
            Business Studies, entrepreneurship, business mathematics,
            environmental science.
          </p>
        </Blockquote>
      </PageSection>

      {/* <PageSection>
        <PageSectionHeader title="The Course Recognition" rightStyle="bottom" />
        <p className="mb-5">
          The +2 stream of "NISS group of Institutions" styled as "NIIS Junior
          College" are duly permitted by Govt. of Orissa, Dept. Higher Education
          and affiliated to Council of Higher Secondary Education (CHSE) Orissa.
        </p>

        <PageSectionHeader title="+2 Science" type="h4" />
        <div className="ml-2 mb-5">
          <Blockquote>
            <p>
              <span className="font-weight-bold">Seats Available : </span> 128
              seats
            </p>
            <p>
              <span className="font-weight-bold">Classroom Teaching : </span> 64
              students in a group
            </p>
            <p>
              <span className="font-weight-bold">Tutorial Classes : </span> 32
              students in a group
            </p>
          </Blockquote>
        </div>

        <PageSectionHeader title="+2 Commerce" type="h4" />
        <div className="ml-2">
          <Blockquote>
            <p>
              <span className="font-weight-bold">Seats Available : </span> 64
              seats
            </p>
            <p>
              <span className="font-weight-bold">Practical Classes : </span> 16
              students in a group
            </p>
            <p>
              <span className="font-weight-bold">
                Entrance Preparatory classes :{" "}
              </span>{" "}
              32 students in a group
            </p>
          </Blockquote>
        </div> */}

      {/* <Table responsive="sm" bordered>
          <thead className="bg-diag-1 text-brand-1">
            <tr>
              <th>Courses </th>
              <th>Seats Available </th>
              <th>Group Available </th>
              <th>Students</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td rowspan="2">+2 science</td>
              <td rowspan="2">128 seats</td>
              <td>Classroom Teaching</td>
              <td>64 students in a group</td>
            </tr>
            <tr>
              <td>Tutorial Classes</td>
              <td>32 students in a group</td>
            </tr>
            <tr>
              <td rowspan="2">+2 commerce</td>
              <td rowspan="2">64 seats</td>
              <td>Practical Classes</td>
              <td>16 students in a group</td>
            </tr>
            <tr>
              <td>Entrance Preparatory classes</td>
              <td>32 students in a group</td>
            </tr>
          </tbody>
        </Table> */}
      {/* </PageSection> */}
    </PageWrapper>
  );
};

export default CourseStructurePage;
