import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import PageHeader from "../../../components/NIISPageHeader/PageHeader";
import PageSection from "../../../components/NIISPageSection/PageSection";
import PageSectionHeader from "../../../components/NIISPageSectionHeader/PageSectionHeader";
import PageWrapper from "../../../components/NIISPageWrapper/PageWrapper";

const IndustryInterfaceAboutUsPage = () => {
  return (
    <PageWrapper>
      <PageHeader title="About Us" />
      <PageSection>
        <p>
          The Industry Interface Centre of NIIS is amongst the best in the
          state. Every year, a record number of students are trained and placed
          in the top companies across the country. Our platform empowers
          students and trains them to make them capable of handling changes in
          the market. And backed by natural resilience, self-empowered training
          and focus, our students are willing to come out of their comfort zone,
          take risks and engage challenges persistently.
        </p>
        <p>
          Various companies from different Banking, Financial technology,
          retail, IT, FMCG etc. are regularly invited for campus placement. We
          have tied up with Eminent corporate houses across the globe to provide
          opportunities to our students.
        </p>
      </PageSection>
      <PageSection bgClass="light">
        <PageSectionHeader title="Gallery" rightStyle="bottom" />
        <Row className="justify-content-center">
          <Col md="6">
            <Image
              src={
                require("../../../assets/industry interface cell/iic1.JPG")
                  .default
              }
              className="img-fluid rounded mb-4"
            />
          </Col>
          <Col md="6">
            <Image
              src={
                require("../../../assets/industry interface cell/iic2.JPG")
                  .default
              }
              className="img-fluid rounded mb-4"
            />
          </Col>
          <Col md="6">
            <Image
              src={
                require("../../../assets/industry interface cell/iicroom.jpg")
                  .default
              }
              className="img-fluid rounded mb-4"
            />
          </Col>
        </Row>
      </PageSection>
    </PageWrapper>
  );
};

export default IndustryInterfaceAboutUsPage;
