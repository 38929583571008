export const trusteeData = [
	{
		imageUrl: require("../assets/managing-trustee/chairman.png"),
		designation: "Founder Chairman",
		name: "Dr. Subhash Chandra Nayak",
		feedback: `When you first start your academic career, you should look for an institution that will
     take you on an exciting trip that will help you to grow and emerge as a leader. To better
     prepare you for future difficulties and disruptions, and to serve as a pillar in the progress
     of our great country. The NIIS Group is the perfect place to begin your career because
     of its academic brilliance and philanthropic mission to develop global leaders.
     We are committed to making a difference in professional and higher education in India,
     and we are taking steps to develop a model that recognises and meets the
     requirements of students.`,
	},
	{
		imageUrl: require("../assets/managing-trustee/trustee2.png"),
		designation: "Joint Managing Trustee",
		name: "Prof. Smrutisudha Nayak",
		feedback: `We also provide you with an exceptional opportunity to push yourself to new heights
     while also creating an environment that fosters interactive learning and life skills. If anyof this rings true for you, and you're dedicated to achieving success in your chosen
     industry, we look forward to meeting you on our bustling campus!`,
	},
];
