import React from "react";
import PageHeader from "../../../components/NIISPageHeader/PageHeader";
import PageSection from "../../../components/NIISPageSection/PageSection";
import PageWrapper from "../../../components/NIISPageWrapper/PageWrapper";

const StudentGuidelinesPage = () => {
  return (
    <PageWrapper>
      <PageHeader title="Student Guidelines" />
      <PageSection>
        <p>
          Every institute existing in our culture should have guidelines for its
          smooth functioning and for the proper maintenance of decorum. Hence we
          had set some basic rules for all the students on campus which are
          always quintessential for their safety and well being. Any
          discrepancies committed by students are taken into the hands of the
          concerned discipline administration and are dealt strictly.
        </p>
        <div className="ml-2">
          <ul>
            <li>
              <p>
                All matters relating to admission, teaching and discipline, the
                decision of the management is final and binding.
              </p>
            </li>
            <li>
              <p>
                The students can convey their grievances in writing to the
                Principal/Dean through their respective Head of the faculty.3.
                The Board of Management reserves the right to amend any rule,
                for smooth functioning of the institute.
              </p>
            </li>
            <li>
              <p>
                Students are advised to collect information from the notice
                board (Academic Block / Hostel) from time to time.
              </p>
            </li>
            <li>
              <p>
                No refund of fees shall be allowed once a candidate is admitted
                into a course after entering in the college admission register.
              </p>
            </li>
            <li>
              <p>
                Students expelled, due to disobedience of institutional rules &
                regulations shall not be entitled f or any refund of fees and
                deposits.
              </p>
            </li>
            <li>
              <p>
                Students residing in the hostel have to abide by the rules &
                regulations of the hostel. Students, violating the rules, are
                liable for disciplinary action.
              </p>
            </li>
            <li>
              <p>
                Students found guilty of misbehaving with any staff (teaching or
                non-teaching)either inside or outside the campus shall be
                expelled from the institution.
              </p>
            </li>
            <li>
              <p>
                The students are required to carry their identity cards with
                them and produce on demand by the authorities. It is advisable
                that they should carry it whenever they go out of the campus.
              </p>
            </li>
            <li>
              <p>
                Use of mobile phones is restricted and not to be allowed in the
                classroom, however the student may keep in switched off mode
                during class hours.
              </p>
            </li>
            <li>
              <p>
                All rules and regulations are made keeping the current rules and
                regulations of the governments as well as affiliating university
                / Council. Any changes in their policy shall be automatically
                over rule the current policy of the institute.
              </p>
            </li>
          </ul>
        </div>
      </PageSection>
    </PageWrapper>
  );
};

export default StudentGuidelinesPage;
