import React from "react";
import PageWrapper from "../../components/NIISPageWrapper/PageWrapper";
import HeroSection from "../../components/NIISHeroSection/HeroSection";
import HeroCaption from "../../components/NIISHeroCaption/HeroCaption";
import StudentCellResponsive from "../../components/NIISStudentCellResponsive/StudentCellResponsive";

const StudentCellPage = () => {
	return (
		<PageWrapper className="mt-n6">
			<HeroSection className="bg-page-background-11">
				<HeroCaption
					title="Clubs & Committees"
					position="bottom-left"
					breadcrumb={true}
					breadcrumbPrefix="Home"
				/>
			</HeroSection>
			<StudentCellResponsive />
		</PageWrapper>
	);
};

export default StudentCellPage;
