import React from "react";
import classNames from "classnames";


const PageSectionHeader = ({ type, center, leftStyle, rightStyle, title }) => {
  const headerContainerClasses = classNames({ "text-center": center });
  const headingClasses = classNames(
    "heading-text",
    "mb-4",
    {
      "heading-text--styled-right-bottom": rightStyle === "bottom",
    },
    {
      "heading-text--styled-right-top": rightStyle === "top",
    },
    {
      "heading-text--styled-left-bottom": leftStyle === "bottom",
    },
    {
      "heading-text--styled-left-top": leftStyle === "top",
    },
    {
        [type]: type
    },
  );

  return (
    <div className={headerContainerClasses} >
      <h2 className={headingClasses}>{title}</h2>
    </div>
  );
};

export default PageSectionHeader;
