export const alumniInfographyData = [
    {
      value: "8000+",
      caption: (
        <>
          Placed Students
        </>
      ),
    },
    {
      value: "1200+",
      caption: (
        <>
           Students Every Year 
        </>
      ),
    },
    {
      value: "100+",
      caption: (
        <>
          Companies Recruit From Us Every Year 
        </>
      ),
    },
    {
      value: "1000+",
      caption: (
        <>
          Graduates Working In Fortune 500 Companies 
        </>
      ),
    },
    {
      value: "5,50,000+",
      caption: (
        <>
          Median Payscale Offered By Our Recruiting Companies 
        </>
      ),
    },
  ];
  
  export const industryInterfaceInfographyData = [
    {
      value: "2500+",
      caption: (
        <>
          Placed <br />
          Studnents
        </>
      ),
    },
    {
      value: "300+",
      caption: (
        <>
          STUDENTS <br /> EVERY YEAR
        </>
      ),
    },
    {
      value: "100+",
      caption: (
        <>
          Companies recruit from <br /> us every year
        </>
      ),
    },
    {
      value: "500+",
      caption: (
        <>
          graduates working in <br /> Fortune Global 500 Companies
        </>
      ),
    },
    {
      value: "6,00,000+",
      caption: (
        <>
          Median payscale offered <br /> by our recruiting companies
        </>
      ),
    },
  ];
  