import React from "react";
import { Row, Col } from "react-bootstrap";
import { BsFillHouseFill } from "react-icons/bs";
import { BiWorld } from "react-icons/bi";
import { AiFillPhone, AiTwotoneMail } from "react-icons/ai";
import PageWrapper from "../../components/NIISPageWrapper/PageWrapper";
import PageHeader from "../../components/NIISPageHeader/PageHeader";
import PageSection from "../../components/NIISPageSection/PageSection";
import CustomIcon from "../../components/NIISCustomIcon/CustomIcon";
import ContactUsForm from "../../components/NIISContactUsFrom/ContactUsForm";
import PageSectionHeader from "../../components/NIISPageSectionHeader/PageSectionHeader";

const ContactUsPage = () => {
  return (
    <PageWrapper>
      <PageHeader title="Contact Us" />
      <PageSection>
        <Row className="align-items-stretch">
          <Col md="7">
            <div className="mb-4">
              <ContactUsForm />
            </div>
          </Col>
          <Col md="5">
            <div className="border mb-4 rounded px-5 py-4 bg-light">
              <PageSectionHeader title="Campus" rightStyle="bottom" />
              <Row className="mb-2 align-items-start">
                <Col xs="2">
                  <CustomIcon
                    currentIcon={BsFillHouseFill}
                    size="md"
                    colorClass="text-brand-2"
                  />
                </Col>
                <Col xs="10">
                  <p>Sarada Vihar, Madanpur, BBSR - 54</p>
                </Col>
              </Row>
              <Row className="mb-2 align-items-start">
                <Col xs="2">
                  <CustomIcon
                    currentIcon={AiFillPhone}
                    size="md"
                    colorClass="text-brand-2"
                  />
                </Col>
                <Col xs="10">
                  <p>+91-9040004375, +91-9778899991</p>
                </Col>
              </Row>
              <Row className="mb-2 align-items-start">
                <Col xs="2">
                  <CustomIcon
                    currentIcon={AiFillPhone}
                    size="md"
                    colorClass="text-brand-2"
                  />
                </Col>
                <Col xs="10">
                  <p>
                    +91-9778899990, +91-8144278720
                  </p>
                </Col>
              </Row>
              <Row className="mb-2 align-items-start">
                <Col xs="2">
                  <CustomIcon
                    currentIcon={AiTwotoneMail}
                    size="md"
                    colorClass="text-brand-2"
                  />
                </Col>
                <Col xs="10">
                  <a
                    href="mailto:niba.bbsr@yahoo.co.in"
                    className="download-link"
                  >
                    niba.bbsr@yahoo.co.in
                  </a>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </PageSection>
      <PageSection bgClass="light">
        <Row className="align-items-stretch">
          <Col md="5">
            <div className="border mb-4 rounded px-5 py-4 bg-white">
              <PageSectionHeader
                title="Administrative Office"
                leftStyle="bottom"
              />
              <Row className="mb-2 align-items-start">
                <Col xs="2">
                  <CustomIcon
                    currentIcon={BsFillHouseFill}
                    size="md"
                    colorClass="text-brand-2"
                  />
                </Col>
                <Col xs="10">
                  <p>
                    Plot No. - 339 (P), Goutam Nagar, BMC-Mausimaa Main Road,
                    BBSR -751014, Odisha,India.
                  </p>
                </Col>
              </Row>
              <Row className="mb-2 align-items-start">
                <Col xs="2">
                  <CustomIcon
                    currentIcon={AiFillPhone}
                    size="md"
                    colorClass="text-brand-2"
                  />
                </Col>
                <Col xs="10">
                  <p>(0674) 2435745 / 2435746</p>
                </Col>
              </Row>

              <Row className="mb-2 align-items-start">
                <Col xs="2">
                  <CustomIcon
                    currentIcon={BiWorld}
                    size="md"
                    colorClass="text-brand-2"
                  />
                </Col>
                <Col xs="10">
                  <a href="http://www.niisinst.com/" className="download-link">
                    niba.bbsr@yahoo.co.in
                  </a>
                </Col>
              </Row>
            </div>
          </Col>
          <Col md="7">
            {/* <Image
              src={require("../../../assets/route.jpg").default}
              className="img-fluid rounded shadow"
            /> */}
            <div class="map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14973.781804997008!2d85.7252298!3d20.2403525!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xe8052118d487bb86!2sNIIS+Institute+Of+Business+Administration!5e0!3m2!1sen!2sin!4v1527159121709"
                width="100%"
                height="400"
                className="border shadow"
                title="Contact Map"
              ></iframe>
            </div>
          </Col>
        </Row>
      </PageSection>
      <PageSection></PageSection>
    </PageWrapper>
  );
};

export default ContactUsPage;
