import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import PageWrapper from "../../../components/NIISPageWrapper/PageWrapper";
import PageSection from "../../../components/NIISPageSection/PageSection";
import NiisDivisionCards from "../../../components/NiisDivisionCards/NiisDivisionCards";
import PageSectionHeader from "../../../components/NIISPageSectionHeader/PageSectionHeader";
import NewsBites from "../../../components/NIISNewsBites/NewsBites";
import TestimonialSlides from "../../../components/NIISTestimonialSlides/TestimonialSlides";
import EnquirySection from "../../../components/NIISEnquirySection/EnquirySection";
import FacilitiesCards from "../../../components/NIISFacilitiesCards/FacilitiesCards";
import {
  managingTrustee,
  niisStudentTestimonial,
} from "../../../static-data/testimonial.data";
import { niisAwards } from "../../../static-data/awards.data";
import TestimonialCard from "../../../components/NIISTestimonialCard/TestimonialCard";
import NiisJuniorCollegeHero from "../../../components/NiisJuniorCollegeHero/NiisJuniorCollegeHero";


const JuniorCollegeHomePage = () => {
  return (
    <PageWrapper>
      <NiisJuniorCollegeHero />
      <PageSection>
        <div className="text-center">
          <PageSectionHeader
            title="Our Constituent Colleges"
            rightStyle="bottom"
          />
        </div>
        <NiisDivisionCards />
      </PageSection>
      <PageSection bgClass="light">
        <PageSectionHeader title="About NIIS" leftStyle="bottom" />
        <Row>
          <Col md="7" className="mb-4">
            <p>
              The group was established in the year 2000 under the aegis of
              Nilachal Management Trust (NEMT). We are a group of visionaries,
              aiming to pioneer into the sector of IT services, Management and
              Social wings of studies. With a motto of providing a world class
              learning curriculum to all our students, we have established
              constituent colleges of different domains, namely NIIS Institute
              of Business Administration, NIIS Institute of Information Science
              and Management and NIIS Higher Secondary School.
            </p>
            <p>
            {"\"NIIS Junior college\" which is the constituent institution of NIIS Group of Institutions has been permitted by Dept. of Higher Education under Council of Higher Secondary Education(CHSE), Govt. of Orissa to impart in +2 level courses in Science & Commerce since 2007 as a self financing co-education institution.  "}
            </p>
          </Col>
          <Col md="5" className="mb-4">
            <Image
              src={require("../../../assets/college-image3.png").default}
              className="shadow-lg img-fluid"
            />
          </Col>
        </Row>
        <p>NIIS aims on providing an education that-</p>
        <div className="ml-2">
          <ul>
            <li>
              <p> offers lifelong learning opportunities</p>
            </li>
            <li>
              <p>
                fosters dynamic learning environments designed to meet the needs
                of our students
              </p>
            </li>
            <li>
              <p>guarantees equality of rights and access.</p>
            </li>
            <li>
              <p>ensures integrity and honesty in the learning process</p>
            </li>
          </ul>
        </div>
        <p>
          A good curriculum or classroom knowledge are all in vain if the ward
          isn’t excelling in a proper communication tactic and overall
          development. We at NIIS ensure that the students always go through
          intense yet interactive sessions of personality developments, soft
          skills enhancement.
        </p>
      </PageSection>
      <PageSection>
        <PageSectionHeader title="From Trustee" rightStyle="bottom" />
        <Row className="align-items-stretch ">
          {managingTrustee
            ? managingTrustee.map((data) => (
                <Col
                  md="6"
                  className="mb-4"
                  key={
                    data.imageUrl.default
                      ? data.imageUrl.default
                      : data.imageUrl
                  }
                >
                  <TestimonialCard {...data} />
                </Col>
              ))
            : null}
        </Row>
      </PageSection>

      <PageSection bgClass="light">
        <PageSectionHeader
          title="Infrastructure & Facilities"
          leftStyle="bottom"
        />
        <FacilitiesCards />
      </PageSection>
      <PageSection>
        <Row>
          <Col md="6" className="mb-4">
            <PageSectionHeader
              title="Alumni Testimonials"
              rightStyle="bottom"
            />
            <TestimonialSlides
              slidesData={niisStudentTestimonial}
              numberOfSlides={1}
              controlName="niisAlumniTestimonials"
              type="mini"
              shadowImage={true}
            />
          </Col>
          <Col md="6" className="mb-4">
            <PageSectionHeader title="Awards" rightStyle="bottom" />

            <TestimonialSlides
              slidesData={niisAwards}
              numberOfSlides={1}
              controlName="niis-award"
              type="mini"
            />
          </Col>
        </Row>
      </PageSection>
      <PageSection bgClass="light">
        <PageSectionHeader title="Recent News" leftStyle="bottom" />
        <NewsBites />
      </PageSection>
      <PageSection bgClass="diag-1">
        <PageSectionHeader title="Admission" leftStyle="bottom" />
        <EnquirySection />
      </PageSection>
    </PageWrapper>
  );
};

export default JuniorCollegeHomePage;
