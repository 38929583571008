import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import PageHeader from "../../../components/NIISPageHeader/PageHeader";
import PageSection from "../../../components/NIISPageSection/PageSection";
import PageWrapper from "../../../components/NIISPageWrapper/PageWrapper";
import PageSectionHeader from "../../../components/NIISPageSectionHeader/PageSectionHeader";

const InfrastructurePage = () => {
  return (
    <PageWrapper>
      <PageHeader title="Infrastructure" />
      <PageSection>
        <PageSectionHeader title="College infrastructure" rightStyle="bottom" />
        <Row>
          <Col md="6">
            <Image
              src={
                require("../../../assets/infrastructure/aerial view 1.jpeg")
                  .default
              }
              className="img-fluid rounded mb-4"
            />
          </Col>
          <Col md="6">
            <Image
              src={
                require("../../../assets/infrastructure/aerial view 7.jpeg")
                  .default
              }
            className="img-fluid rounded mb-4"
            />
          </Col>
        </Row>
      </PageSection>
      <PageSection bgClass="light">
        <PageSectionHeader title="Classroom" leftStyle="bottom" />
        <Row>
          <Col md="6">
            <Image
              src={
                require("../../../assets/infrastructure/classroom 1.jpg")
                  .default
              }
              className="img-fluid rounded mb-4"
            />
          </Col>
          <Col md="6">
            <Image
              src={
                require("../../../assets/infrastructure/classroom 2.JPG")
                  .default
              }
              className="img-fluid rounded mb-4"
            />
          </Col>
        </Row>
      </PageSection>
      <PageSection>
        <PageSectionHeader title="Auditorium" rightStyle="bottom" />
        <Row>
          <Col md="6">
            <Image
              src={
                require("../../../assets/infrastructure/main hall 1.jpg")
                  .default
              }
              className="img-fluid rounded mb-4"
            />
          </Col>
          <Col md="6">
            <Image
              src={
                require("../../../assets/infrastructure/main hall 2.jpg")
                  .default
              }
            className="img-fluid rounded mb-4"
            />
          </Col>
        </Row>
      </PageSection>
      <PageSection bgClass="light">
        <PageSectionHeader title="IT Lab" leftStyle="bottom" />
        <Row>
          <Col md="6">
            <Image
              src={
                require("../../../assets/infrastructure/it 1.jpg")
                  .default
              }
              className="img-fluid rounded mb-4"
            />
          </Col>
          <Col md="6">
            <Image
              src={
                require("../../../assets/infrastructure/it2.jpg")
                  .default
              }
              className="img-fluid rounded mb-4"
            />
          </Col>
        </Row>
      </PageSection>
      <PageSection>
        <PageSectionHeader title="Industry Interface Centre" rightStyle="bottom" />
        <Row className="justify-content-center">
          <Col md="6">
            <Image
              src={
                require("../../../assets/infrastructure/iicroom 2.jpg")
                  .default
              }
              className="img-fluid rounded mb-4"
            />
          </Col>
          <Col md="6">
            <Image
              src={
                require("../../../assets/infrastructure/iic room 3.jpg")
                  .default
              }
            className="img-fluid rounded mb-4"
            />
          </Col>
          <Col md="6">
            <Image
              src={
                require("../../../assets/infrastructure/iic2.JPG")
                  .default
              }
            className="img-fluid rounded mb-4"
            />
          </Col>
        </Row>
      </PageSection>
    </PageWrapper>
  );
};

export default InfrastructurePage;
