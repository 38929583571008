import React from "react";
import { Col, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import CourseCard from "../../../components/NIISCourseCard/CourseCard";
import PageHeader from "../../../components/NIISPageHeader/PageHeader";
import PageSection from "../../../components/NIISPageSection/PageSection";
import PageWrapper from "../../../components/NIISPageWrapper/PageWrapper";
import { undergraduateCourseData } from "../../../static-data/nisscourses.data";

const UndergraduatePage = () => {
  const { pathname } = useLocation();

  return (
    <PageWrapper>
      <PageHeader title="Under Graduate Courses" />
      <PageSection>
        <Row className="justify-content-center">
          {undergraduateCourseData &&
            undergraduateCourseData.map(({imageUrl, title, link }) => (
              <Col lg="3" md="2" key={title} className="d-flex align-items-stretch mb-4">
                <CourseCard title={title} imageUrl={imageUrl} link={`${pathname}${link}`} />
              </Col>
            ))}
        </Row>
      </PageSection>
    </PageWrapper>
  );
};

export default UndergraduatePage;
