import React from "react";
import PageSection from "../NIISPageSection/PageSection";
import { Col, Row } from "react-bootstrap";
import TextIconMiniCard from "../NIISTextIconMinicard/TextIconMinicard";
import { aboutusicons } from "../../static-data/aboutisicons.data";

const AboutUsIconsResponsive = () => {
	return (
		<PageSection bgClass="light">
			<div className="px-2 px-md-5">
				<Row className="justify-content-center gx-1">
					{aboutusicons.map((data, index) => (
						<Col
							md="3"
							className="d-flex justify-content-center align-items-baseline justify-content-md-center"
							key={"infrastructure-home" + index}
						>
							<TextIconMiniCard {...data} />
						</Col>
					))}
				</Row>
			</div>
		</PageSection>
	);
};

export default AboutUsIconsResponsive;