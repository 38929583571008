import React from "react";
import PageWrapper from "../../components/NIISPageWrapper/PageWrapper";
import HeroSection from "../../components/NIISHeroSection/HeroSection";
import HeroCaption from "../../components/NIISHeroCaption/HeroCaption";
import CourseInfoResponsive from "../../components/NIISCourseInfoResponsive/CourseInfoResponsive";
import CoursesResponsive from "../../components/NIISCoursesResponsive/CoursesResponsive";

const CoursesPage = () => {
	return (
		<PageWrapper className="mt-n6">
			<HeroSection className="bg-page-background-7">
				<HeroCaption
					title="Courses"
					position="bottom-left"
					breadcrumb={true}
					breadcrumbPrefix="Home"
				/>
			</HeroSection>
			<CourseInfoResponsive />
			{/* <FacultyResponsive /> */}
			<CoursesResponsive />
		</PageWrapper>
	);
};

export default CoursesPage;
