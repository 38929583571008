import React from "react";
import PageWrapper from "../../../components/NIISPageWrapper/PageWrapper";
import PageSection from "../../../components/NIISPageSection/PageSection";
import PageSectionHeader from "../../../components/NIISPageSectionHeader/PageSectionHeader";
import PageHeader from "../../../components/NIISPageHeader/PageHeader";
import { Col, Row } from "react-bootstrap";
import AdmissionMiniCard from "../../../components/AdmissionMiniCard/AdmissionMiniCard";
import { pgAdmissionPageData } from "../../../static-data/pg-admissionpage.data";

const UGAdmissionPage = () => {
	return (
		<PageWrapper>
		<PageHeader title="Post Graduate Programs" />
		<PageSection>
		  <p style={{textAlign: "justify"}}>
            Welcome to NIIS, where postgraduate education is not just a degree but a transformative experience. 
            Our PG programs are designed to empower you with advanced knowledge, critical thinking, and practical skills, 
            preparing you to excel in your chosen field.

		  </p>
		</PageSection>
		<PageSection bgClass="light">
		  <PageSectionHeader title="Why Choose NIIS for Your PG Pursuit?" rightStyle="bottom" />
		  <PageSection bgClass="background-white">
				<Row className="justify-content-center">
					{pgAdmissionPageData.map((data, index) => (
						// <Row className="justify-content-center">
							<Col
								md="4 mt-2"
								className="d-flex align-items-stretch mb-4 mb-md-0"
								key={"about-us-home" + index}
							>
								<AdmissionMiniCard {...data} />
							</Col>
						//</Row>
					))}
				</Row>
			</PageSection>
		</PageSection>
	  </PageWrapper>
	);
};

export default UGAdmissionPage;