import React, { useState, useEffect } from "react";
import bsBreakpoints from "bs-breakpoints";
import classNames from "classnames";
import { Col, Row } from "react-bootstrap";
import PageSection from "../NIISPageSection/PageSection";
import "./infographyResponsive.style.scss";
import { infographyData } from "../../static-data/infography.data";

const InfographyResponsive = () => {
  const [currentBreakPoint, setCurrentBreakPoint] = useState("");

  useEffect(() => {
    bsBreakpoints.init();
    setCurrentBreakPoint(bsBreakpoints.getCurrentBreakpoint());

    const handleBreakPoint = (event) => {
      setCurrentBreakPoint(event.detail);
    };

    window.addEventListener("new.bs.breakpoint", handleBreakPoint);

    return () => {
      window.removeEventListener("new.bs.breakpoint", handleBreakPoint);
    };
  }, []);

  const infographyContainerClasses = classNames("infography-container", {
    "py-0": currentBreakPoint === "xSmall" || currentBreakPoint === "small",
  });

  const infographyDataValueClasses = classNames(
    "mb-0 font-weight-bold",
    {
      "display-4": !(
        currentBreakPoint === "xSmall" || currentBreakPoint === "small"
      ),
    },
    {
      "display-6":
        currentBreakPoint === "xSmall" || currentBreakPoint === "small",
    }
  );

  return (
    <PageSection type="lg" bgClass="page-background-2">
      <div className={infographyContainerClasses}>
        <div className="infography-header">
          <h2 className="text-uppercase font-weight-bold">
            Premier Educational Group of Odisha
          </h2>
          {/* <p>for masters in business & computer applications</p> */}
        </div>
        <div className="infography-data">
          <Row className="justify-content-center">
            {infographyData.map((data, index, arr) => (
              <Col
                md="3"
                className={
                  index === arr.length - 1 ? "mb-md-0 mb-0" : "mb-md-4 mb-5"
                }
                key={"infography-home" + index}
              >
                <p className={infographyDataValueClasses}>{data.value}</p>
                <p className="text-uppercase font-weight-medium mb-0">
                  {data.caption}
                </p>
              </Col>
            ))}
          </Row>
        </div>
      </div>
    </PageSection>
  );
};

export default InfographyResponsive;
