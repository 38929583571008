import React from "react";
import { Image } from "react-bootstrap";
import { FaHeart } from "react-icons/fa";
import CustomIcon from "../NIISCustomIcon/CustomIcon";

import "./socialMediaCard.style.scss";

const SocialMediaCard = ({
  avatarUrl,
  iconUrl,
  bannerUrl,
  name,
  username,
  likes,
  dateTime,
  title,
  desciption,
}) => {
  return (
    <div className="card social-media-card">
      <div className="social-media-card--header">
        <Image
          src={avatarUrl.default ? avatarUrl.default : avatarUrl}
          alt="avatar"
          className="img-fluid social-media-card--header-avatar"
        />
        <div className="social-media-card--header-content">
          <span className="mb-0 text-heading-color font-paragraph-font">
            {name}
          </span>
          <span className="d-block text-highlight-color mt-n1 font-md font-paragraph-font">
            {username}
          </span>
        </div>
        <Image
          src={iconUrl.default ? iconUrl.default : iconUrl}
          alt="social media icon"
          className="img-fluid social-media-card--header-icon"
        />
      </div>
      <div className="social-media-card--content">
        <p className="font-sm text-text-color mb-0">{desciption}</p>
        <div className="social-media-card--content-info">
          <div className="text-nowrap">
            <CustomIcon
              className="ml-0 mr-1"
              size="sm"
              currentIcon={FaHeart}
              colorClass="text-danger"
            />
            <span className="font-sm font-weight-semibold font-paragraph-font text-danger">
              {likes}
            </span>
          </div>
          <div>
            <p className="font-sm mb-0 text-text-light-color">{dateTime}</p>
          </div>
        </div>
      </div>
      <div className="social-media-card--footer">
        <div className="social-media-card--footer-banner">
          <Image
            src={bannerUrl.default ? bannerUrl.default : bannerUrl}
            alt="social media banner"
            className="img-fluid social-media-card--footer-banner-image"
          />
        </div>
        <div className="social-media-card--footer-content">
          <p className="font-weight-medium font-sm text-white mb-0">{title}</p>
        </div>
      </div>
    </div>
  );
};

export default SocialMediaCard;
