export const aboutUsCardsData = [
	{
		title: "Values of the institution",
		imageUrl: require("../assets/about-us/VALUES.png"),
	},
	{
		title: "Leadership & managements",
		imageUrl: require("../assets/about-us/Leadership.png"),
	},
	{
		title: "Industry Interface Center",
		imageUrl: require("../assets/about-us/Industry.png"),
	},
	{
		title: "World-Class Infrastructure",
		imageUrl: require("../assets/about-us/Awards.png"),
	},
];
