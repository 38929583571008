import React from "react";
import { Col, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const NewsCardResponsive = ({ date, link, title, description, imageUrl }) => {
	return (
		<div className="w-100 border mb-4">
			<Row className="p-2">
				<Col md="3">
					<Image
						src={imageUrl.default ? imageUrl.default : imageUrl}
						alt={title}
						className="w-100 mb-2"
					/>
				</Col>
				<Col>
					<div className="w-100 d-flex align-items-center justify-content-between mb-2">
						<p className="font-weight-medium text-text-heading-color mb-0">
							{date}
						</p>
						{/* <u>
              <Link
                to={link}
                className="text-link-color text-underline font-paragraph-font font-md"
              >
                View / Download
              </Link>
            </u> */}
					</div>

					<p className="text-heading-color font-lg mb-2">{title}</p>
					<p className="font-weight-light font-md mb-0">{description}</p>
				</Col>
			</Row>
		</div>
	);
};

export default NewsCardResponsive;
