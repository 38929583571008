export const studentCellData = [
	{
		title: "Mind Mantra",
		imageUrl: require("../assets/clubs/mindmantra.png"),
		description: "Mind Mantra is the management club at NIIS Institute of Business Administration. The club is dedicated to enhancing the knowledge and skills of students in various areas of management. Mind Mantra conducts regular events, workshops and seminars to provide students with practical learning opportunities and prepare them for real-world business challenges.",
	},
	{
		title: "Marketing Club",
		imageUrl: require("../assets/clubs/marketing.png"),
		description: "The Marketing Club at NIIS Institute of Business Administration is a student-led initiative. The club aims to provide students with hands-on experience in various aspects of marketing. The Marketing Club conducts regular events, guest lectures and workshops to equip students with the latest marketing strategies and tools, and prepare them for successful careers in the field.",
		
	},
	{
		title: "Human Resources Club",
		imageUrl: require("../assets/clubs/humanresource.png"),
		description: "The Human Resources Club at NIIS Institute of Business Administration provides students with an opportunity to learn and develop their skills in the field of HR. The club conducts events, workshops, and seminars to help students understand the role and importance of HR in modern organizations. The Human Resources Club aims to prepare students for successful careers in the field of HR by providing them with practical learning opportunities and exposure to the latest industry trends and best practices.",
	},
	{
		title: "Finance Club",
		imageUrl: require("../assets/clubs/finance.png"),
		description: "The Finance Club at NIIS Institute of Business Administration is a student-run organization that provides a platform for students to enhance their knowledge and skills in the field of finance. The club organizes various events, seminars, and workshops to help students understand the latest developments and trends in the financial world. The Finance Club also offers practical learning opportunities for students by organizing simulations and case studies to help them develop their analytical and problem-solving skills. The club aims to prepare students for successful careers in finance by providing them with exposure to different areas of finance and an understanding of the practical applications of financial concepts.",
	},
	{
		title: "Information Technology Club",
		imageUrl: require("../assets/clubs/informationtech.png"),
		description: " The Information Technology Club at NIIS Institute of Business Administration is a student-led organization that provides a platform for students to explore the latest developments and trends in the field of information technology. The club offers students a chance to develop practical skills and experience by organizing workshops, seminars, and competitions related to software development, cybersecurity, artificial intelligence, and more. The IT Club at NIIS also helps students to enhance their technical knowledge by organizing guest lectures from industry experts and conducting visits to technology companies. Through its various initiatives, the club aims to develop the technical and leadership skills of students, and prepare them for successful careers in the field of information technology.",
	},
	{
		title: "Electoral Literacy Club",
		imageUrl: require("../assets/clubs/electoral.png"),
		description: "The Electoral Literacy Club at NIIS Institute of Business Administration is a student-led organization that aims to promote awareness and understanding of the democratic process and electoral system. The club provides a platform for students to learn about the rights and responsibilities of citizens, the importance of voting, and the role of political parties in shaping public policy. Through workshops, seminars, and interactive sessions, the club aims to increase students' knowledge and understanding of the electoral process, and help them develop critical thinking and decision-making skills. The Electoral Literacy Club at NIIS also works towards building a culture of active citizenship and promoting democratic values and principles among students. The club encourages students to actively participate in electoral processes and promotes ethical and responsible voting. Through its various initiatives, the club aims to empower students to become informed and engaged citizens, and contribute to the development of a healthy and vibrant democracy.",
	},

];
